import React, { useState } from 'react';

import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeUserInput } from '../../helper/sanitize';
import * as constant from '../Constants/constant';
import MessagePopup from './Messagepopup';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    background: 'white',
    color: 'white',
  },
};

function RemoveAppAccess({ modalIsOpen, closeModal, data }) {
  const [temp, setTemp] = useState([]);
  const [accbutton, setAccbutton] = useState(false);
  const { csrf } = useSelector((state) => state.app);

  function handleClose() {
    closeModal();
  }

  let subtitle;

  const [messageIsOpen, setMessageIsOpen] = React.useState(false);

  const [message, setMessage] = useState({
    title: '',
    body: '',
    success: false,
  });

  function openmessagepopup() {
    setMessageIsOpen(true);
  }
  function closemessagepopup() {
    setMessageIsOpen(false);
  }

  function handleRemove() {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
        {
          application_name: 'N/A',
          data: {
            application_name: temp,
            email: data.email,
          },
          request_type: 'RemoveAppAccess',
          email: localStorage.getItem('username'),
        },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setMessage((prevState) => ({
          ...prevState,
          title: 'Remove Application Access',
          body: 'Application removal initiated. Waiting for Approval',
          success: true,
        }));
        openmessagepopup();
        handleClose();
        setAccbutton(false);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  // function afterOpenModal() {

  //     subtitle.style.color = 'white';
  // }

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
        // //onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <div>
          <button
            onClick={() => {
              handleClose();
            }}
            style={{
              textDecoration: 'none',
              border: 'none',
              borderRadius: '16px',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            className="material-symbols-outlined position-absolute top-0 end-0"
          >
            close
          </button>
          <div className="fw-bold fs-5" style={{ color: '#0063c3' }}>
            Remove Application Access
          </div>

          {!accbutton && (
            <div>
              <div className="ms-4">
                <div className="fw-bold fs-6" style={{ color: '#0063c3' }}>
                  {data?.email}
                </div>
                <div className="text-danger mb-2">
                  *Select applications to remove access for the user
                </div>

                {data?.data?.map((item) => (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setTemp([...temp, item.application_name]);
                        } else {
                          setTemp(
                            temp.filter((i) => i !== item.application_name)
                          );
                        }
                      }}
                    />
                    <label
                      className="form-check-label text-black"
                      htmlFor="flexCheckDefault"
                    >
                      {item.alias_name
                        ? item.alias_name
                        : item.application_name}
                    </label>
                  </div>
                ))}
              </div>

              <div className="mt-2 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  disabled={temp.length === 0}
                  onClick={() => {
                    setAccbutton(true);
                  }}
                >
                  Remove Access
                </button>
              </div>
            </div>
          )}

          {accbutton && (
            <div>
              <div className="text-black mt-2">
                Are you sure to remove access for selected application?
              </div>

              <div className="mt-2 d-flex justify-content-end">
                <button
                  className="btn btn-danger me-2"
                  onClick={() => {
                    setAccbutton(false);
                  }}
                >
                  No
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleRemove();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <MessagePopup
        modalIsOpen={messageIsOpen}
        closeModal={closemessagepopup}
        title={message.title}
        body={message.body}
        success={message.success}
      />
    </div>
  );
}

export default RemoveAppAccess;
