import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  role: '',
  allowedApp: [],
  appList: [],
  initialLoader: true,
  active: '',
  getUser: '',
  allowCountries: [],
  initialCountryValue: [],
  countryListNew: [],
  appListNew: [],
};

export const dashboarsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setAllowedApplication: (state, actions) => {
      const adminApp = actions.payload?.data?.map(
        (data) => data.application_name
      );
      const app_list = actions.payload?.data?.map((data) => {
        return { appName: data.application_name, aliasName: data.alias_name };
      });
      state.allowedApp = adminApp;
      state.appList = app_list;
      state.role = actions.payload?.role;
    },
    updateInitialLoader: (state, actions) => {
      state.initialLoader = actions.payload;
    },
    setActive: (state, actions) => {
      state.active = actions.payload;
    },
    setGetUser: (state, actions) => {
      state.getUser = actions.payload;
    },
    setAllowedCountries: (state, actions) => {
      const app = actions.payload;
      const value = state.getUser.data.filter(
        (d) => d.application_name === app
      );

      if (value[0]?.mailBlastCountries) {
        state.allowCountries = value[0].mailBlastCountries;
      } else {
        // state.allowCountries = [
        //     {
        //         "value": "en",
        //         "label": "English"
        //     },
        //     {
        //         "value": "en",
        //         "label": "English"
        //     },
        //     {
        //         "value": "en",
        //         "label": "English"
        //     },
        //     {
        //         "value": "en",
        //         "label": "English"
        //     },
        //     {
        //         "value": "en",
        //         "label": "English"
        //     },
        //     {
        //         "value": "en",
        //         "label": "English"
        //     }
        // ]
        state.allowCountries = [];
      }
    },
    setSuperAdminAllowedCountries: (state, actions) => {
      state.allowCountries = state.initialCountryValue;
      // [
      //     {
      //         "value": "CA",
      //         "label": "Canada"
      //     },
      //     {
      //         "value": "US",
      //         "label": "US"
      //     },
      //     {
      //         "value": "ES",
      //         "label": "Spain"
      //     },
      //     {
      //         "value": "DE",
      //         "label": "Germany"
      //     },
      //     {
      //         "value": "AU",
      //         "label": "Australia"
      //     },
      //     {
      //         "value": "FR",
      //         "label": "France"
      //     }
      // ]
    },
    setInitialCountryValues: (state, actions) => {
      state.initialCountryValue = actions.payload.mailBlastCountries;
      state.allowCountries = actions.payload.mailBlastCountries;
    },
    newAppList: (state, actions) => {
      state.appListNew = [...actions.payload];
    },
    newCountryList: (state, actions) => {
      const { options } = actions.payload;
      state.countryListNew = options.mailBlastCountries.map((data) => ({
        value: data.value,
        label: data.label,
      }));
    },
  },
});

export const {
  setAllowedApplication,
  updateInitialLoader,
  setActive,
  setGetUser,
  setAllowedCountries,
  setSuperAdminAllowedCountries,
  setInitialCountryValues,
  newCountryList,
  newAppList,
} = dashboarsSlice.actions;
export default dashboarsSlice.reducer;
