export const text = {
  max: '50',
  min: '3',
  type: 'alphanumeric',
};

export const email = {
  type: 'email',
};

export const phone = {
  max: 10,
  min: 10,
  type: 'mobile',
};

export const password = {
  type: '(A-Z),(a-z),(0-9),(such as !, #, or %)',
};

export const customize = {
  max: '100',
  min: '3',
  type: 'alphanumeric',
};

export const requireFields = [
  'firstName',
  'lastName',
  'speciality',
  'postalCode',
  'city',
  'password',
  'email',
  'password1',
  'LicenseID',
  'institutionName',
];
export const language = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Spanish' },
  { value: 'fr', text: 'French' },
];

export const region = [
  { value: 'global', text: 'global' },
  { value: 'us', text: 'us' },
  { value: 'uk', text: 'uk' },
];
