import React, { useState } from 'react';
import { WorldMap } from 'react-svg-worldmap';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import MyComponent from '../Table/Table';
import { Applicationdata } from './Application_data';
import {
  updateLoader,
  setOnboardData,
  completeButtonDisable,
  updateOnboardKind,
  updateRequestId,
} from '../../store/slice';
import * as constant from '../Constants/constant';
import { countriesOnboarded } from '../../helper/helper';
import { sanitizeUserInput } from '../../helper/sanitize';

function Country(props) {
  const [data, setData] = useState(props.activeCountryData || '');
  const { csrf } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  axios.defaults.withCredentials = true;

  React.useEffect(() => {
    dispatch(updateLoader(true));

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
          countriesOnboarded_new: true,
          country: props.country,
          startDate: props.startdate.toISOString(),
          endDate: props.enddate.toISOString(),
          appName: props.dashapp,
        },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)), 'errorItem');
      });
    // countriesOnboarded(
    //   {"country":props.country},props.setCountrycount,props.setActiveCountryData
    // )

    //   setData(props.activeCountryData)
  }, []);
  React.useEffect(() => {
    setData(props.activeCountryData);
  }, [props.activeCountryData]);

  // const data = [
  //   //{ country: 'cn', value: 5 }, // china
  //   { country: 'US', value: 10 }, // united states
  //   { country: 'CA', value: 7 }, // russia
  //   { country: 'IN', value: 10 }, // india
  // ];

  const column = [
    {
      name: 'Country',
      selector: (row) => row.country,
      cell: (row) => row.country,
      sortable: true,
    },
    {
      name: 'No of Applications',
      cell: (row) => row.app_count,
      // sortable: true
    },
    {
      name: 'Total No. of Users',
      cell: (row) => row.value,
      // sortable: true
    },
  ];
  return (
    <div className="container h-100">
      <div className="row h-100">
        <div className="col">
          {data != '' ? (
            <WorldMap
              style={{ width: '90%', height: '45vh' }}
              color="blue"
              // title="Top 10 Populous Countries"
              value-suffix="people"
              size="lg"
              data={data}
            />
          ) : (
            'Loading...'
          )}
        </div>
        <div className="col h-90 overflow-auto ">
          <div className="mb-4 mt-1">
            <MyComponent
              header="true"
              data={data}
              fileName="countries data"
              columns={column}
              paginationPerPage={10}
              tableHeight="47vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Country;
