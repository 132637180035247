import React, { useState } from 'react';

import './index.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from '../sidebar/Sidebar';
import { setShow } from '../../store/appslice';
import '../style.css';
import UserPopover from '../userpopover';
import Popover from '@mui/material/Popover';
import Loader from '../loader/loader';
import Pending from './pending';

function Reqhome() {
  const dispatch = useDispatch();
  const [approveLoading, setApprove] = useState(false);
  const [active1, setActive1] = useState('1');
  const { show } = useSelector((state) => state.app);
  axios.defaults.withCredentials = true;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <main className={show ? 'space-toggle' : null} style={{ margin: 0 }}>
        <aside
          style={{ background: '#045EB7' }}
          className={`sidebar ${show ? 'show' : null}`}
        >
          <nav className="nav">
            <div>
              <div
                className="header-toggle"
                onClick={() => dispatch(setShow())}
              >
                <span className="fas fa-bars sidebarfirst" />
              </div>
              <Sidebar show={show} />
            </div>
          </nav>
        </aside>

        <div className="maintop">
          <header
            style={{ background: 'white', padding: 0, height: '7vh' }}
            className={`header ${show ? 'space-toggle' : null}`}
          >
            <span>
              <img
                style={{ paddingLeft: '1rem', height: '40px' }}
                src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg"
                alt=""
              />
            </span>
            <div>
              <span
                aria-describedby={id}
                role="button"
                className="material-symbols-outlined headercolor fs-1 me-3"
                onClick={handleProfileClick}
              >
                account_circle
              </span>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <UserPopover />
              </Popover>
            </div>
          </header>
          <Pending setApprove={setApprove} />
        </div>
      </main>
      {approveLoading && <Loader />}
    </div>
  );
}

export default Reqhome;
