import React from 'react';

export default function Loader() {
  return (
    <div
      className="position-fixed top-0 start-0 d-flex w-100 h-100 justify-content-center align-items-center bg-dark bg-opacity-50"
      style={{ zIndex: '1000' }}
    >
      <div className="spinner-grow text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
