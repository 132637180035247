import React, { Fragment, useState } from 'react';

import Sidebar from './sidebar/Sidebar';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import Popover from '@mui/material/Popover';
import UserPopover from './userpopover';
import rolenotfound from './Assets/rolenotfound.svg';

function Rolenotfound() {
  const [show, setShow] = useState(true);
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const user = localStorage.getItem('username');
  React.useEffect((e) => {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount !== '1') {
      sessionStorage.setItem('reloadCount', '1');
      window.location.reload(false);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <main className={show ? 'space-toggle' : null} style={{ margin: 0 }}>
      <aside
        style={{ background: '#045EB7' }}
        className={`sidebar ${show ? 'show' : null}`}
      >
        <nav className="nav">
          <div>
            <div className="header-toggle" onClick={() => setShow(!show)}>
              <span className="fas fa-bars sidebarfirst" />
            </div>
            <Sidebar />
          </div>
        </nav>
      </aside>
      <div className="maintop">
        <header
          style={{
            background: 'white',
            zIndex: '-1',
            height: '7vh',
            padding: 0,
          }}
          className={`header ${show ? 'space-toggle' : null}`}
        >
          <span>
            <img
              style={{ paddingLeft: '1rem', height: '40px' }}
              src="https://amgen-cdn-test-bucket.s3.eu-central-1.amazonaws.com/amgen-passport-logo.png"
              alt=""
            />
          </span>
          <div>
            {/* <span className="material-symbols-outlined headercolor">search</span>&nbsp;&nbsp;
            <span className="material-symbols-outlined headercolor">notifications</span>&nbsp;&nbsp; */}
            {/* <span className="material-symbols-outlined headercolor" type="button" data-toggle="tooltip" title={user}>account_circle</span> */}

            <span
              aria-describedby={id}
              role="button"
              className="material-symbols-outlined headercolor fs-1 me-3"
              onClick={handleClick}
            >
              account_circle
            </span>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <UserPopover />
            </Popover>
          </div>
        </header>
        <div className="bodyfirstusernotfound">
          <img src={rolenotfound} style={{ height: '70vh' }} />
          <br />
          <div className="text-center fw-bold" style={{ color: '#0063c3' }}>
            You do not have any role assigned yet, Please contact
            global_customer_idm@amgen.com
          </div>
          <button onClick={refreshPage} className="btn btn-primary">
            Click to reload!
          </button>
        </div>
      </div>
    </main>
  );
}

export default Rolenotfound;
