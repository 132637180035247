import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  appendTransvalue,
  updateMandatoryTrans,
} from '../../store/onboardfinal';
import { getLanguage } from '../../helper/helper';
import { trimData } from '../../helper/onboard';

export default function MandatoryTrans({
  modalIsOpen,
  closeModal,
  tiggerTrans,
  setTiggerTrans,
  handleSubmitModel,
}) {
  const dispatch = useDispatch();
  const { data, customizeField } = useSelector((state) => state.onboard);
  const { translations, mandatoryTrans, finalTranslations } = useSelector(
    (state) => state.onboardFinal
  );
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      maxHeight: '50vh',
      background: 'white',
      color: '#6c757d',
    },
  };

  useEffect(() => {
    if (tiggerTrans) {
      if (Object.keys(translations).length !== 0) {
        const { country } = data.options;
        const temp = data.options.allowedLanguages.map((lan, index) => {
          const key = `${lan.value}-${country}`;
          const val = data.options.fields.map((fields, index) => {
            if (lan.value.toLowerCase() === 'en') {
              if (fields.name in customizeField) {
                if (fields.type.toLowerCase() === 'dropdown') {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: fields.label,
                      value: fields.label,
                    })
                  );
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: fields.placeholder,
                      value: fields.placeholder,
                    })
                  );
                  const dropdownVal = fields.values.map((options, index) => {
                    if (options.key) {
                      dispatch(
                        updateMandatoryTrans({
                          obj: key,
                          key: options.value,
                          value: options.value,
                        })
                      );
                    }
                  });
                } else if (fields.type.toLowerCase() === 'text') {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: fields.label,
                      value: fields.label,
                    })
                  );
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: fields.placeholder,
                      value: fields.placeholder,
                    })
                  );
                  if (fields?.validations?.max) {
                    dispatch(
                      updateMandatoryTrans({
                        obj: key,
                        key: `max${fields.validations.max}`,
                        value: '',
                      })
                    );
                  }
                  if (fields?.validations?.min) {
                    dispatch(
                      updateMandatoryTrans({
                        obj: key,
                        key: `min${fields.validations.min}`,
                        value: '',
                      })
                    );
                  }
                  if (fields?.validations?.regex?.msg) {
                    dispatch(
                      updateMandatoryTrans({
                        obj: key,
                        key: fields?.validations?.regex?.msg,
                        value: fields?.validations?.regex?.msg,
                      })
                    );
                  }
                }
              } else if (fields.type.toLowerCase() === 'dropdown') {
                const dropdownVal = fields.values.map((options, index) => {
                  if (options.key) {
                    if (
                      key in translations &&
                      Object.keys(translations[key]).length !== 0 &&
                      !(options.value in translations[key])
                    ) {
                      dispatch(
                        updateMandatoryTrans({
                          obj: key,
                          key: options.value,
                          value: options.value,
                        })
                      );
                    }
                  }
                });
              } else if (fields.type.toLowerCase() === 'text') {
                if (fields?.validations?.max) {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: `max${fields.validations.max}`,
                      value: '',
                    })
                  );
                }
                if (fields?.validations?.min) {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: `min${fields.validations.min}`,
                      value: '',
                    })
                  );
                }
                if (fields?.validations?.regex?.msg) {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: fields?.validations?.regex?.msg,
                      value: fields?.validations?.regex?.msg,
                    })
                  );
                }
              }
            } else if (fields.name in customizeField) {
              if (fields.type.toLowerCase() === 'dropdown') {
                dispatch(
                  updateMandatoryTrans({
                    obj: key,
                    key: fields.label,
                    value: '',
                  })
                );
                dispatch(
                  updateMandatoryTrans({
                    obj: key,
                    key: fields.placeholder,
                    value: '',
                  })
                );
                const dropdownVal = fields.values.map((options, index) => {
                  if (options.key) {
                    dispatch(
                      updateMandatoryTrans({
                        obj: key,
                        key: options.value,
                        value: '',
                      })
                    );
                  }
                });
              } else if (fields.type.toLowerCase() === 'text') {
                dispatch(
                  updateMandatoryTrans({
                    obj: key,
                    key: fields.label,
                    value: '',
                  })
                );
                dispatch(
                  updateMandatoryTrans({
                    obj: key,
                    key: fields.placeholder,
                    value: '',
                  })
                );
                if (fields?.validations?.max) {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: `max${fields.validations.max}`,
                      value: '',
                    })
                  );
                }
                if (fields?.validations?.min) {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: `min${fields.validations.min}`,
                      value: '',
                    })
                  );
                }
                if (fields?.validations?.regex?.msg) {
                  dispatch(
                    updateMandatoryTrans({
                      obj: key,
                      key: fields?.validations?.regex?.msg,
                      value: '',
                    })
                  );
                }
              }
            } else if (fields.type.toLowerCase() === 'dropdown') {
              const dropdownVal = fields.values.map((options, index) => {
                if (options.key) {
                  if (
                    key in translations &&
                    Object.keys(translations[key]).length !== 0 &&
                    !(options.value in translations[key])
                  ) {
                    dispatch(
                      updateMandatoryTrans({
                        obj: key,
                        key: options.value,
                        value: '',
                      })
                    );
                  }
                }
              });
            } else if (fields.type.toLowerCase() === 'text') {
              if (fields?.validations?.max) {
                dispatch(
                  updateMandatoryTrans({
                    obj: key,
                    key: `max${fields.validations.max}`,
                    value: '',
                  })
                );
              }
              if (fields?.validations?.min) {
                dispatch(
                  updateMandatoryTrans({
                    obj: key,
                    key: `min${fields.validations.min}`,
                    value: '',
                  })
                );
              }
              if (fields?.validations?.regex?.msg) {
                dispatch(
                  updateMandatoryTrans({
                    obj: key,
                    key: fields?.validations?.regex?.msg,
                    value: '',
                  })
                );
              }
            }
          });
          setTiggerTrans(false);
        });
      }
    }
  }, [tiggerTrans]);
  const validations = (val) => {
    if (trimData(val) === '') {
      return 'Required';
    }
    return '';
  };
  const buildForm = (errors) => {
    try {
      if (Object.keys(mandatoryTrans).length > 0) {
        return Object.entries(mandatoryTrans).map(([key, data], index) => {
          if (Object.keys(mandatoryTrans[key]).length > 0) {
            return (
              <div key={index} className="my-2">
                <div className="h4 fw-bold">{`${getLanguage(
                  key.split('-')[0]
                )} `}</div>
                {Object.entries(mandatoryTrans[key]).map(
                  ([keys, datas], indexs) => {
                    return (
                      <div key={indexs} className="px-2 pb-1">
                        <div className="h6">{keys}</div>
                        <Field
                          className="form-control"
                          placeholder={keys}
                          type="text"
                          name={`${key}.${keys}`}
                          validate={validations}
                        />
                        {Object.keys(errors).length !== 0 &&
                          key in errors &&
                          Object.keys(errors[key]).length !== 0 &&
                          errors[key][keys] && (
                            <div className="text-danger">
                              {errors[key][keys]}
                            </div>
                          )}
                      </div>
                    );
                  }
                )}
              </div>
            );
          }
        });
      }
    } catch (e) {
      console.log('e ', e);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <div className="text-dark">
          <div className="text-start">
            <b className="h3">Mandatory Translations</b>
          </div>
          <div>
            <Formik
              initialValues={{ ...mandatoryTrans }}
              onSubmit={(values) => {
                dispatch(appendTransvalue(values));
                closeModal();
                handleSubmitModel();
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  {buildForm(errors)}
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn"
                      type="submit"
                      style={{ backgroundColor: '#035EB7' }}
                    >
                      <span className="text-white align-middle">Submit</span>{' '}
                      <span className="text-white ms-1 align-middle material-symbols-outlined">
                        double_arrow
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
