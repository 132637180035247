import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import './homepage.css';
import { useNavigate } from 'react-router-dom';
import LinkedIn from './linkedin-new.png';
import Twitter from './icons8-twitter-50.png';
import Youtube from './icons8-youtube-50.png';
import Facebook from './icons8-facebook-50.png';
import Instagram from './icons8-instagram-50.png';

function Homepage() {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const loggingIn = async () =>
    oktaAuth.signInWithRedirect({ originalUri: '/dashboard' });
  useEffect(() => {
    // if(localStorage.getItem("okta-token-storage")){
    //   navigate("/dashboard");
    // }
    if (authState?.isAuthenticated) {
      navigate('/dashboard');
    }
  }, [authState?.isAuthenticated]);

  return (
    <div className="fullpage-home">
      <div className="top-container d-flex align-items-center">
        <div className="pl-5 logo-component">
          <img
            className="logo-top"
            src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg"
            alt="left-logo"
          />
        </div>
      </div>
      <div className="middle-container">
        <div className="d-flex w-25 h-100 align-items-center ml-5">
          <div>
            <div className="left-text-container">
              <h2 className="big-text"> Welcome to Amgen Passport</h2>
              <div className="text-dark text-opacity-75">
                This site has been designed to provide healthcare professionals
                with the latest information and resources to enhance patient
                outcomes, whilst being tailored to suit each physician's
                preferences.
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button onClick={loggingIn} className="btn btn-primary px-3">
                Sign In
                <span className="pl-3">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="arrow-right"
                    className="arrow"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row px-3 footer-container overflow-auto">
        <div className="col-12 col-lg-6 px-0">
          <div className="left-footer-logo">
            <img
              className="left-amgen-logo"
              src="https://d2y8anc3gh6pek.cloudfront.net/bed9bbf07f03344fc6b3302bb5ccb559.png"
              alt="left-footer-logo"
            />
          </div>
          <div className="small-content-footer">
            <small>
              Amgen is committed to providing its customers, patients and care
              givers the most comprehensive and up-to-date information and
              resources possible. If you need anything additional to enhance
              your or your patients treatment experience, please do not hesitate
              to contact us
            </small>
          </div>
          <div className="left-footer-approved">
            <small>
              AU-04433 AU-05577 AU-06829 AU-09635 AU-05599 Approved May 2019
            </small>
          </div>
        </div>
        <div className="col-12 col-lg-6 justify-content-end px-0">
          <div className="justify-content-end three-box">
            <div className="terms-right-footer">
              <span className="terms-right-footer-text"> Terms of Use</span>
              <span className="vertical-line-footer"> | </span>
            </div>
            <div className="privacy-right-footer">
              <span className="privacy-right-footer-text">Privacy Policy</span>
              <span className="vertical-line-footer"> | </span>
            </div>
            <div className="contactus-right-footer">
              <span className="contactus-right-footer-text"> Contact Us</span>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="hr w-75 bg-white" style={{ height: '1px' }} />
          </div>
          <div className="socialnetwork-right">
            <div className="justify-content-end text-with-icons">
              <small className="rightfooter-line-below-content">
                Stay Connected With Amgen
              </small>
              <small className="icons">
                <img className="icon1" src={LinkedIn} alt="linkedin" />
                <img className="icon2" src={Twitter} alt="twitter" />
                <img className="icon3" src={Youtube} alt="youtube" />
                <img className="icon4" src={Facebook} alt="facebook" />
                <img className="icon5" src={Instagram} alt="instagram" />
              </small>
            </div>
          </div>
          <div className="d-flex justify-content-end rights-container-footer">
            <small> ©️1996-2021 Amgen Inc. All Rights Reserved . </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
