import React, { useState } from 'react';

import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    background: 'white',
    color: 'white',
  },
};

function Viewpopup({ modalIsOpen, closeModal, r }) {
  function handleClose() {
    closeModal();
  }

  let subtitle;

  // function afterOpenModal() {

  //     subtitle.style.color = 'white';
  // }

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
        // //onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        appElement={document.getElementById('app')}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <div>
          <button
            onClick={() => {
              handleClose();
            }}
            style={{
              textDecoration: 'none',
              border: 'none',
              borderRadius: '16px',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            className="material-symbols-outlined position-absolute top-0 end-0"
          >
            close
          </button>
          <div style={{ color: '#0063c3' }}>View</div>
          <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
            {r.request_type !== 'UpdateRole' &&
              r.request_type !== 'RemoveAppAccess' && (
                <div className="d-flex text-dark">
                  <div className="col-5">Application Name </div>:{' '}
                  <div className="col-4">{r.application_name}</div>
                </div>
              )}

            <div className="d-flex text-dark">
              <div className="col-5">User </div>:{' '}
              <div className="col-4">{r?.data?.email}</div>
            </div>

            {r.request_type === 'RemoveAppAccess' && (
              <div className="d-flex text-dark">
                <div className="col-5">Remove Applications</div>:
                <div className="ms-2 col-4 text-primary">
                  {r?.data?.application_name.map((data) => (
                    <div>{data}</div>
                  ))}
                </div>
              </div>
            )}

            {r.request_type === 'UpdateRole' && (
              <div className="d-flex text-dark">
                <div className="col-5">Requested Role </div>:{' '}
                <div className="col-4">{r.data.role}</div>
              </div>
            )}

            <div className="d-flex text-dark">
              <div className="col-5">Request Type </div>:{' '}
              <div className="col-4">{r.request_type}</div>
            </div>

            {r.request_type === 'AddUser' && (
              <div className="d-flex text-dark">
                <div className="col-5">Role </div>:{' '}
                <div className="col-4">{r.data.role}</div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Viewpopup;
