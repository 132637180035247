import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { m } from 'framer-motion';
import * as constant from '../Constants/constant';
import Rejectpopup from '../Popup/Rejectpopup';
import EmailTemplatePreviewPopup from '../Popup/emailTemplatePreviewPopup';
import Previewpopup from '../Popup/Previewpopup';
import Viewpopup from '../Popup/Viewpopup';
import ClientCreationPopup from '../Popup/clientcreationpopup';
import OnboardRequestPopup from '../Popup/onboardrequestpopup';
import UpdateTranslationPopup from '../Popup/translationpopup';
import Editpopup from '../Popup/Editpopup';
import UrlModel from '../Popup/urlpopup';
import MyComponent from '../Table/Table';
import { sanitizeUserInput } from '../../helper/sanitize';
import {
  updateLoader,
  setOnboardData,
  completeButtonDisable,
  updateOnboardKind,
  updateRequestId,
} from '../../store/slice';
import { approvedcolumns, promotedcolumns, rejectedcolumns } from './columns';
import OnboardView from '../Popup/onboardview';

export default function Pending({ setApprove }) {
  axios.defaults.withCredentials = true;
  const { role } = useSelector((state) => state.dashboard);
  const { csrf, show } = useSelector((state) => state.app);
  const { adminApplications } = useSelector((state) => state.onboard);
  const [addcomment, setAddcomment] = React.useState(false);
  const [appname, setAppname] = useState('');
  const dispatch = useDispatch();
  const [requestid, setRequestid] = useState('');
  const [needhelp, setNeedhelp] = useState('');
  const [tnc_link, setTncLink] = useState('');
  const [need_help_link, setNeedHelpLink] = useState('');
  const [amgen_uri, setAmgenUri] = useState('');
  const [footer_logo, setFooterLogo] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [privacy_link, setPrivacyLink] = useState('');
  const [copy_right_year, setCopyRightYear] = useState('');
  const [body, setBody] = useState('');
  const [login, setLogin] = useState('');
  const [tnc, settnc] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [salutation, setSalutation] = useState('');
  const [banner, setBanner] = useState('');
  const [title, setTitle] = useState('');
  const [r, setR] = useState('');
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenMailPreview, setIsOpenMailPreview] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [clientcreationopen, setClientcreationpopup] = React.useState(false);
  const [clientcreationdata, setClientcreationdata] = React.useState('');
  const [onboardopen, setOnboardopen] = React.useState(false);
  const [onboarddata, setOnbaorddata] = React.useState('');
  const [updatetranslationpopup, setUpdatetranslationpopup] =
    React.useState(false);
  const [updatetranslationdata, setUpdatetranslationdata] = React.useState({});
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [url, setUrl] = useState({ url: [], redirectURI: [] });
  const [urlModel, seturlOpen] = useState(false);
  const [data, setData] = useState('');
  const [onboardView, setOnboard] = useState(false);
  const [onboardPayload, setOnboardPayload] = useState({});
  const [data1, setData1] = useState([]);
  const [active1, setActive1] = useState('1');
  // const [approveLoading, setApprove] = useState(false);
  const user = localStorage.getItem('username');
  const navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`, {
        headers: { ...constant.headers },
      })

      .then((res) => {
        if (role == 'Admin') {
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
              [{ key: 'status', value: 'pending' }],
              {
                headers: {
                  ...constant.headers,
                  'csrf-token': res.data.data.csrfToken,
                },
              }
            )
            .then((response) => {
              dispatch(updateLoader());
              const { data } = response.data;
              const data1 = data.filter((data) => {
                return adminApplications.includes(data.application_name);
              });
              setData(data1);
            })
            .catch((error) => {
              console.log(sanitizeUserInput(JSON.stringify(error)));
            });
          return () => {
            dispatch(updateLoader());
          };
        }
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
            [{ key: 'status', value: 'pending', condition: 'contains' }],
            {
              headers: {
                ...constant.headers,
                'csrf-token': res.data.data.csrfToken,
              },
            }
          )
          .then((response) => {
            dispatch(updateLoader());
            const data1 = response.data.data.filter(
              ((data) => data.application_name != '') &&
                ((data) => data.application_name != null)
            );
            setData(data1);
          })
          .catch((error) => {
            console.log(sanitizeUserInput(JSON.stringify(error)));
          });
        return () => {
          dispatch(updateLoader());
        };
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }, []);

  function closeCommentmodal() {
    setAddcomment(false);
    dispatch(updateLoader());

    if (role == 'Admin') {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
          [{ key: 'status', value: 'pending' }],
          {
            headers: { ...constant.headers, 'csrf-token': csrf },
          }
        )
        .then((response) => {
          dispatch(updateLoader());
          const { data } = response.data;
          setData(
            data.filter((data) => {
              return adminApplications.includes(data.application_name);
            })
          );
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)));
        });
      return () => {
        dispatch(updateLoader());
      };
    }
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
        [{ key: 'status', value: 'pending', condition: 'contains' }],
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        const data1 = response.data.data.filter(
          ((data) => data.application_name != '') &&
            ((data) => data.application_name != null)
        );
        setData(data1);
        dispatch(updateLoader());
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
    return () => {
      dispatch(updateLoader());
    };
  }

  function closeMailModal() {
    setIsOpenMailPreview(false);
  }

  function closeClientCreation() {
    setClientcreationpopup(false);
    // setApprove(false);
  }

  function openClientCreation(e, r) {
    setClientcreationdata(r);
    setClientcreationpopup(true);
  }

  function closeTranslationpopup() {
    setUpdatetranslationpopup(false);
  }

  function openTranslationpopup(e, r) {
    setUpdatetranslationdata(r?.data?.data);
    setUpdatetranslationpopup(true);
  }

  function closeOnboardpopup() {
    setOnboardopen(false);
  }

  function openOnbaordpopup(e, r) {
    setOnbaorddata(r?.data);
    setOnboardopen(true);
  }

  function closePreviewModal() {
    setIsOpenPreview(false);
  }

  function openModal2(e, r) {
    if (r.request_type == 'onboard') {
      dispatch(completeButtonDisable(false));
      dispatch(updateOnboardKind('adminEdit'));
      dispatch(updateRequestId(r.request_id));

      // axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-app`,{application_name:r.application_name},
      //     {
      //           headers: { ...constant.headers,"csrf-token": csrf },
      //     }
      //     )
      //     .then((response) => {
      dispatch(setOnboardData(r.data));
      navigate(`/myrequest/Onboard`);
      // })
      // .catch(error => {console.log(error)})
    } else {
      setR(r);
      setIsOpen2(true);
    }
  }

  function closeModal2() {
    dispatch(updateLoader());
    setIsOpen2(false);

    if (role == 'Admin') {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
          [{ key: 'status', value: 'pending' }],
          {
            headers: { ...constant.headers, 'csrf-token': csrf },
          }
        )
        .then((response) => {
          dispatch(updateLoader());
          const { data } = response.data;
          setData(
            data.filter((data) => {
              return adminApplications.includes(data.application_name);
            })
          );
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)));
        });
      return () => {
        dispatch(updateLoader());
      };
    }
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
        [{ key: 'status', value: 'pending stage 2' }],
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        dispatch(updateLoader());
        setData(
          response.data.data.filter((data) => data.application_name !== '')
        );
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
    return () => {
      dispatch(updateLoader());
    };
  }

  function openurlpopup() {
    seturlOpen(true);
  }

  function closeurlpopup() {
    seturlOpen(false);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function handleOnboard() {
    setOnboard((pre) => !pre);
  }

  async function openModal(e, r) {
    switch (r.request_type) {
      case 'onboard':
        // dispatch(completeButtonDisable(false));
        // dispatch(updateOnboardKind("adminEdit"));
        // dispatch(updateRequestId(r.request_id));
        // dispatch(setOnboardData(r.data));
        // navigate(`/myrequest/onboard`);
        setOnboardPayload(r.data);
        handleOnboard();
        break;
      case 'whitelist':
        setUrl(r.data);
        openurlpopup();
        break;
      case 'frEmailTemplate':
        setR(r.data.data.body);
        openPreviewModal();
        break;
      case 'ClientCreation':
        // setR(r.data.data.body)
        // openPreviewModal();
        openClientCreation(e, r);
        break;
      case 'updateTranslation':
        // setR(r.data.data.body)
        // openPreviewModal();
        openTranslationpopup(e, r);
        break;
      case 'preload':
        try {
          const { fname } = r.data;
          const newCsvData = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/api/downloading-preload`,
            data: { requestId: r.request_id, fname },
            headers: {
              ...constant.headers,
              'csrf-token': csrf,
              // "Content-Type": "multipart/form-data"
            },
          });

          const { data } = newCsvData.data;
          const csvdata = csvmaker(data);
          download(csvdata, r);
        } catch (error) {
          console.log(sanitizeUserInput(JSON.stringify(error)));
        }
        break;
      case 'languageTemplate':
        for (const key in r.data.data) {
          if (key.includes('email_title')) {
            setTitle(r.data.data[key]);
          }
          if (key.includes('email_body')) {
            setBody(r.data.data[key]);
          }
          if (key.includes('tnc') && !key.includes('tnc_link')) {
            settnc(r.data.data[key]);
          }
          // if (key.includes("need_help_link")) {
          //   setNeedHelpLink(r.data.data[key]);
          // }
          if (key.includes('privacy') && !key.includes('privacy_link')) {
            setPrivacy(r.data.data[key]);
          }
          if (key.includes('privacy_link')) {
            setPrivacyLink(r.data.data[key]);
          }
          if (key.includes('tnc_link')) {
            setTncLink(r.data.data[key]);
          }
          if (key.includes('amgen_uri')) {
            setAmgenUri(r.data.data[key]);
          }
          if (key.includes('footer_logo')) {
            setFooterLogo(r.data.data[key]);
          }
          if (key.includes('banner')) {
            setBanner(r.data.data[key]);
          }
          if (key.includes('copy_right_year')) {
            setCopyRightYear(r.data.data[key]);
          }

          if (key.includes('_address_line1')) {
            setAddress1(r.data.data[key]);
          }
          if (key.includes('_address_line2')) {
            setAddress2(r.data.data[key]);
          }
          // if (key.includes("need_help") && !key.includes("need_help_link")) {
          //   setNeedhelp(r.data.data[key]);
          // }
          if (
            key.includes('login_logo') ||
            (key.includes('contact_us') && !key.includes('contact_us_link'))
          ) {
            setLogin(r.data.data[key]);
          }
          if (key.includes('_salutation')) {
            setSalutation(r.data.data[key]);
          }
          if (key.includes('email_head')) {
            setR({ r });
          }
        }
        openMailModal();
        break;
      default:
        setR(r);
        setIsOpen(true);
        break;
    }

    // if(r.request_type == "onboard"){
    //     dispatch(completeButtonDisable(true));

    // // axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-app`,{application_name:r.application_name},
    // //     {
    // //            headers: { ...constant.headers,"csrf-token": csrf },
    // //     }
    // //     )
    // //     .then((response) => {
    //         dispatch(setOnboardData(r.data));
    //         navigate(`/myrequest/Onboard`);
    //     // })
    //     // .catch(error => {console.log(error)})
    // }else{
    // setR(r);
    // setIsOpen(true);
    // }
  }

  const handleClick = (event) => {
    setActive1(event.target.id);
    dispatch(updateLoader());

    if (role == 'Admin') {
      setData('');

      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
          [{ key: 'status', value: event.target.name }],
          {
            headers: { ...constant.headers, 'csrf-token': csrf },
          }
        )
        .then((response) => {
          dispatch(updateLoader());
          const { data } = response.data;
          setData(
            data.filter((data) => {
              return adminApplications.includes(data.application_name);
            })
          );
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)));
        });
      return () => {
        dispatch(updateLoader());
      };
    }
    setData('');
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
        [{ key: 'status', value: event.target.name, condition: 'contains' }],
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        const data1 = response.data.data;
        // .filter(
        //   ((data) => data.application_name != "") &&
        //     ((data) => data.application_name != null)
        // );
        setData(data1);
        dispatch(updateLoader());
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
    return () => {
      dispatch(updateLoader());
    };
  };

  function handleReject(request_id, application_name) {
    setRequestid(request_id);
    setAppname(application_name);
    openCommentmodal();
  }

  function handleApproval(request_id, request_type, application_name, data, r) {
    const dataBckup = data;
    let endpoint = 'approve-user-access';
    let mtd = 0;
    let request = {
      request_id,
      appname: application_name,
      processed_by: user,
      role,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`, {
        headers: { ...constant.headers },
      })

      .then((res_csrf) => {
        if (request_type == 'onboard') {
          endpoint = 'approve-onboard-request';
          mtd = 0;
          request = {
            ...request,
            data,
          };
        } else if (
          request_type == 'activation' ||
          request_type == 'deactivation'
        ) {
          endpoint = 'approve-app-activation-request';
          mtd = 0;
          request = {
            ...request,
            status: request_type == 'activation' ? 'Active' : 'Deactive',
          };
        } else if (request_type == 'access') {
          endpoint = 'approve-user-access';
          mtd = 0;
        } else if (request_type == 'whitelist') {
          endpoint = 'approve-whitelist-urls';
          mtd = 0;
        } else if (request_type == 'frEmailTemplate') {
          endpoint = 'fr/approve-frmail-template';
          mtd = 0;
        } else if (request_type == 'languageTemplate') {
          endpoint = 'update-translation';
          mtd = 0;
        } else if (request_type == 'preload') {
          endpoint = 'preload-users';
          mtd = 0;
        } else if (request_type == 'updateTranslation') {
          endpoint = 'update-translation';
          mtd = 0;
        } else if (request_type == 'UpdateRole') {
          endpoint = 'approve-role-update';
          mtd = 0;
        } else if (request_type == 'AddUser') {
          endpoint = 'approve-create-user';
          mtd = 0;
        } else if (request_type == 'RemoveAppAccess') {
          endpoint = 'remove-app-access';
          mtd = 0;
        } else if (request_type == 'ClientCreation') {
          endpoint = 'approve-client-creation';
          mtd = 1;

          axios
            .put(
              `${process.env.REACT_APP_API_BASE_URL}/api/approve-client-creation`,
              {
                ...request,
              },
              {
                headers: {
                  ...constant.headers,
                  'csrf-token': res_csrf?.data?.data?.csrfToken,
                },
              }
            )
            .then((response) => {
              dispatch(updateLoader());
              setData('');
              axios
                .post(
                  `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
                  [{ key: 'status', value: 'pending', condition: 'contains' }],
                  {
                    headers: { ...constant.headers, 'csrf-token': csrf },
                  }
                )
                .then((response) => {
                  const data1 = response.data.data;
                  // .filter(
                  //   ((data) => data.application_name != "") &&
                  //     ((data) => data.application_name != null)
                  // );
                  setData(data1);
                  // dispatch(updateLoader());
                  setApprove(false);
                })
                .catch((error) => {
                  setApprove(false);
                  console.log(sanitizeUserInput(JSON.stringify(error)));
                  setData(dataBckup);
                });
            })
            .catch((error) => {
              setApprove(false);
              console.log(sanitizeUserInput(JSON.stringify(error)));
            });
        } else if (request_type == 'AddUser') {
          endpoint = 'approve-create-user';
          mtd = 0;
        } else {
          endpoint = '';
        }
        setApprove(true);
        if (role == 'Super Admin') {
          if (mtd === 0) {
            axios
              .post(
                `${process.env.REACT_APP_API_BASE_URL}/api/${endpoint}`,
                {
                  ...request,
                },
                {
                  headers: {
                    ...constant.headers,
                    'csrf-token': res_csrf?.data?.data?.csrfToken,
                  },
                }
              )
              .then((response) => {
                dispatch(updateLoader());
                setData('');
                axios
                  .post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
                    [
                      {
                        key: 'status',
                        value: 'pending',
                        condition: 'contains',
                      },
                    ],
                    {
                      headers: {
                        ...constant.headers,
                        'csrf-token': res_csrf?.data?.data?.csrfToken,
                      },
                    }
                  )
                  .then((response) => {
                    const data1 = response.data.data;
                    // .filter(
                    //   ((data) => data.application_name != "") &&
                    //     ((data) => data.application_name != null)
                    // );
                    setData(data1);
                    dispatch(updateLoader());
                    setApprove(false);
                  })
                  .catch((error) => {
                    setApprove(false);
                    console.log(sanitizeUserInput(JSON.stringify(error)));
                    setData(dataBckup);
                  });
              })

              .catch((error) => {
                setApprove(false);
                console.log(sanitizeUserInput(JSON.stringify(error)));
              });
          } else if (endpoint !== 'approve-client-creation') {
            axios
              .put(
                `${process.env.REACT_APP_API_BASE_URL}/api/${endpoint}`,
                {
                  ...request,
                },
                {
                  headers: {
                    ...constant.headers,
                    'csrf-token': res_csrf?.data?.data?.csrfToken,
                  },
                }
              )
              .then((response) => {
                dispatch(updateLoader());
                setData('');
                axios
                  .post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
                    [
                      {
                        key: 'status',
                        value: 'pending',
                        condition: 'contains',
                      },
                    ],
                    {
                      headers: {
                        ...constant.headers,
                        'csrf-token': res_csrf?.data?.data?.csrfToken,
                      },
                    }
                  )
                  .then((response) => {
                    const data1 = response.data.data;
                    // .filter(
                    //   ((data) => data.application_name != "") &&
                    //     ((data) => data.application_name != null)
                    // );
                    setData(data1);
                    dispatch(updateLoader());
                    setApprove(false);
                  })
                  .catch((error) => {
                    setApprove(false);
                    console.log(sanitizeUserInput(JSON.stringify(error)));
                    setData(dataBckup);
                  });
              })

              .catch((error) => {
                setApprove(false);
                console.log(sanitizeUserInput(JSON.stringify(error)));
              });
          }
        } else {
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/api/promote`,
              {
                request_id: r.request_id,
                status: 'pending stage 2',
                processed_by: localStorage.getItem('username'),
              },
              {
                headers: {
                  ...constant.headers,
                  'csrf-token': res_csrf?.data?.data?.csrfToken,
                },
              }
            )
            .then((response) => {
              setData('');
              axios
                .post(
                  `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
                  [{ key: 'status', value: 'pending' }],
                  {
                    headers: { ...constant.headers, 'csrf-token': csrf },
                  }
                )
                .then((response) => {
                  const { data } = response.data;
                  setData(
                    data.filter((data) => {
                      return adminApplications.includes(data.application_name);
                    })
                  );
                  setApprove(false);
                })
                .catch((error) => {
                  setApprove(false);
                  console.log(sanitizeUserInput(JSON.stringify(error)));
                  setData(dataBckup);
                });
              return () => {
                dispatch(updateLoader());
              };
            })
            .catch((error) => {
              setApprove(false);
              console.log(sanitizeUserInput(JSON.stringify(error)));
            });
        }
      })
      .catch((error) => {});
  }

  function openCommentmodal() {
    setAddcomment(true);
  }

  function openMailModal() {
    setIsOpenMailPreview(true);
  }

  function closeMailModal() {
    setIsOpenMailPreview(false);
  }

  const csvmaker = function (data) {
    // Empty array for storing the values
    const csvRows = [];

    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    const headers = Object.keys(data[0]);

    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    csvRows.push(headers.join(','));

    // Pushing Object values into array
    // with comma separation
    for (const i in data) {
      const values = Object.values(data[i]).join(',');
      csvRows.push(values);
    }
    // Returning the array joining with new line
    return csvRows.join('\n');
  };

  const download = function (data, r) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a');

    // Passing the blob downloading url
    a.setAttribute('href', url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', `${r.request_id}.csv`);

    // Performing a download with click
    a.click();
  };

  function openPreviewModal() {
    setIsOpenPreview(true);
  }

  function closePreviewModal() {
    setIsOpenPreview(false);
  }

  const pendingcolumns = [
    {
      name: 'Application Name',
      cell: (row) => row.application_name,
      selector: (row) => row.application_name,
    },
    {
      name: 'Request Type',
      cell: (row) => row.request_type,
      selector: (row) => row.request_type,
    },
    {
      name: 'Created by',
      cell: (row) => row.email,
      selector: (row) => row.email,
    },
    {
      name: 'Requested Date',
      cell: (row) => row.requested_date,
      sortable: true,
      selector: (row) => row.requested_date,
    },
    {
      name: 'Actions',
      allowOverflow: true,
      cell: (r) => {
        return (
          <div>
            {r.request_type !== 'preload' && (
              <span
                style={{ fontSize: '18px' }}
                role="button"
                className="material-symbols-outlined"
                onClick={(e) => {
                  openModal(e, r);
                }}
              >
                visibility
              </span>
            )}
            {r.request_type === 'preload' && (
              <span
                style={{ fontSize: '18px' }}
                role="button"
                className="material-symbols-outlined"
                onClick={(e) => {
                  openModal(e, r);
                }}
              >
                download
              </span>
            )}
            {/* &nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ fontSize: "18px" }} className="material-symbols-outlined" type="button" data-toggle="tooltip" title="Edit"
                  onClick={(e) => {openModal2(e,r)}}
                  >edit</span> */}
          </div>
        );
      },
    },
    {
      name: 'Status',
      sortable: true,
      cell: (row) => row.status,
      selector: (row) => row.status,
    },
    {
      name: 'Approve/Reject',
      cell: (r) => {
        return (
          <div>
            <span
              type="button"
              className="material-symbols-outlined iconfontsize"
              style={{
                color: 'white',
                background: '#045EB7',
                borderRadius: '15px',
              }}
              onClick={() => {
                setApprove(true);
                handleApproval(
                  r.request_id,
                  r.request_type,
                  r.application_name,
                  r.data,
                  r
                );
              }}
            >
              done
            </span>
            &nbsp;
            <span
              type="button"
              className="material-symbols-outlined iconfontsize"
              style={{
                color: 'white',
                background: '#DA5454',
                borderRadius: '15px',
              }}
              onClick={() => {
                handleReject(r.request_id, r.application_name);
              }}
            >
              close
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          backgroundColor: '#EDF2F6',
          height: '90vh',
          overflow: 'hidden',
          width: '101%',
        }}
      >
        <div style={{ marginTop: '2rem', marginLeft: '3rem' }}>
          <button
            key={1}
            className={active1 === '1' ? 'active1' : 'active2'}
            id="1"
            name="pending"
            onClick={handleClick}
          >
            Pending
          </button>

          <button
            key={3}
            className={active1 === '3' ? 'active1' : 'active2'}
            id="3"
            name="approved"
            onClick={handleClick}
          >
            Approved
          </button>

          <button
            key={2}
            className={active1 === '2' ? 'active1' : 'active2'}
            id="2"
            name="rejected"
            onClick={handleClick}
          >
            Rejected
          </button>

          {role == 'Admin' ? (
            <button
              key={4}
              className={active1 === '4' ? 'active1' : 'active2'}
              id="4"
              name="pending stage 2"
              onClick={handleClick}
            >
              Promoted
            </button>
          ) : (
            ''
          )}
        </div>

        <div className="onboardbodyfirst">
          <div className="tablebo" style={{ padding: ' 2rem 3rem 0rem 2rem' }}>
            {active1 === '1' && (
              <MyComponent columns={pendingcolumns} data={data} />
            )}
            {active1 === '2' && (
              <MyComponent columns={rejectedcolumns} data={data} />
            )}
            {active1 === '3' && (
              <MyComponent columns={approvedcolumns} data={data} />
            )}
            {active1 === '4' && (
              <MyComponent columns={promotedcolumns} data={data} />
            )}

            <Viewpopup
              modalIsOpen={modalIsOpen}
              r={r}
              closeModal={closeModal}
            />
            <ClientCreationPopup
              modalIsOpen={clientcreationopen}
              data={clientcreationdata}
              closeModal={closeClientCreation}
            />
            <UpdateTranslationPopup
              modalIsOpen={updatetranslationpopup}
              data={updatetranslationdata}
              closeModal={closeTranslationpopup}
            />
            <OnboardRequestPopup
              modalIsOpen={onboardopen}
              data={onboarddata}
              closeModal={closeOnboardpopup}
            />
            <UrlModel
              modalIsOpen={urlModel}
              closeModal={closeurlpopup}
              data={url}
            />
            <Editpopup
              modalIsOpen2={modalIsOpen2}
              r={r}
              closeModal2={closeModal2}
            />
            <EmailTemplatePreviewPopup
              modalIsOpen={isOpenPreview}
              closeModal={closePreviewModal}
              data={r}
            />
            <OnboardView
              modalIsOpen={onboardView}
              payload={onboardPayload}
              closeModal={handleOnboard}
            />
            <Previewpopup
              modalIsOpen={isOpenMailPreview}
              closeModal={closeMailModal}
              data={r}
              title={title}
              body={body}
              login={login}
              tnc={tnc}
              banner={banner}
              tnc_link={tnc_link}
              privacy={privacy}
              privacy_link={privacy_link}
              need_help_link={need_help_link}
              footer_logo={footer_logo}
              copy_right_year={copy_right_year}
              amgen_uri={amgen_uri}
              address1={address1}
              address2={address2}
              salutation={salutation}
              needhelp={needhelp}
            />
            <Rejectpopup
              requestid={requestid}
              appname={appname}
              opencommentmodal={addcomment}
              closecommentmodal={closeCommentmodal}
            />
          </div>
        </div>
      </div>
      {/* {approveLoading && <Loader />} */}
    </div>
  );
}

export async function downloadReport(r) {
  try {
    const newCsvData = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/downloading-preload`,
      data: { requestId: r.request_id, fname: `${r.request_id}_report` },
      headers: {
        ...constant.headers,
        'csrf-token': Pending.csrf,
        // "Content-Type": "multipart/form-data"
      },
    });
    const { data } = newCsvData.data;
    const csvdata = csvmaker(data);
    download(csvdata, r);
  } catch (error) {
    console.log(sanitizeUserInput(JSON.stringify(error)));
  }
}

const csvmaker = function (data) {
  // Empty array for storing the values
  const csvRows = [];

  // Headers is basically a keys of an
  // object which is id, name, and
  // profession
  const headers = Object.keys(data[0]);

  // As for making csv format, headers
  // must be separated by comma and
  // pushing it into array
  csvRows.push(headers.join(','));

  // Pushing Object values into array
  // with comma separation
  for (const i in data) {
    const values = Object.values(data[i]).join(',');
    csvRows.push(values);
  }
  // Returning the array joining with new line
  return csvRows.join('\n');
};

const download = function (data, r) {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', `${r.request_id}.csv`);

  // Performing a download with click
  a.click();
};
