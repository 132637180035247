import React, { Fragment, useState } from 'react';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import AmgenLogo from '../Assets/Group1.svg';
import Sidebar from '../sidebar/Sidebar';
import MyComponent from '../Table/Table';
import * as constant from '../Constants/constant';
import { updateLoader } from '../../store/slice';
import Preview from './preview';
import Testpopup from './testpopup';
import MessagePopup from '../Popup/Messagepopup';
import {
  changeContentSuccessMail,
  setSuccessMailBody,
  setSuccessMailFooter,
  setSuccessMailContentOverride,
  resetSuccessData,
  resetFailureData,
  setTeamNameSlice,
  setNeedHelpSlice,
  setTeamDlSlice,
  setBodySlice,
  setSuccessMailcontent,
  setFailureMailcontent,
  setDeactivatedMailContent,
  setInactiveMailContent,
  setLink,
  setFooterLeft,
  setFooterRight,
  setSuccessAppnameAndLag,
  setFailureAppnameAndLag,
  setFailureMailSender,
  setFailureMailContentOverride,
  setUserData,
} from '../../store/mailslice';
import { sanitizeUserInput } from '../../helper/sanitize';

function Dynamicmailadmin({ template, setTemplate, preval, setPreval }) {
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const { csrf } = useSelector((state) => state.app);
  const [message, setMessage] = useState({
    title: '',
    body: '',
    success: false,
  });
  const [error, setError] = useState({
    terms: '',
    privacy: '',
    url: '',
    dl: '',
  });
  axios.defaults.withCredentials = true;

  function openmessagepopup() {
    setMessageIsOpen(true);
  }
  function closemessagepopup() {
    setMessageIsOpen(false);
  }

  const {
    failureMailContent,
    successMailContent,
    success_application_list,
    inactiveMailContent,
    deactivatedMailContent,
  } = useSelector((state) => state.mailtemplate);
  React.useEffect(() => {
    // let lanList=success_application_list.filter((data)=>data.application_name===successMailContent.application_name);
    // setAllowLan(lanList[0]?.options?.allowedLanguages);
    if (
      successMailContent.application_name !== '' &&
      successMailContent.country !== '' &&
      successMailContent.language !== ''
    ) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/locales/${successMailContent.application_name}/${successMailContent.language}-${successMailContent.country}`,
          {
            headers: { ...constant.headers, 'csrf-token': csrf },
          }
        )
        .then((response) => {
          const a = response.data;
          const filteredByKeyFailure = Object.fromEntries(
            Object.entries(a).filter(
              ([key, value]) =>
                key.includes('hcp_validation') &&
                !key.includes('hcp_validation_success')
            )
          );

          dispatch(setFailureMailcontent(filteredByKeyFailure));

          const filteredByKeySuccess = Object.fromEntries(
            Object.entries(a).filter(([key, value]) =>
              key.includes('hcp_validation_success')
            )
          );
          dispatch(setSuccessMailcontent(filteredByKeySuccess));

          const filteredByKeyInactive = Object.fromEntries(
            Object.entries(response.data).filter(([key, value]) =>
              key.includes('Inactive_users_email')
            )
          );
          dispatch(setInactiveMailContent(filteredByKeyInactive));

          const filteredByKeyDeactivated = Object.fromEntries(
            Object.entries(response.data).filter(([key, value]) =>
              key.includes('deactivated_users_email')
            )
          );
          dispatch(setDeactivatedMailContent(filteredByKeyDeactivated));
        })
        .catch((err) => console.log(sanitizeUserInput(JSON.stringify(err))));

      dispatch(resetSuccessData());
      dispatch(resetFailureData());
    } else {
      dispatch(resetSuccessData());
      dispatch(resetFailureData());
    }
  }, [
    successMailContent.application_name,
    successMailContent.language,
    successMailContent.country,
  ]);

  function setSucValue() {
    const body = successMailContent.hcp_validation_success_email_body.replace(
      /&nbsp;/i,
      ' '
    );
    const data = {
      email: successMailContent.email,
      language: successMailContent.language,
      data: successMailContent.content,
    };
    return data;
  }

  function setInactiveValue() {
    const data = {
      email: inactiveMailContent.email,
      language: inactiveMailContent.language,
      data: inactiveMailContent.content,
    };
    return data;
  }

  function setDeactiveValue() {
    const data = {
      email: deactivatedMailContent.email,
      language: deactivatedMailContent.language,
      data: deactivatedMailContent.content,
    };
    return data;
  }

  function setFailValue() {
    const data = {
      email: failureMailContent.email,
      language: failureMailContent.language,
      data: failureMailContent.content,
    };
    return data;
  }

  const [show, setShow] = useState(true);
  const [preview, handlePreview] = useState(false);
  const [teamname, setTeamname] = useState('');
  const [teamdl, setTeamdl] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [amgenuri, setAmgenuri] = useState('');
  const [termsofuse, setTermsofuse] = useState('');
  const [needhelp, setNeedHelp] = useState('');
  const [allowLan, setAllowLan] = useState([]);
  const user = localStorage.getItem('username');
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [teamnamerep, setteamnamerep] = useState('');
  const [teamdlrep, setteamdlrep] = useState('');
  const [inactiveReplacedBody, setinactiveReplacedBody] = useState('');
  const [deactiveReplacedBody, setdeactiveReplacedBody] = useState('');

  const [changeContent, setChangeContent] = useState({
    teamName: '{{TeamName}}',
    teamDL: '{{TeamDL}}',
    amgenUri: 'https://www.amgen.com',
    terms: '{{termsofuse}}',
    privacy: '{{privacystatement}}',
  });

  function changeValue() {
    let Tempteamnamerep =
      successMailContent?.content?.hcp_validation_success_email_body?.replaceAll(
        changeContent.teamName,
        teamname
      );
    Tempteamnamerep = Tempteamnamerep?.replaceAll(changeContent.teamDL, teamdl);
    let Tempfooter_right;
    if (preval == 1) {
      Tempfooter_right =
        successMailContent?.content?.hcp_validation_success_email_footer_right;
    } else if (preval == 2) {
      Tempfooter_right =
        failureMailContent?.content?.hcp_validation_email_footer_right;
    } else if (preval == 3) {
      Tempfooter_right =
        inactiveMailContent?.content?.Inactive_users_email_footer_right;
    } else if (preval == 4) {
      Tempfooter_right =
        deactivatedMailContent?.content?.deactivated_users_email_footer_right;
    }

    Tempfooter_right = Tempfooter_right?.replaceAll(
      changeContent.terms,
      `'${termsofuse}'`
    );
    Tempfooter_right = Tempfooter_right?.replaceAll(
      changeContent.privacy,
      `'${privacy}'`
    );

    // let Tempfooter_left=successMailContent?.content?.hcp_validation_success_email_footer_left.replaceAll(changeContent.amgenUri,amgenuri);

    let Tempteamdlrep =
      failureMailContent?.content?.hcp_validation_email_body?.replaceAll(
        changeContent.teamDL,
        teamdl
      );
    Tempteamdlrep = Tempteamdlrep?.replaceAll(changeContent.teamName, teamname);

    let TempinactiveReplacedBody =
      inactiveMailContent?.content?.Inactive_users_email_body?.replaceAll(
        changeContent.teamDL,
        teamdl
      );
    TempinactiveReplacedBody = TempinactiveReplacedBody?.replaceAll(
      changeContent.teamName,
      teamname
    );

    let TempdeactiveReplacedBody =
      deactivatedMailContent?.content?.deactivated_users_email_body?.replaceAll(
        changeContent.teamDL,
        teamdl
      );
    TempdeactiveReplacedBody = TempdeactiveReplacedBody?.replaceAll(
      changeContent.teamName,
      teamname
    );

    dispatch(
      setUserData({
        teamname,
        teamdl,
        privacy,
        termsofuse,
        amgenuri,
        success: Tempteamnamerep,
        failure: Tempteamdlrep,
        inactive: TempinactiveReplacedBody,
        deactive: TempdeactiveReplacedBody,
      })
    );
    // dispatch(setTeamNameSlice(teamname))
    // dispatch(setTeamDlSlice(teamdl))
    // dispatch(setNeedHelpSlice(teamdl))
    // dispatch(setFooterLeft(Tempfooter_left))
    dispatch(setFooterRight(Tempfooter_right));

    // dispatch(setLink({privacy:privacy,tnc:termsofuse,amgen:amgenuri}))

    // dispatch(
    //   setBodySlice(
    //     {
    //       success:Tempteamnamerep,
    //       failure:Tempteamdlrep,
    //       inactive:TempinactiveReplacedBody,
    //       deactive:TempdeactiveReplacedBody
    //     }
    //     ))

    setChangeContent({
      ...changeContent,
      teamName: teamname,
      teamDL: teamdl,
      amgenUri: amgenuri,
      terms: `'${termsofuse}'`,
      privacy: `'${privacy}'`,
    });
  }

  function openTestpopup() {
    setIsOpen(true);
  }
  function closeTestpopup() {
    setIsOpen(false);
  }

  return (
    <main className="mt-0 px-5">
      <div>
        {/* <div className="d-flex py-1 shadow-sm mb-4">
          
  
          <div class="me-3 d-flex align-items-center form-check">
                <label class="form-check-label" htmlFor="flexRadioDefault1">
                Application<span className="text-danger">*</span>
                </label>
                <select class="form-select mx-2" aria-label="Default select example"
                   onChange={(event) => {
                    dispatch(
                        setSuccessAppnameAndLag({
                          name: event.target.value,
                        })
                    )
                    dispatch(
                      setFailureAppnameAndLag({
                        name: event.target.value,
                      })
                  )
                    }}>
                    <option selected>Select Application</option>
                    {success_application_list?.map ((option) => {
                      return <option>{option["application_name"]}</option>
                      })}
                       </select>
          </div>
          <div class="me-3 d-flex align-items-center form-check">
                <label class="form-check-label" htmlFor="flexRadioDefault1">
                Language
                </label>
                <select class="form-select mx-2" aria-label="Default select example"
                onChange={(event) => {
                  dispatch(
                      setSuccessAppnameAndLag({
                        lag: event.target.value,
                      })
                  )
                  dispatch(
                    setFailureAppnameAndLag({
                      lag: event.target.value,
                    })
                )
                  }}>
                <option selected>Select Language</option>
                    {allowLan?.map ((option) => {
                      return <option value={option.value}>{option["label"]}</option>
                      })}
                </select>
          </div>
          <div class="me-3 d-flex align-items-center form-check">
                <label class="form-check-label" htmlFor="flexRadioDefault1">
                Template
                </label>
                <select class="form-select mx-2" aria-label="Default select example"
                value={template}
                onChange={(e) => {
                 setTemplate(Number(e.target.value));
                 setPreval(e.target.value)
                  }}>
                <option value={0}>Select Mail Template</option>
                <option value={1}>Success mail</option>
                <option value={2}>Failure mail</option>
                <option value={3}>Inactive notification mail</option>
                <option value={4}>Deactivated notification mail</option>
                
                </select>
          </div>

          </div> */}

        <div className="py-1 shadow-sm mb-4">
          <div className="mb-1 d-flex align-items-center form-check">
            <label
              className="form-check-label text-nowrap col-2"
              htmlFor="flexRadioDefault1"
            >
              Team Name<span className="text-danger">*</span>
            </label>
            <input
              className="form-control mx-2"
              placeholder="Enter Team Name"
              onChange={(e) => {
                handlePreview(false);
                setTeamname(e.target.value);
              }}
            />
          </div>
          <div className="me-3 mb-1 d-flex align-items-center form-check">
            <label
              className="form-check-label text-nowrap col-2"
              htmlFor="flexRadioDefault1"
            >
              Team DL<span className="text-danger">*</span>
            </label>
            <div className="w-100">
              <input
                className="form-control mx-2"
                placeholder="Enter Team DL"
                onChange={(e) => {
                  handlePreview(false);
                  if (e.target.value.length === 0) {
                    setError((pre) => ({ ...pre, dl: '' }));
                  } else if (constant.emailRegex.test(e.target.value.trim())) {
                    setTeamdl(e.target.value);
                    setError((pre) => ({ ...pre, dl: '' }));
                  } else {
                    setError((pre) => ({
                      ...pre,
                      dl: 'Enter the valid email',
                    }));
                  }
                }}
              />
              {error.dl !== '' && (
                <div className="text-danger mx-3">{error.dl}</div>
              )}
            </div>
          </div>
          <div className="me-3 mb-1 d-flex align-items-center form-check">
            <label
              className="form-check-label text-nowrap col-2"
              htmlFor="flexRadioDefault1"
            >
              Terms of use<span className="text-danger">*</span>
            </label>
            <div className="w-100">
              <input
                className="form-control mx-2"
                name="teamname"
                placeholder="Enter Terms of use link"
                onChange={(e) => {
                  handlePreview(false);
                  if (e.target.value.length === 0) {
                    setError((pre) => ({ ...pre, terms: '' }));
                  } else if (constant.urlFormat.test(e.target.value.trim())) {
                    setTermsofuse(e.target.value);
                    setError((pre) => ({ ...pre, terms: '' }));
                  } else {
                    setError((pre) => ({
                      ...pre,
                      terms: 'Enter the valid url',
                    }));
                  }
                }}
              />
              {error.terms !== '' && (
                <div className="text-danger mx-3">{error.terms}</div>
              )}
            </div>
          </div>
          <div className="me-3 mb-1 d-flex align-items-center form-check">
            <label
              className="form-check-label text-nowrap col-2"
              htmlFor="flexRadioDefault1"
            >
              Privacy policy<span className="text-danger">*</span>
            </label>
            <div className="w-100">
              <input
                className="form-control mx-2"
                name="teamdl"
                placeholder="Enter privacy policy link"
                onChange={(e) => {
                  handlePreview(false);
                  if (e.target.value.length === 0) {
                    setError((pre) => ({ ...pre, privacy: '' }));
                  } else if (constant.urlFormat.test(e.target.value.trim())) {
                    setPrivacy(e.target.value);
                    setError((pre) => ({ ...pre, privacy: '' }));
                  } else {
                    setError((pre) => ({
                      ...pre,
                      privacy: 'Enter the valid url',
                    }));
                  }
                }}
              />
              {error.privacy !== '' && (
                <div className="text-danger mx-3">{error.privacy}</div>
              )}
            </div>
          </div>
          <div className="me-3 mb-1 d-flex align-items-center form-check">
            <label
              className="form-check-label text-nowrap col-2"
              htmlFor="flexRadioDefault1"
            >
              Amgen URL<span className="text-danger">*</span>
            </label>
            <div className="w-100">
              <input
                className="form-control mx-2"
                name="teamdl"
                placeholder="Enter amgen uri link"
                onChange={(e) => {
                  handlePreview(false);
                  if (e.target.value.length === 0) {
                    setError((pre) => ({ ...pre, url: '' }));
                  } else if (constant.urlFormat.test(e.target.value.trim())) {
                    setAmgenuri(e.target.value);
                    setError((pre) => ({ ...pre, url: '' }));
                  } else {
                    setError((pre) => ({
                      ...pre,
                      url: 'Enter the valid url',
                    }));
                  }
                }}
              />
              {error.url !== '' && (
                <div className="text-danger mx-3">{error.url}</div>
              )}
            </div>
          </div>
          {/* <div class="me-3 d-flex align-items-center form-check">
                <label class="form-check-label" htmlFor="flexRadioDefault1">
                Need help<span className="text-danger">*</span>
                </label>
                <input 
                class="form-control mx-2" 
                placeholder="Paste link"
                onChange={(e)=>{setNeedHelp(e.target.value)}}
                  ></input>
          </div> */}
          <div className="d-flex justify-content-end mt-2 mx-1">
            {' '}
            <button
              style={{ width: '5rem' }}
              className="ms-auto btn btn-primary"
              disabled={
                !teamname ||
                !teamdl ||
                !privacy ||
                !amgenuri ||
                !termsofuse ||
                error.terms ||
                error.privacy ||
                error.url ||
                error.dl
              }
              onClick={() => {
                handlePreview(true);
                changeValue();
              }}
            >
              Preview
            </button>
            <button
              className="btn btn-secondary ms-2"
              style={{ width: '5rem' }}
              disabled={
                !teamname ||
                !teamdl ||
                !privacy ||
                !amgenuri ||
                !termsofuse ||
                error.terms ||
                error.privacy ||
                error.url ||
                error.dl
              }
              onClick={() => {
                openTestpopup();
              }}
            >
              Test
            </button>
            {isButtonLoading ? (
              <button
                type="button"
                style={{ width: '5rem' }}
                className="btn btn-primary ms-2"
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            ) : (
              <button
                className="btn btn-primary ms-2"
                disabled={
                  !teamname ||
                  !teamdl ||
                  !privacy ||
                  !amgenuri ||
                  !termsofuse ||
                  error.terms ||
                  error.privacy ||
                  error.url ||
                  error.dl
                }
                style={{ width: '5rem' }}
                onClick={() => {
                  let data = '';

                  if (preval == 1) {
                    data = setSucValue();
                  } else if (preval == 2) {
                    data = setFailValue();
                  } else if (preval == 3) {
                    data = setInactiveValue();
                  } else if (preval == 4) {
                    data = setDeactiveValue();
                  }
                  setIsButtonLoading(true);
                  axios
                    .put(
                      `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
                      {
                        application_name: successMailContent.application_name,
                        data,
                        request_type: 'languageTemplate',
                        email: localStorage.getItem('username'),
                      },
                      {
                        headers: { ...constant.headers, 'csrf-token': csrf },
                      }
                    )
                    .then((response) => {
                      setMessage((prevState) => ({
                        ...prevState,
                        title: 'Template created successfully',
                        body: 'Template created successfully. Your template has been created correctly and is currently waiting for Admin approval.',
                        success: true,
                      }));
                      setIsButtonLoading(false);
                      openmessagepopup();
                    })
                    .catch((error) => {
                      console.log(sanitizeUserInput(JSON.stringify(error)));
                      setIsButtonLoading(false);
                    });
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      {preview && (
        <div className="row">
          <div className="d-flex flex-wrap text-align-center justify-content-center">
            {preval == 1 && (
              <div className="d-flex justify-content-center">
                <Preview
                  title={
                    successMailContent.content.hcp_validation_success_email_head
                  }
                  body={
                    successMailContent?.content
                      ?.hcp_validation_success_email_body
                  }
                  login={
                    successMailContent.content.hcp_validation_success_login_logo
                  }
                  banner={
                    successMailContent.content.hcp_validation_success_banner
                  }
                  tnc={
                    successMailContent.content.hcp_validation_success_email_tnc
                  }
                  tnc_link={
                    successMailContent.content
                      .hcp_validation_success_email_tnc_link
                  }
                  privacy={
                    successMailContent.content
                      .hcp_validation_success_email_privacy
                  }
                  privacy_link={
                    successMailContent.content
                      .hcp_validation_success_email_privacy_link
                  }
                  need_help_link={
                    successMailContent.content
                      .hcp_validation_success_email_need_help_link
                  }
                  footer_logo={
                    successMailContent.content
                      .hcp_validation_success_footer_logo
                  }
                  copy_right_year={
                    successMailContent.content
                      .hcp_validation_success_copy_right_year
                  }
                  amgen_uri={
                    successMailContent.content
                      .hcp_validation_success_email_amgen_uri
                  }
                  address1={
                    successMailContent.content
                      .hcp_validation_success_email_address_line1
                  }
                  address2={
                    successMailContent.content
                      .hcp_validation_success_email_address_line2
                  }
                  needhelp={
                    successMailContent.content
                      .hcp_validation_success_email_need_help
                  }
                  salutation={
                    successMailContent.content
                      .hcp_validation_success_email_salutation
                  }
                />
              </div>
            )}
            {preval == 2 && (
              <div className=" d-flex justify-content-center">
                <Preview
                  title={failureMailContent.content.hcp_validation_email_head}
                  body={failureMailContent?.content?.hcp_validation_email_body}
                  login={failureMailContent?.content?.hcp_validation_contact_us}
                  banner={
                    failureMailContent?.content?.hcp_validation_email_banner
                  }
                  tnc={failureMailContent.content.hcp_validation_email_tnc}
                  tnc_link={
                    failureMailContent.content.hcp_validation_email_tnc_link
                  }
                  privacy={
                    failureMailContent.content.hcp_validation_email_privacy
                  }
                  privacy_link={
                    failureMailContent.content.hcp_validation_email_privacy_link
                  }
                  need_help_link={
                    failureMailContent.content
                      .hcp_validation_email_need_help_link
                  }
                  footer_logo={
                    failureMailContent.content.hcp_validation_footer_logo
                  }
                  copy_right_year={
                    failureMailContent.content.hcp_validation_copy_right_year
                  }
                  amgen_uri={
                    failureMailContent.content.hcp_validation_email_amgen_uri
                  }
                  address1={
                    failureMailContent.content
                      .hcp_validation_email_address_line1
                  }
                  address2={
                    failureMailContent.content
                      .hcp_validation_email_address_line2
                  }
                  needhelp={failureMailContent.content.hcp_validation_need_help}
                  salutation={
                    failureMailContent.content.hcp_validation_salutation
                  }
                />
              </div>
            )}
            {preval == 3 && (
              <div className=" d-flex justify-content-center">
                <Preview
                  title={inactiveMailContent.content.Inactive_users_email_head}
                  body={inactiveMailContent?.content?.Inactive_users_email_body}
                  login={
                    inactiveMailContent.content.Inactive_users_email_login_logo
                  }
                  banner={
                    inactiveMailContent.content.Inactive_users_email_banner
                  }
                  tnc={inactiveMailContent.content.Inactive_users_email_tnc}
                  tnc_link={
                    inactiveMailContent.content.Inactive_users_email_tnc_link
                  }
                  privacy={
                    inactiveMailContent.content.Inactive_users_email_privacy
                  }
                  privacy_link={
                    inactiveMailContent.content
                      .Inactive_users_email_privacy_link
                  }
                  need_help_link={
                    inactiveMailContent.content
                      .Inactive_users_email_need_help_link
                  }
                  footer_logo={
                    inactiveMailContent.content.Inactive_users_footer_logo
                  }
                  copy_right_year={
                    inactiveMailContent.content.Inactive_users_copy_right_year
                  }
                  amgen_uri={
                    inactiveMailContent.content.Inactive_users_email_amgen_uri
                  }
                  address1={
                    inactiveMailContent.content
                      .Inactive_users_email_address_line1
                  }
                  address2={
                    inactiveMailContent.content
                      .Inactive_users_email_address_line2
                  }
                  needhelp={
                    inactiveMailContent.content.Inactive_users_email_need_help
                  }
                  salutation={
                    inactiveMailContent.content.Inactive_users_email_salutation
                  }
                />
              </div>
            )}
            {preval == 4 && (
              <div className=" d-flex justify-content-center">
                <Preview
                  title={
                    deactivatedMailContent.content.deactivated_users_email_head
                  }
                  body={
                    deactivatedMailContent?.content
                      ?.deactivated_users_email_body
                  }
                  login={
                    deactivatedMailContent?.content
                      ?.deactivated_users_email_login_logo
                  }
                  banner={
                    deactivatedMailContent?.content
                      ?.deactivated_users_email_banner
                  }
                  tnc={
                    deactivatedMailContent.content.deactivated_users_email_tnc
                  }
                  tnc_link={
                    deactivatedMailContent.content
                      .deactivated_users_email_tnc_link
                  }
                  privacy={
                    deactivatedMailContent.content
                      .deactivated_users_email_privacy
                  }
                  privacy_link={
                    deactivatedMailContent.content
                      .deactivated_users_email_privacy_link
                  }
                  need_help_link={
                    deactivatedMailContent.content
                      .deactivated_users_email_need_help_link
                  }
                  footer_logo={
                    deactivatedMailContent.content.deactivated_users_footer_logo
                  }
                  copy_right_year={
                    deactivatedMailContent.content
                      .deactivated_users_copy_right_year
                  }
                  amgen_uri={
                    deactivatedMailContent.content
                      .deactivated_users_email_amgen_uri
                  }
                  address1={
                    deactivatedMailContent?.content
                      ?.deactivated_users_email_address_line1
                  }
                  address2={
                    deactivatedMailContent?.content
                      ?.deactivated_users_email_address_line2
                  }
                  needhelp={
                    deactivatedMailContent?.content
                      ?.deactivated_users_email_need_help
                  }
                  salutation={
                    deactivatedMailContent?.content
                      ?.deactivated_users_email_salutation
                  }
                />
              </div>
            )}
          </div>
          {/* <div className="d-flex w-100 mt-4">
                <button 
                className="btn btn-secondary me-2" 
                style={{width:"15%"}}
                onClick={()=>{openTestpopup()}}>Test</button>
                {(isButtonLoading) ? <div className="w-100">
              <button  type="button" style={{width:"15%"}}
                className="btn btn-primary ms-2" >
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div> : <div className="w-100">
                <button 
                className="btn btn-primary ms-2" 
                style={{width:"15%"}}
                onClick={(()=>{


                  let data = "";

                  if (preval == 1) {
                    data = setSucValue()
                  }else if (preval == 2){
                    data = setFailValue()
                  }else if (preval == 3){
                    data = setInactiveValue()
                  }else if (preval == 4){
                    data = setDeactiveValue()
                  }
                  setIsButtonLoading(true)
                  axios
                  .put(
                    `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
                    {
                      application_name: successMailContent.application_name,
                      data: data,
                      request_type:"languageTemplate",
                      email: localStorage.getItem("username"),
                    },
                    {
                      headers: { ...constant.headers,"csrf-token": csrf },
                    }
                  )
                  .then((response) => {

                    setMessage(prevState => ({
                      ...prevState,
                      title: "Template created successfully",
                      body:"Template created successfully. Your template has been created correctly and is currently waiting for Admin approval.",
                      success:true
                  }));
                  setIsButtonLoading(false)
                  openmessagepopup();
                  })
                  .catch((error) => {
                    console.log(sanitizeUserInput(JSON.stringify(error)));
                    setIsButtonLoading(false)
                  });

                })}
                >Submit</button>
                </div>}
                </div> */}
        </div>
      )}
      <Testpopup
        modalIsOpen={modalIsOpen}
        closeModal={closeTestpopup}
        preval={preval}
      />
      <MessagePopup
        modalIsOpen={messageIsOpen}
        closeModal={closemessagepopup}
        title={message.title}
        body={message.body}
        success={message.success}
      />
    </main>
  );
}

export default Dynamicmailadmin;
