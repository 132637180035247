import React, { useState, useEffect } from 'react';

import './style.css';

export default function Collapse({
  title,
  isOpen,
  mandatory,
  setValue,
  children,
}) {
  const [toggle, setToggle] = useState(isOpen);
  useEffect(() => {
    setToggle(isOpen);
  }, [isOpen]);
  const onToggle = () =>
    setToggle((pre) => {
      if (setValue) {
        setValue(!pre);
      }
      return !pre;
    });
  return (
    <div className="collapse_container">
      <div
        className="d-flex justify-content-start align-items-center fs-6 user-select-none collapse_header"
        role="button"
        onClick={() => onToggle()}
      >
        <span className="fw-bold me-1">{toggle ? '△' : '▽'}</span>
        <div>{title}</div>
        {mandatory && <span className="ms-1 text-danger">*</span>}
      </div>
      <div className={toggle ? 'd-block' : 'd-none'}>{children}</div>
    </div>
  );
}

// sub collapse component

export function SubCollapse({ title, isOpen, info, children }) {
  const [toggle, setToggle] = useState(isOpen);
  useEffect(() => {
    setToggle(isOpen);
  }, [isOpen]);
  const onToggle = () => setToggle((pre) => !pre);
  return (
    <div className="w-100 m-2 py-3">
      <div
        className="d-flex justify-content-start align-items-center user-select-none collapse_header"
        role="button"
        onClick={() => onToggle()}
      >
        <span className="fw-bold me-1">{toggle ? '△' : '▽'}</span>
        <div className="d-flex justify-content-start align-items-center">
          <span>{title} </span>{' '}
          <span
            className="material-symbols-outlined fs-6 text-info"
            role="button"
            data-toggle="tooltip"
            title={info}
          >
            info
          </span>{' '}
        </div>
      </div>
      <div className={toggle ? 'd-block' : 'd-none'}>{children}</div>
    </div>
  );
}
