import React, {
  Fragment,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import  DOMPurify from 'dompurify';
import AmgenLogo from '../Assets/Group1.svg';
import Sidebar from '../sidebar/Sidebar';
import MyComponent from '../Table/Table';
import UserPopover from '../userpopover';
import * as constant from '../Constants/constant';
import '../style.css';
import Frtestpopup from '../Popup/Frtestpopup';
import MessagePopup from '../Popup/Messagepopup';
import { sanitizeUserInput } from '../../helper/sanitize';

const ForgerockMail = forwardRef((props, ref) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [updateloading, setUpdateLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [flag, setFlag] = useState(false);
  const [termsofuse, setTermsofuse] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [teamname, setTeamname] = useState('');
  const [teamname1, setTeamname1] = useState('Team Name');
  const { failureMailContent, successMailContent, success_application_list } =
    useSelector((state) => state.mailtemplate);
  const [teamdl, setTeamdl] = useState('');
  const [amgenuri, setAmgenuri] = useState('');
  const [teamdl1, setTeamdl1] = useState('Team DL');
  const [defaulttemplate, setDefaulttemplate] = useState(false);
  const [preview, setPreview] = useState(false);
  const dispatch = useDispatch();
  const [subject, setSubject] = useState('');
  const [data, setData] = useState('');
  const [defaultValue, setDefaultValue] = useState(false);
  const { allowedApp, role, initialLoader } = useSelector(
    (state) => state.dashboard
  );
  const { csrf } = useSelector((state) => state.app);
  axios.defaults.withCredentials = true;
  const [error, setError] = useState({
    terms: '',
    privacy: '',
    url: '',
    dl: '',
  });
  // const [payload,props.setPayload]=useState({
  //                                         app_name:"",
  //                                         locale:"",
  //                                         activity:""
  //                                     })
  const [message, setMessage] = useState({
    title: '',
    body: '',
    success: false,
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);

  function openTestpopup() {
    setIsOpen(true);
  }
  function closeTestpopup() {
    setIsOpen(false);
  }
  function openmessagepopup() {
    setMessageIsOpen(true);
  }
  function closemessagepopup() {
    setMessageIsOpen(false);
  }
  const [needhelp, setNeedHelp] = useState('{{object.needHelpURI}}');
  const [tnc, setTnc] = useState('TERMS_OF_USE_URI');
  const [prcy, setPrcy] = useState('PRIVACY_STATEMENT_URI');
  const [amg, setAmg] = useState('AMGEN_URI');
  function handlePreview(e) {
    let a = data.replaceAll(teamdl1, teamdl);
    a = a.replaceAll(teamname1, teamname);
    a = a.replaceAll(needhelp, teamdl);
    a = a.replaceAll(tnc, termsofuse);
    a = a.replaceAll(prcy, privacy);
    a = a.replaceAll(amg, amgenuri);
    setData(a);
    setTeamname1(teamname);
    setTeamdl1(teamdl);
    setNeedHelp(teamdl);
    setTnc(termsofuse);
    setPrcy(privacy);
    setAmg(amgenuri);
    setPreview(true);
  }

  function handleUpdate() {
    setDefaultValue(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/fr/get-email-template`, {
        headers: {
          app_name: 'dashboard',
          locale: successMailContent.language,
          activity: props.payload.activity,
          'csrf-token': csrf,
        },
      })
      .then((response) => {
        setData(response.data.data.body);
        setLoading(false);
        setSubject(response.data.data.subject);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });

    setPreview(false);
    setUpdate(true);
    setDefaulttemplate(true);
    setFlag(true);
  }

  useImperativeHandle(ref, () => ({
    handleActivityChange() {
      setTeamname1('Team Name');
      setTeamdl1('Team DL');
      setNeedHelp('{{object.needHelpURI}}');
      setTnc('TERMS_OF_USE_URI');
      setPrcy('PRIVACY_STATEMENT_URI');
      setAmg('AMGEN_URI');

      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/fr/get-email-template`,
          {
            headers: {
              app_name: successMailContent.application_name,
              'app-name': 'dashboard',
              locale: successMailContent.language,
              activity: props.payload.activity,
              'csrf-token': csrf,
            },
          }
        )
        .then((response) => {
          setSubject(response.data.data.subject);
          setData(response.data.data.body);
          setDefaulttemplate(response.data.data.default);
          if (response.data.data.default == true) {
            setUpdate(true);
            setPreview(false);
          } else {
            setUpdate(false);
            setPreview(true);
          }
          props.setLoading(false);
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)));
          props.setLoading(false);
        });
    },
  }));
  function handleTest() {
    openTestpopup();
  }

  function handleSubmit(r) {
    // if(role == "App Owner"){

    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
        {
          application_name: successMailContent.application_name,
          data: {
            default: defaultValue ? false : defaulttemplate,
            locale: successMailContent.language,
            activity: props.payload.activity,
            data: {
              body: data,
              subject,
              sender_address: 'no-reply@gblidm.amgen.com',
              sender_name: 'no-reply@gblidm.amgen.com',
            },
          },
          request_type: 'frEmailTemplate',
          email: localStorage.getItem('username'),
        },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setDefaultValue(false);
        setMessage((prevState) => ({
          ...prevState,
          title: 'Template created Successfully',
          body: 'Your Mail Template Created succesfully. Template Waiting for Approval',
          success: true,
        }));
        openmessagepopup();
        setUpdateLoading(false);
        setPreview(false);
        setUpdate(false);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  return (
    <div>
      <div className="mt-1">
        <div>
          {update && !loading && (
            <div className=" py-1 mt-2 mx-5 shadow-sm mb-4">
              <div className="d-flex align-items-center form-check mb-1">
                <label
                  className="form-check-label text-nowrap col-2"
                  htmlFor="flexRadioDefault1"
                >
                  Team Name<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control mx-2"
                  name="teamname"
                  placeholder="Enter Team Name"
                  onChange={(e) => {
                    setTeamname(e.target.value);
                  }}
                />
              </div>
              <div className="me-3 d-flex align-items-center form-check mb-1">
                <label
                  className="form-check-label text-nowrap col-2"
                  htmlFor="flexRadioDefault1"
                >
                  Team DL<span className="text-danger">*</span>
                </label>
                <div className="w-100">
                  <input
                    className="form-control mx-2"
                    name="teamdl"
                    placeholder="Enter Team DL"
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setError((pre) => ({ ...pre, dl: '' }));
                      } else if (
                        constant.emailRegex.test(e.target.value.trim())
                      ) {
                        setTeamdl(e.target.value.trim());
                        setError((pre) => ({ ...pre, dl: '' }));
                      } else {
                        setError((pre) => ({
                          ...pre,
                          dl: 'Enter the valid email',
                        }));
                      }
                    }}
                  />
                  {error.dl !== '' && (
                    <div className="text-danger mx-3">{error.dl}</div>
                  )}
                </div>
              </div>

              <div className="me-3 d-flex align-items-center form-check mb-1">
                <label
                  className="form-check-label text-nowrap col-2"
                  htmlFor="flexRadioDefault1"
                >
                  Terms of use<span className="text-danger">*</span>
                </label>
                <div className="w-100">
                  <input
                    className="form-control mx-2"
                    name="teamname"
                    placeholder="Enter Terms of use link"
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setError((pre) => ({ ...pre, terms: '' }));
                      } else if (
                        constant.urlFormat.test(e.target.value.trim())
                      ) {
                        setTermsofuse(e.target.value);
                        setError((pre) => ({ ...pre, terms: '' }));
                      } else {
                        setError((pre) => ({
                          ...pre,
                          terms: 'Enter the valid url',
                        }));
                      }
                    }}
                  />
                  {error.terms !== '' && (
                    <div className="text-danger mx-3">{error.terms}</div>
                  )}
                </div>
              </div>
              <div className="me-3 d-flex align-items-center form-check mb-1">
                <label
                  className="form-check-label text-nowrap col-2"
                  htmlFor="flexRadioDefault1"
                >
                  Privacy policy<span className="text-danger">*</span>
                </label>
                <div className="w-100">
                  <input
                    className="form-control mx-2"
                    name="teamdl"
                    placeholder="Enter privacy policy link"
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setError((pre) => ({ ...pre, privacy: '' }));
                      } else if (
                        constant.urlFormat.test(e.target.value.trim())
                      ) {
                        setPrivacy(e.target.value);
                        setError((pre) => ({ ...pre, privacy: '' }));
                      } else {
                        setError((pre) => ({
                          ...pre,
                          privacy: 'Enter the valid url',
                        }));
                      }
                    }}
                  />
                  {error.privacy !== '' && (
                    <div className="text-danger mx-3">{error.privacy}</div>
                  )}
                </div>
              </div>
              <div className="me-3 d-flex align-items-center form-check mb-1">
                <label
                  className="form-check-label text-nowrap col-2"
                  htmlFor="flexRadioDefault1"
                >
                  Amgen URL<span className="text-danger">*</span>
                </label>
                <div className="w-100">
                  <input
                    className="form-control mx-2"
                    name="teamdl"
                    placeholder="Enter amgen uri link"
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setError((pre) => ({ ...pre, url: '' }));
                      } else if (
                        constant.urlFormat.test(e.target.value.trim())
                      ) {
                        setAmgenuri(e.target.value);
                        setError((pre) => ({ ...pre, url: '' }));
                      } else {
                        setError((pre) => ({
                          ...pre,
                          url: 'Enter the valid url',
                        }));
                      }
                    }}
                  />
                  {error.url !== '' && (
                    <div className="text-danger mx-3">{error.url}</div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end my-2 me-4">
                <button
                  style={{ width: '5em' }}
                  className="btn btn-primary"
                  disabled={
                    !successMailContent.application_name ||
                    !successMailContent.language ||
                    !props.payload.activity ||
                    !teamname ||
                    !teamdl ||
                    !termsofuse ||
                    !privacy ||
                    !amgenuri ||
                    error.terms ||
                    error.privacy ||
                    error.url ||
                    error.dl
                  }
                  onClick={() => {
                    handlePreview();
                  }}
                >
                  Preview
                </button>
                {!defaulttemplate && (
                  <button
                    className="btn btn-secondary me-2"
                    style={{ width: '5em' }}
                    onClick={() => {
                      handleTest();
                    }}
                  >
                    Test
                  </button>
                )}
                {defaulttemplate && (
                  <button
                    className="btn btn-primary ms-2"
                    disabled={
                      !successMailContent.application_name ||
                      !successMailContent.language ||
                      !props.payload.activity ||
                      !teamname ||
                      !teamdl ||
                      !termsofuse ||
                      !privacy ||
                      !amgenuri ||
                      error.terms ||
                      error.privacy ||
                      error.url ||
                      error.dl
                    }
                    style={{ width: '5em' }}
                  >
                    {updateloading && (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {!updateloading && (
                      <div
                        onClick={(event) => {
                          setUpdateLoading(true);
                          handleSubmit();
                        }}
                      >
                        Submit
                      </div>
                    )}
                  </button>
                )}
                {!defaulttemplate && (
                  <button
                    className="btn btn-primary ms-2"
                    style={{ width: '5em' }}
                    onClick={(event) => {
                      handleUpdate();
                    }}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          )}

          {preview && (
            <>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }}
              />

              {/* { !defaulttemplate && <div className="ms-5 mt-3 mb-2">
                                        Do you want update this template ? 
                                        <button 
                                            className="btn btn-primary ms-2"
                                            style={{width:"15%"}}
                                            onClick={(event) => {
                                                handleUpdate()
                                                }}
                                            >Update
                                        </button>
                                        </div>} */}

              {/* <div className="my-4 ms-5">
                                    {!defaulttemplate &&<button 
                                    className="btn btn-secondary me-2" 
                                    style={{width:"15%"}}
                                    onClick={()=>{handleTest()}}>Test</button>
                                    }
                                    {defaulttemplate &&<button 
                                    className="btn btn-primary ms-2" 
                                    style={{width:"15%"}}
                                    >
                                            {updateloading && <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>}
                                            {!updateloading && <div
                                            onClick={(event) => {
                                            setUpdateLoading(true)
                                            handleSubmit();
                                            }}
                                            >Submit</div>}
                                    </button>}
                                </div> */}
            </>
          )}
        </div>
      </div>
      <Frtestpopup
        modalIsOpen={modalIsOpen}
        closeModal={closeTestpopup}
        payload={props.payload}
        subject={subject}
      />
      <MessagePopup
        modalIsOpen={messageIsOpen}
        closeModal={closemessagepopup}
        title={message.title}
        body={message.body}
        success={message.success}
      />
    </div>
  );
});

export default ForgerockMail;
