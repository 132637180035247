const Applicationdata = [
  {
    name: 'dashboard',
    app_access: 'ALL',
    fields: {
      changeemail: {
        redirectURL: '',
        steps: [
          {
            name: 'Change Email',
            fields: [
              {
                name: 'email',
                label: 'Email',
                placeholder: 'Email',
                type: 'text',
                validations: {
                  required: true,
                  type: 'email',
                },
                values: '',
              },
              {
                name: 'newEmail',
                label: 'Enter New Email',
                placeholder: 'Email',
                type: 'text',
                validations: {
                  required: true,
                  type: 'email',
                },
                values: '',
              },
            ],
            submitButtonLabel: 'Submit',
          },
        ],
        title: 'Change Email',
      },
      changepassword: {
        redirectURL: '',
        steps: [
          {
            name: 'Change Password',
            fields: [
              {
                name: 'password',
                label: 'New Password',
                passwordPolicy: {
                  message: 'Require three of the four character types below:',
                  rules: [
                    {
                      message: 'Uppercase characters',
                      pattern: '[A-Z]+',
                    },
                    {
                      message: 'Lowercase characters',
                      pattern: '[a-z]+',
                    },
                    {
                      message: 'Numeric (0-9)',
                      pattern: '\\d',
                    },
                    {
                      message: 'Special characters ?, %, *, $',
                      pattern: '[?%*$]',
                    },
                  ],
                },
                placeholder: 'Password',
                type: 'password',
                validations: {
                  min: 8,
                  required: true,
                },
                values: '',
              },
              {
                name: 'password1',
                label: 'Confirm Password',
                placeholder: 'Password',
                type: 'password',
                validations: {
                  min: 8,
                  required: true,
                },
                values: '',
              },
            ],
            submitButtonLabel: 'Submit',
          },
        ],
        title: 'Change Password',
      },
      changeprofile: {
        redirectURL: '',
        steps: [
          {
            name: 'Change Profile',
            fields: [
              {
                name: 'Profession',
                label: 'Profession',
                type: 'dropdown',
                validations: {
                  required: true,
                },
                values: [
                  {
                    key: '',
                    value: 'Select a title type',
                  },
                  {
                    key: '1',
                    value: 'Dr (Male)',
                  },
                  {
                    key: '2',
                    value: 'Dr (Female)',
                  },
                  {
                    key: '3',
                    value: 'Miss',
                  },
                  {
                    key: '4',
                    value: 'Mrs',
                  },
                  {
                    key: '5',
                    value: 'Mr',
                  },
                ],
              },
              {
                name: 'firstName',
                label: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                validations: {
                  max: '100',
                  min: '3',
                  required: true,
                  type: 'alphanumeric',
                },
                values: null,
              },
              {
                name: 'lastName',
                label: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                validations: {
                  max: '100',
                  min: '3',
                  required: true,
                  type: 'alphanumeric',
                },
                values: null,
              },
              {
                name: 'phone',
                label: 'Phone',
                placeholder: 'Phone Number',
                type: 'text',
                validations: {
                  max: 10,
                  min: 10,
                  required: true,
                  type: 'mobile',
                },
                values: null,
              },
            ],
            submitButtonLabel: 'Update',
          },
        ],
      },
      externalUrls: {
        login:
          'https://r1e2e-safetyportal.cs17.force.com/s/fr?language=fr&login=true',
        privacy: 'https://www.amgen.fr/politique-de-protection-des-donnees',
        tnc: 'https://r1val-safetyportal.cs17.force.com/s/fr/termsofuse/terms-of-use-france?language=fr',
      },
      fastaccess: {
        redirectURL: 'https://www.google.co.in/',
        steps: [
          {
            name: 'Fast Access',
            fields: [
              {
                name: 'email',
                label: 'Email or mobile',
                placeholder: 'Email or mobile',
                type: 'text',
                validations: {
                  max: 12,
                  min: 6,
                  required: true,
                },
                values: '',
              },
            ],
            submitButtonLabel: 'Next >',
          },
        ],
      },
      forgotpassword: {
        redirectURL:
          'https://amgenpassport-dev.amgen.com/agGMISpain/forgotpassword/changepassword?r=http://d354ti6jbz9ma5.cloudfront.net/redirect.html&l=en&response_mode=query',
        steps: [
          {
            name: 'Login',
            fields: [
              {
                name: 'email',
                label: 'Email',
                placeholder: 'Email',
                type: 'text',
                validations: {
                  max: 255,
                  min: 3,
                  required: true,
                  type: 'email',
                },
                values: '',
              },
            ],
            submitButtonLabel: 'Log In',
          },
        ],
        title: 'Forgot Password',
      },
      register: {
        redirectURL:
          'https://amgenpassport-dev.amgen.com/agGMISpain/register/activated?r=http://d354ti6jbz9ma5.cloudfront.net/redirect.html&response_mode=query',
        steps: [
          {
            name: 'Registration',
            fields: [
              {
                name: 'profession',
                label: 'Profession',
                type: 'dropdown',
                validations: {
                  required: true,
                },
                values: [
                  {
                    key: '',
                    value: 'Select a title type',
                  },
                  {
                    key: '1',
                    value: 'Dr (Male)',
                  },
                  {
                    key: '2',
                    value: 'Dr (Female)',
                  },
                  {
                    key: '3',
                    value: 'Miss',
                  },
                  {
                    key: '4',
                    value: 'Mrs',
                  },
                  {
                    key: '5',
                    value: 'Mr',
                  },
                ],
              },
              {
                name: 'firstName',
                label: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                validations: {
                  max: '100',
                  min: '3',
                  required: true,
                  type: 'alphanumeric',
                },
                values: null,
              },
              {
                name: 'lastName',
                label: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                validations: {
                  max: '100',
                  min: '3',
                  required: true,
                  type: 'alphanumeric',
                },
                values: null,
              },
              {
                name: 'email',
                label: 'EmailID',
                placeholder: 'Email',
                type: 'email',
                validations: {
                  required: true,
                  type: 'email',
                },
                values: null,
              },
              {
                name: 'phone',
                label: 'phone Number',
                placeholder: 'Phone Number',
                type: 'text',
                validations: {
                  max: 10,
                  min: 10,
                  required: false,
                  type: 'mobile',
                },
                values: null,
              },
              {
                name: 'speciality',
                label: 'Speciality',
                type: 'dropdown',
                validations: {
                  required: false,
                },
                values: [
                  {
                    key: '',
                    value: 'Select Speciality',
                  },
                  {
                    key: '1',
                    value: 'Administration',
                  },
                  {
                    key: '2',
                    value: 'Clinical Immunology & Allergy',
                  },
                  {
                    key: '3',
                    value: 'Hematology Oncology',
                  },
                  {
                    key: '4',
                    value: 'Anesthesiology',
                  },
                  {
                    key: '5',
                    value: 'Primary Care / Internal Med',
                  },
                  {
                    key: '6',
                    value: 'Anti-coagulation',
                  },
                  {
                    key: '7',
                    value: 'Biochemistry',
                  },
                  {
                    key: '8',
                    value: 'Cardiology',
                  },
                  {
                    key: '9',
                    value: 'Cardiac Surgery',
                  },
                  {
                    key: '10',
                    value: 'General Surgery',
                  },
                  {
                    key: '11',
                    value: 'Colorectal Surgery',
                  },
                  {
                    key: '12',
                    value: 'Oral & Maxillofacial Surgery',
                  },
                  {
                    key: '13',
                    value: 'Orthopedic Surgery',
                  },
                  {
                    key: '14',
                    value: 'Plastic Surgery',
                  },
                  {
                    key: '15',
                    value: 'Thoracic Surgery',
                  },
                  {
                    key: '16',
                    value: 'Vascular Surgery',
                  },
                  {
                    key: '17',
                    value: 'Pediatric Critical Care',
                  },
                  {
                    key: '18',
                    value: 'Pain Care',
                  },
                  {
                    key: '19',
                    value: 'Dermatology',
                  },
                  {
                    key: '20',
                    value: 'Endocrinology',
                  },
                  {
                    key: '21',
                    value: 'Infectious Disease',
                  },
                  {
                    key: '22',
                    value: 'Nurse Practitioner',
                  },
                  {
                    key: '23',
                    value: 'Epidemiology',
                  },
                  {
                    key: '24',
                    value: 'Unknown Specialty',
                  },
                  {
                    key: '25',
                    value: 'New for Spain',
                  },
                  {
                    key: '26',
                    value: 'Pharmacy',
                  },
                  {
                    key: '27',
                    value: 'Hospital Pharmacy',
                  },
                  {
                    key: '28',
                    value: 'Pharmacology',
                  },
                  {
                    key: '29',
                    value: 'Physiotherapist',
                  },
                  {
                    key: '30',
                    value: 'Gastroenterology',
                  },
                  {
                    key: '31',
                    value: 'Medical Genetics',
                  },
                  {
                    key: '32',
                    value: 'Geriatrics',
                  },
                  {
                    key: '33',
                    value: 'Hematology',
                  },
                  {
                    key: '34',
                    value: 'Immunology',
                  },
                  {
                    key: '35',
                    value: 'Recherche clinique',
                  },
                  {
                    key: '36',
                    value: 'Laboratory - Medical Analysis',
                  },
                  {
                    key: '37',
                    value: 'New for Spain',
                  },
                  {
                    key: '38',
                    value: 'Speech Pathologist',
                  },
                  {
                    key: '39',
                    value: 'Wholesaler',
                  },
                  {
                    key: '40',
                    value: 'Community Medicine/Public Health',
                  },
                  {
                    key: '41',
                    value: 'STD/HIV Medicine',
                  },
                  {
                    key: '42',
                    value: 'Emergency Medicine',
                  },
                  {
                    key: '43',
                    value: 'Pain Management',
                  },
                  {
                    key: '44',
                    value: 'Sleep Medicine',
                  },
                  {
                    key: '45',
                    value: 'Esthetics and Cosmetology',
                  },
                  {
                    key: '46',
                    value: 'General Practice',
                  },
                  {
                    key: '47',
                    value: 'Internal Medicine',
                  },
                  {
                    key: '48',
                    value: 'Occupational/Industrial Medicine',
                  },
                  {
                    key: '49',
                    value: 'Nutrition Medicine',
                  },
                  {
                    key: '50',
                    value: 'Tropical Medicine',
                  },
                  {
                    key: '51',
                    value: 'Microbiology/Bacteriology',
                  },
                  {
                    key: '52',
                    value: 'Nephrology',
                  },
                  {
                    key: '53',
                    value: 'Neonatal-Perinatal Medicine',
                  },
                  {
                    key: '54',
                    value: 'Pulmonology',
                  },
                  {
                    key: '55',
                    value: 'Neurology',
                  },
                  {
                    key: '56',
                    value: 'N/A',
                  },
                  {
                    key: '57',
                    value: 'Obstetrics & Gynaecology',
                  },
                  {
                    key: '58',
                    value: 'Ophthalmology',
                  },
                  {
                    key: '59',
                    value: 'Oncology',
                  },
                  {
                    key: '60',
                    value: 'Orthopedics',
                  },
                  {
                    key: '61',
                    value: 'Otolaryngology (ENT)',
                  },
                  {
                    key: '62',
                    value: 'Other',
                  },
                  {
                    key: '63',
                    value: 'Forensic Pathology',
                  },
                  {
                    key: '64',
                    value: 'Pediatrics',
                  },
                  {
                    key: '65',
                    value: 'Podiatrist',
                  },
                  {
                    key: '66',
                    value: 'Psychologist',
                  },
                  {
                    key: '67',
                    value: 'Pediatric Psychology',
                  },
                  {
                    key: '68',
                    value: 'Psychiatry',
                  },
                  {
                    key: '69',
                    value: 'Pediatric Psychiatry',
                  },
                  {
                    key: '70',
                    value: 'Radiology',
                  },
                  {
                    key: '71',
                    value: 'Hospital Pharmacy',
                  },
                  {
                    key: '72',
                    value: 'Rheumatology',
                  },
                  {
                    key: '73',
                    value: 'Social Worker',
                  },
                  {
                    key: '74',
                    value: 'Traumatology',
                  },
                  {
                    key: '75',
                    value: 'Urology',
                  },
                ],
              },
              {
                name: 'postalCode',
                label: 'Postal Code',
                placeholder: 'Postal Code',
                type: 'text',
                validations: {
                  max: '6',
                  min: '3',
                  required: true,
                  type: 'alphanumeric',
                },
                values: null,
              },
              {
                name: 'city',
                label: 'city',
                placeholder: 'city',
                type: 'text',
                validations: {
                  max: '50',
                  min: '3',
                  required: true,
                },
                values: null,
              },
              {
                name: 'institutionName',
                label: 'Institution Name',
                placeholder: 'Institution Name',
                type: 'text',
                validations: {
                  max: '50',
                  min: '3',
                  required: true,
                },
                values: null,
              },
              {
                name: 'password',
                label: 'Password',
                passwordPolicy: {
                  message: 'Require three of the four character types below:',
                  rules: [
                    {
                      message: 'Uppercase characters',
                      pattern: '[A-Z]+',
                    },
                    {
                      message: 'Lowercase characters',
                      pattern: '[a-z]+',
                    },
                    {
                      message: 'Numeric (0-9)',
                      pattern: '\\d',
                    },
                    {
                      message: 'Special characters ?, %, *, $',
                      pattern: '[?%*$]',
                    },
                  ],
                },
                placeholder: 'Password',
                type: 'password',
                validations: {
                  min: 8,
                  required: true,
                },
                values: null,
              },
              {
                name: 'confirmPassword',
                label: 'Confirm Password',
                passwordPolicy: {
                  message: 'Require three of the four character types below:',
                  rules: [
                    {
                      message: 'Uppercase characters',
                      pattern: '[A-Z]+',
                    },
                    {
                      message: 'Lowercase characters',
                      pattern: '[a-z]+',
                    },
                    {
                      message: 'Numeric (0-9)',
                      pattern: '\\d',
                    },
                    {
                      message: 'Special characters ?, %, *, $',
                      pattern: '[?%*$]',
                    },
                  ],
                },
                placeholder: 'Confirm Password',
                type: 'password',
                validations: {
                  min: 8,
                  required: true,
                },
                values: null,
              },
            ],
            submitButtonLabel: 'Next >',
          },
          {
            name: 'termsAndCondition',
            fields: [
              {
                name: 'termsAndCondition',
                label:
                  'I have read & accept the Terms of Use. I have also read the Privacy Collection Notice & consent to the collection & use of my personal information as detailed in the Privacy Collection Notice',
                placeholder:
                  'I have read & accept the Terms of Use. I have also read the Privacy Collection Notice & consent to the collection & use of my personal information as detailed in the Privacy Collection Notice',
                type: 'checkbox',
                validations: {
                  required: false,
                },
                values: '',
              },
              {
                name: 'consentOption',
                label:
                  'Optional - I agree to receive AMGEN communication by email on products and services',
                placeholder:
                  'Optional - I agree to receive AMGEN communication by email on products and services',
                type: 'checkbox',
                validations: {
                  required: false,
                },
                values: '',
              },
            ],
            submitButtonLabel: 'Next >',
          },
        ],
      },
      verifyotp: {
        redirectURL: '',
        steps: [
          {
            name: 'Confirm OTP',
            fields: [
              {
                name: 'otp',
                label: 'OTP',
                placeholder: 'OTP',
                type: 'text',
                validations: {
                  min: 6,
                  required: true,
                },
              },
            ],
            submitButtonLabel: 'Confirm OTP',
          },
        ],
        title: 'Confirm OTP',
      },
    },
    mapping_json: {
      name: 'name',
      city: 'city',
      confirmpassword: 'confirmPassword',
      consentoption: 'consentOption',
      country: 'CA',
      email: 'email',
      firstname: 'firstName',
      fullname: 'fullName',
      institutionname: 'institutionName',
      lastname: 'lastName',
      password: 'password',
      phone: 'phone',
      postalcode: 'postalCode',
      profession: 'profession',
      speciality: 'speciality',
      termsandcondition: 'termsAndCondition',
    },
    options: {
      hcpvalidation: false,
      mdmProfile: true,
      newLoginFlow: true,
      redirectMethod: 'get',
    },
    redirectURI: [
      'http://localhost:3000',
      'http://localhost:3001/',
      'http://localhost:3001',
      'https://amgenpassport-sbx.amgen.com',
    ],
    url: [
      'http://localhost:3001',
      'http://localhost:3001/',
      'https://amgenpassport-sbx.amgen.com',
    ],
  },
];

export default Applicationdata;
