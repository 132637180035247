import React, { useState } from 'react';
import Modal from 'react-modal';
import Collapse from '../Collapse/collapse';
import Countries from '../Data/Region';
import ApiComponentView from '../Myrequest/apicomponentView';
import { getLanguage } from '../../helper/helper';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '5px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxHeight: '60vh',
    background: 'white',
  },
};

export default function OnboardView({ modalIsOpen, closeModal, payload }) {
  const [active, setActive] = useState(0);
  const fucNext = () => setActive((pre) => (pre === 3 ? 3 : pre + 1));
  const fucPrevious = () => setActive((pre) => (pre === 0 ? 0 : pre - 1));
  const getCountry = (val) => {
    if (val === '') {
      return '';
    }
    const data = Countries.filter((d) => d.code === val);
    return data[0].label;
  };
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        data-keyboard="false"
      >
        <div className="h-100 text-dark">
          <span
            role="button"
            className="position-absolute top-0 end-0 material-symbols-outlined text-white me-3 mt-3"
            style={{
              background: '#AAAAAA',
              borderRadius: '15px',
            }}
            onClick={() => {
              setActive(0);
              closeModal();
            }}
          >
            close
          </span>
          <div className="p-2 h-100">
            <h3 className="fw-bold">Onboard view</h3>
            <div className="h-100 mt-3 overflow-auto p-2">
              {active === 0 && (
                <div
                  className="d-flex ps-4 py-4"
                  style={{
                    border: '1px  #045EB7',
                    boxShadow: '1px 1px 7px 0px #045EB7',
                  }}
                >
                  <div className="w-25">
                    <h5 className="fw-bold my-2">Restrictions</h5>
                    <div className="mb-2">Global UI</div>
                    <div className="mb-2">HCP validation</div>
                    <div className="mb-2">Allow other apps</div>
                    <div className="mb-2"> HCP Validation mail</div>
                    <div className="mb-2"> Sync email consent</div>
                    <div className="mb-2"> Update Profile</div>
                    <div className="mb-2"> Update email</div>
                    <div className="mb-2"> Update password</div>
                    <div className="mb-2"> Quick register</div>
                    <div className="mb-2"> Tokenized</div>
                    <div className="mb-2"> OTP Login</div>
                  </div>
                  <div className="">
                    <h5 className="fw-bold my-2">Mandatory</h5>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.options?.globalUI}
                        type="checkbox"
                        id="flexCheckglobalUI"
                        name="globalui"
                        disabled
                      />{' '}
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.options?.triggerHcpSuccessEmail}
                        type="checkbox"
                        id="flexCheckHcp"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.options?.allowOtherAppsToReg}
                        type="checkbox"
                        id="flexCheckAllowOther"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.options?.triggerHcpSuccessEmail}
                        type="checkbox"
                        id="flexCheckHcpMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.options?.syncEmailConsent}
                        type="checkbox"
                        id="flexCheckSyncMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={
                          !payload?.fields?.changeprofile?.steps[0]?.disabled
                        }
                        type="checkbox"
                        id="flexCheckSyncMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.fields?.changeprofile?.steps[2]?.flag}
                        type="checkbox"
                        id="flexCheckSyncMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.fields?.changeprofile?.steps[4]?.flag}
                        type="checkbox"
                        id="flexCheckSyncMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.fields?.register?.allowPrepopulate}
                        type="checkbox"
                        id="flexCheckSyncMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.fields?.login?.allowTokenized}
                        type="checkbox"
                        id="flexCheckSyncMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        checked={payload?.fields?.login?.allowOtp}
                        type="checkbox"
                        id="flexCheckSyncMail"
                        name="globalui"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}

              {active === 1 && (
                <div>
                  <Collapse title="Application Details">
                    <div className="mt-3" style={{ marginLeft: '2rem' }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: '#045EB7',
                          }}
                        >
                          Application name
                        </b>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the application name"
                          name="name"
                          disabled
                          value={payload?.name}
                          style={{ width: '35%', height: '2rem' }}
                        />
                      </div>
                    </div>
                    <div className="mt-3" style={{ marginLeft: '2rem' }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: '#045EB7',
                          }}
                        >
                          Application alias name
                        </b>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the alias name"
                          name="aliasName"
                          disabled
                          value={payload?.options?.aliasName || ''}
                          style={{ width: '35%', height: '2rem' }}
                        />
                      </div>
                    </div>
                    <div className="mt-3" style={{ marginLeft: '2rem' }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: '#045EB7',
                          }}
                        >
                          Owner name
                        </b>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the owner name"
                          name="appOwner"
                          disabled
                          value={payload?.options?.appOwner || ''}
                          style={{ width: '35%', height: '2rem' }}
                        />
                      </div>
                    </div>
                  </Collapse>
                  <Collapse title="Country">
                    <div
                      style={{
                        marginLeft: '2rem',
                        marginRight: '2rem',
                        display: 'flex',
                      }}
                    >
                      <div className="w-100">
                        <div className="mt-3">
                          <div className="d-flex w-100 my-1 pe-1">
                            <div className="d-flex w-100 px-2 py-1 border rounded-2 me-4">
                              <div className="ps-1 txt-overflow">
                                {payload.options.country}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse title="Language">
                    <div
                      style={{
                        marginLeft: '2rem',
                        marginRight: '2rem',
                        display: 'flex',
                      }}
                    >
                      <div className="w-100">
                        {/* <div className="d-flex align-items-center mb-2">
                          <b className="me-1" style={{
                                color: "#045EB7",
                              }}>Origin URL</b>
                        </div> */}

                        <div className="mt-1">
                          {payload?.options?.allowedLanguages?.map(
                            (data, index) => (
                              <div
                                key={index}
                                className="d-flex w-100 my-1 pe-1"
                              >
                                <div className="d-flex px-2 py-1 rounded-2 me-4">
                                  <div className="ps-1 txt-overflow">
                                    {data.label}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse title="Whitelist">
                    <div
                      style={{
                        marginLeft: '2rem',
                        marginRight: '2rem',
                        display: 'flex',
                      }}
                    >
                      <div className="w-100">
                        <div className="d-flex align-items-center mb-2">
                          <b
                            className="me-1"
                            style={{
                              color: '#045EB7',
                            }}
                          >
                            Origin URL
                          </b>
                        </div>

                        <div className="mt-1">
                          {payload.url.map((data, index) => (
                            <div key={index} className="d-flex w-100 my-1 pe-1">
                              <div className="d-flex w-100 px-2 py-1 border rounded-2 me-4">
                                <div className="ps-1 txt-overflow">{data}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="ms-3 w-100">
                        <div className="d-flex align-items-center mb-2">
                          <b
                            className="me-1"
                            style={{
                              color: '#045EB7',
                              // borderBottom: "1px solid #045EB7",
                            }}
                          >
                            Redirect URL
                          </b>
                        </div>
                        <div className="mt-1">
                          {payload.redirectURI.map((data, index) => (
                            <div key={index} className="d-flex w-100 my-1 pe-1">
                              <div className="d-flex w-100 px-2 py-1 border rounded-2 me-4">
                                <div className="ps-1 txt-overflow">{data}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}
              {active === 2 && (
                <div>
                  <Collapse title="Social Validation">
                    <div style={{ marginTop: '2rem', marginLeft: '2rem' }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1 user-select-none"
                          style={{
                            color: '#045EB7',
                            // borderBottom: "1px solid #045EB7",
                          }}
                        >
                          OneKey Connect
                        </b>
                      </div>
                      <div>
                        <div className="form-check">
                          {payload.fields.login.steps[1].social.owa.map(
                            (data, index) => (
                              <div>{data}</div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse title="All fields">
                    <div style={{ marginTop: '2rem', marginLeft: '2rem' }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1 user-select-none"
                          style={{
                            color: '#045EB7',
                            // borderBottom: "1px solid #045EB7",
                          }}
                        >
                          User Required Fields
                        </b>
                        <span
                          className="material-symbols-outlined fs-6 text-info"
                          role="button"
                          data-toggle="tooltip"
                          // title={info?.userField}
                        >
                          info
                        </span>
                      </div>
                      <ApiComponentView data={payload} />
                    </div>
                  </Collapse>
                  <Collapse title="Consent">
                    <div className="mt-3" style={{ marginLeft: '2rem' }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: '#045EB7',
                          }}
                        >
                          Terms and condition
                        </b>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the alias name"
                          name="aliasName"
                          disabled
                          value={
                            payload?.fields?.register?.steps[1].fields[0]
                              .label || ''
                          }
                          style={{ width: '92%', height: '2rem' }}
                        />
                      </div>
                    </div>
                    <div className="mt-3" style={{ marginLeft: '2rem' }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: '#045EB7',
                          }}
                        >
                          Consent
                        </b>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the owner name"
                          name="appOwner"
                          disabled
                          value={
                            payload?.fields?.register?.steps[1].fields[1]
                              .label || ''
                          }
                          style={{ width: '92%', height: '2rem' }}
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}
              {active === 3 && (
                <div>
                  <Collapse title="Mail Template">
                    <div style={{ marginTop: '2rem', marginLeft: '2rem' }}>
                      <div className=" align-items-center mb-2">
                        {payload?.mailTemplate &&
                          Object.keys(payload?.mailTemplate)?.map(
                            (d, index) => (
                              <div>
                                {d !== 'redirectURL' && (
                                  <div>
                                    <div className="text-primary bold mb-2">
                                      {getLanguage(d.split('-')[0])}
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-3">Amgen URL</div> :
                                      <div className="col-9">
                                        {payload?.mailTemplate[d]?.amgen_url}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-3">Privacy URL</div> :
                                      <div className="col-9">
                                        {payload?.mailTemplate[d]?.privacy_url}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-3">
                                        Terms of use URL
                                      </div>{' '}
                                      :
                                      <div className="col-9">
                                        {
                                          payload?.mailTemplate[d]
                                            ?.terms_of_use_url
                                        }
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-3">Team DL</div> :
                                      <div className="col-9">
                                        {payload?.mailTemplate[d]?.team_dl}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="col-3">Team Name</div> :
                                      <div className="col-9">
                                        {payload?.mailTemplate[d]?.team_name}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}

              <div className="d-flex justify-content-end mt-3">
                <button
                  className={
                    active === 0 ? 'onboardbtn2 opacity-50' : 'onboardbtn2'
                  }
                  style={{ width: '123px', height: '41px' }}
                  disabled={active === 0}
                  onClick={() => fucPrevious()}
                >
                  Previous
                </button>
                <button
                  className={
                    active === 3
                      ? 'onboardbtn2 opacity-50 ms-2'
                      : 'onboardbtn2 ms-2'
                  }
                  style={{ width: '123px', height: '41px' }}
                  disabled={active === 3}
                  onClick={() => fucNext()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
