import { downloadReport } from './pending';

const promotedcolumns = [
  {
    name: 'Users',
    cell: (row) => row.email,
    selector: 'email',
  },
  {
    name: 'Request type',
    cell: (row) => row.request_type,
    selector: 'request_type',
  },
  {
    name: 'Application Name',
    cell: (row) => row.application_name,
    selector: 'application_name',
  },
  {
    name: 'Status',
    cell: (row) => row.status,
    selector: 'status',
  },
  {
    name: 'Updated by',
    cell: (row) => row.processed_by,
    selector: 'processed_by',
  },
];

const approvedcolumns = [
  {
    name: 'Users',
    cell: (row) => row.email,
  },
  {
    name: 'Request type',
    cell: (row) => row.request_type,
  },
  {
    name: 'Application Name',
    cell: (row) => row.application_name,
  },
  {
    name: 'Actions',
    width: '100px',
    cell: (r) => {
      return (
        <div>
          {r.request_type !== 'preload' && <></>}
          {r.request_type === 'preload' && (
            <span
              style={{ fontSize: '18px' }}
              role="button"
              className="material-symbols-outlined user-select-none"
              onClick={() => {
                downloadReport(r);
              }}
            >
              download
            </span>
          )}
        </div>
      );
    },
  },
  {
    name: 'Status',
    cell: (row) => row.status,
  },
  {
    name: 'Updated by',
    cell: (row) => row.processed_by,
  },
  {
    name: 'Submitted date',
    cell: (row) => row.requested_date,
    sortable: true,
  },
  {
    name: 'Approved date',
    cell: (row) => row.processed_date,
    sortable: true,
  },
];

const rejectedcolumns = [
  {
    name: 'Application Name',
    cell: (row) => row.application_name,
    selector: 'application_name',
  },
  {
    name: 'Request type',
    cell: (row) => row.request_type,
    selector: 'request_type',
  },
  {
    name: 'Created by',
    cell: (row) => row.email,
    selector: 'email',
  },
  {
    name: 'Status',
    cell: (row) => row.status,
    selector: 'status',
  },
  {
    name: 'Updated by',
    cell: (row) => row.processed_by,
    selector: 'processed_by',
  },
  {
    name: 'Comments',
    cell: (row) => row.comments,
    selector: 'comments',
  },
  {
    name: 'Submitted date',
    cell: (row) => row.requested_date,
    sortable: true,
  },
  {
    name: 'Rejected date',
    cell: (row) => row.processed_date,
    sortable: true,
  },
];

export { rejectedcolumns, approvedcolumns, promotedcolumns };
