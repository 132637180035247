import React, { Fragment, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import AmgenLogo from '../Assets/Group1.svg';
import Sidebar from '../sidebar/Sidebar';
import MyComponent from '../Table/Table';
import Requestdata from '../Data/Requestdata';
import Viewpopup from '../Popup/Viewpopup';
import Editpopup from '../Popup/Editpopup';
import * as constant from '../Constants/constant';
import {
  updateLoader,
  setOnboardData,
  updateOnbaordKind,
  updateRequestId,
} from '../../store/slice';
import { setShow } from '../../store/appslice';
import '../style.css';
import UserPopover from '../userpopover';
import { sanitizeUserInput } from '../../helper/sanitize';

function Requesthome() {
  const [data, setData] = useState('');
  const [appname, setAppname] = useState('');
  const [r, setR] = useState('');
  const [alldata, setAlldata] = useState('');
  const user = localStorage.getItem('username');
  const [appList, setAppList] = useState([]);
  const { Loader } = useSelector((state) => state.onboard);
  const { csrf, show } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  axios.defaults.withCredentials = true;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`, {
        headers: { ...constant.headers },
      })

      .then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
            [{ key: 'email', value: user }],
            {
              headers: {
                ...constant.headers,
                'csrf-token': res.data.data.csrfToken,
              },
            }
          )
          .then((response) => {
            dispatch(updateLoader());
            setData(response.data.data);
            setAlldata(response.data.data);
            if (response.data && response.data.data) {
              const name = response.data.data;
              const appList = [];
              for (const i in name) {
                if (
                  !appList.includes(name[i].application_name) &&
                  name[i].status == 'approved'
                ) {
                  appList.push(name[i].application_name);
                }
              }

              setAppList(appList);
            }
          })
          .catch((error) => {
            console.log(sanitizeUserInput(JSON.stringify(error)));
          });
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
    return () => {
      dispatch(updateLoader());
    };
  }, []);

  // function handleOnboard(){
  //     const app = appname != ""? appname:"globalUiDefault";
  //     axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-app`,{application_name:"globalUiDefault"},
  //         {
  //             headers: { ...constant.headers,"csrf-token": csrf },
  //         }
  //         )
  //         .then((response) => {

  //             response.data.data.name="";
  //             dispatch(setOnboardData(response.data.data));
  //             navigate(`/myrequest/onboard`);
  //         })
  //         .catch(error => {console.log(error)})
  // }

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(e, r) {
    setIsOpen(true);
    setR(r);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  function openModal2(e, r) {
    setR(r);
    setIsOpen2(true);
  }
  function closeModal2() {
    dispatch(updateLoader());
    setIsOpen2(false);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
        [{ key: 'email', value: user }],
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setData(response.data.data);
        dispatch(updateLoader());
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  function handledelete(r) {
    dispatch(updateLoader());

    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/delete-user-request?request_id=${r.request_id}`,
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,
            [{ key: 'email', value: user }],
            {
              headers: { ...constant.headers, 'csrf-token': csrf },
            }
          )
          .then((response) => {
            setData(response.data.data);
            dispatch(updateLoader());
          })
          .catch((error) => {
            console.log(sanitizeUserInput(JSON.stringify(error)));
          });
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  const columns = [
    {
      name: 'Request ID',
      selector: (row) => row.request_id,
    },
    {
      name: 'Application Name',
      selector: (row) => row.application_name,
    },
    {
      name: 'Request created on',
      selector: (row) => row.requested_date,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
    },
    {
      name: 'Actions',
      selector: '',
      cell: (r) => {
        return (
          <div>
            {r.status == 'pending' ? (
              <div>
                <span
                  type="button"
                  className="material-symbols-outlined iconfontsize"
                  data-toggle="tooltip"
                  title="View"
                  onClick={(e) => {
                    openModal(e, r);
                  }}
                >
                  visibility
                </span>
                {/* <span type="button" class="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Edit" onClick={(e) => {openModal2(e,r)}}>edit</span>  */}
                {/* <span type="button" class="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Delete" onClick={() => 
                        {
                            handledelete(r)}
                        }>delete</span> */}
              </div>
            ) : (
              <div>
                <span
                  type="button"
                  className="material-symbols-outlined iconfontsize"
                  data-toggle="tooltip"
                  title="View"
                  onClick={(e) => {
                    openModal(e, r);
                  }}
                >
                  visibility
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: 'Comments',
      selector: (row) => row.comments,
    },
  ];

  return (
    <main className={show ? 'space-toggle' : null} style={{ margin: 0 }}>
      <aside
        style={{ background: '#045EB7' }}
        className={`sidebar ${show ? 'show' : null}`}
      >
        <nav className="nav">
          <div>
            <div className="header-toggle" onClick={() => dispatch(setShow())}>
              <span className="fas fa-bars sidebarfirst" />
            </div>
            <Sidebar show={show} />
          </div>
        </nav>
      </aside>
      <div className="maintop">
        <header
          style={{
            background: 'white',
            zIndex: '-1',
            height: '7vh',
            padding: 0,
          }}
          className={`header ${show ? 'space-toggle' : null}`}
        >
          <span>
            <img
              style={{ paddingLeft: '1rem', height: '40px' }}
              src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg"
              alt=""
            />
          </span>
          <div>
            {/* <span className="material-symbols-outlined headercolor">search</span>&nbsp;&nbsp;
            <span className="material-symbols-outlined headercolor">notifications</span>&nbsp;&nbsp; */}
            {/* <span className="material-symbols-outlined headercolor" type="button" data-toggle="tooltip" title={user}>account_circle</span> */}

            <span
              aria-describedby={id}
              role="button"
              className="material-symbols-outlined headercolor fs-1 me-3"
              onClick={handleClick}
            >
              account_circle
            </span>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <UserPopover />
            </Popover>
          </div>
        </header>
        <div className="ms-4 mt-2 fw-bold" style={{ color: '#0463c3' }}>
          My Request
        </div>
        <div className="bodyfirst">
          <div className="d-flex justify-content-end me-5" />
          <div className="myreqwidth p-5 me-3">
            <MyComponent
              tableHeight="90vh"
              columns={columns}
              data={data}
              export
              filter
              fileName="My request"
            />
            <Viewpopup
              modalIsOpen={modalIsOpen}
              r={r}
              closeModal={closeModal}
            />
            <Editpopup
              modalIsOpen2={modalIsOpen2}
              r={r}
              closeModal2={closeModal2}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Requesthome;
