import React, { Fragment, useEffect, useState } from 'react';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import AmgenLogo from '../Assets/Group1.svg';
import Sidebar from '../sidebar/Sidebar';
import MyComponent from '../Table/Table';
import * as constant from '../Constants/constant';
import { updateLoader } from '../../store/slice';
import MessagePopup from '../Popup/Messagepopup';
import {
  resetLanguage,
  resetCountry,
  setCountry,
  setLanguage,
} from '../../store/frmails';
import { sanitizeUserInput } from '../../helper/sanitize';
import FileUpload from '../Popup/fileUploadPopup';
import CountryOnboard from '../Popup/countryOnboardTrans';

function UpdateTran() {
  const { allowedApp, country, laguage, appValue } = useSelector(
    (state) => state.frmails
  );
  const { role, appList } = useSelector((state) => state.dashboard);
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  const { csrf } = useSelector((state) => state.app);
  const [message, setMessage] = useState({
    title: '',
    body: '',
    success: false,
  });
  const [appname, setAppname] = useState('');
  const [languageloc, setLanguageloc] = useState('');
  const [countryloc, setCountryloc] = useState('');
  const [go, setGo] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [value, setValue] = useState('');
  const { countryListNew } = useSelector((state) => state.dashboard);
  const [countrys, setCountry] = useState('');
  const [newAppList, setAppList] = useState([]);
  const [isAppLoader, setAppLoader] = useState(false);
  const [language, setLanguage] = useState([]);
  const [countryModal, setCountryModal] = useState(false);
  axios.defaults.withCredentials = true;

  useEffect(() => {
    if (countryListNew.length > 0) {
      setCountry(countryListNew[0].value);
    }
  }, []);
  useEffect(() => {
    setAppLoader((pre) => !pre);
    if (countrys) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
          {
            headers: { ...constant.headers },
          }
        )
        .then((res) => {
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/api/country-app-list`,
              { country: countrys },
              {
                headers: {
                  ...constant.headers,
                  'csrf-token': res.data.data.csrfToken,
                },
                withCredentials: true,
              }
            )
            .then((response) => {
              if (
                typeof response.data.data === 'object' &&
                Object.keys(response.data.data).length > 0
              ) {
                setAppList(response.data.data.apps);
                setLanguage(response.data.data.allowedLanguages);
              } else {
                setAppList([]);
                setLanguage([]);
              }
              setAppLoader((pre) => !pre);
            })
            .catch((er) => {
              console.log('errorr ', sanitizeUserInput(JSON.stringify(er)));
              setAppList([]);
              setAppLoader((pre) => !pre);
            });
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)), 'errorItem');
          setAppList([]);
          setAppLoader((pre) => !pre);
        });
    } else {
      setAppList([]);
      setAppLoader((pre) => !pre);
    }
  }, [countrys]);
  function validateURL(url, appname, languageloc, countryloc) {
    const allowedApps = [
      'AmgenISS',
      'BeyondstatinsAU',
      'agGMIUS',
      'agGMIFrance',
      'agGMISpain',
      'AmgenCompass',
      'demoTwo',
      'demoOne',
    ];
    const allowedLanguages = Object.values(constant.allowedLanguages);
    const allowedCountry = Object.values(constant.allowedCountry);
    if (
      constant.urlFormat.test(url) &&
      allowedApps.includes(appname) &&
      allowedLanguages.includes(languageloc) &&
      allowedCountry.includes(countryloc)
    ) {
      return true;
    }
    return false;
  }

  function getLocale() {
    const trans_url = `${process.env.REACT_APP_API_BASE_URL}/api/locales/${window.encodeURIComponent(appname)}/${window.encodeURIComponent(languageloc)}-${window.encodeURIComponent(countrys)}`;
    axios
      .get(trans_url, {
        headers: { ...constant.headers, 'csrf-token': csrf },
      })
      .then((response) => {
        setGo(false);
        setValue(response.data);
      })
      .catch((err) => console.log(sanitizeUserInput(JSON.stringify(err))));
  }

  function openmessagepopup() {
    setMessageIsOpen(true);
  }
  function closemessagepopup() {
    setMessageIsOpen(false);
  }
  function openUploadFile() {
    setFileUpload(true);
  }
  function openCountryModal() {
    setCountryModal(true);
  }
  function colseUploadFile() {
    setFileUpload(false);
  }
  function colseCountryModal() {
    setCountryModal(false);
  }

  function updateValue(name, val) {
    setValue({
      ...value,
      [name]: val,
    });
  }

  useEffect(() => {
    setValue('');
  }, [appname, languageloc, countrys]);

  function handleSubmit() {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
        {
          application_name: appname,
          request_type: 'updateTranslation',
          email: localStorage.getItem('username'),
          data: {
            language: languageloc,
            country: countryloc,
            data: value,
          },
        },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setMessage((prevState) => ({
          ...prevState,
          title: 'Language Translation Updated Successfully',
          body: 'Language translation updated succesfully. Template Waiting for Approval',
          success: true,
        }));

        openmessagepopup();
        setValue('');
        setAppname('');
        setLanguageloc('');
        // setCountry("");
        setSubmit(false);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  const [show, setShow] = useState(false);
  const user = localStorage.getItem('username');
  const dispatch = useDispatch();

  return (
    <main className="mt-0 px-5">
      <div className="d-flex py-1 shadow-sm mb-4 justify-content-between">
        <div className="d-flex">
          <div className="me-3 d-flex align-items-center form-check">
            <label
              className="form-check-label me-1"
              htmlFor="flexRadioDefault1"
            >
              Country<sup className="text-danger">* </sup>
            </label>
            <select
              name="country"
              value={countrys}
              onChange={(e) => {
                setCountry(e.target.value);
                setAppname('');
                setLanguageloc('');
              }}
              // className="form-select"
              aria-label="Default select example"
              style={{
                border: '1px solid #ced4da',
                width: '150px',
                borderRadius: '5px',
                height: '2rem',
              }}
            >
              <option value="">Select Country</option>
              {countryListNew.map((x, index) => (
                <option key={index} value={x.value}>
                  {x.label}
                </option>
              ))}
            </select>
          </div>
          <div className="me-3 d-flex align-items-center form-check">
            <label
              className="form-check-label me-1"
              htmlFor="flexRadioDefault1"
            >
              Application<sup className="text-danger">* </sup>
            </label>
            <select
              name="application"
              value={appname}
              onChange={(e) => {
                setAppname(e.target.value);
                if (e.target.value !== '') {
                  setLanguageloc('');
                } else {
                  dispatch(resetLanguage());
                }
              }}
              aria-label="Default select example"
              style={{
                border: '1px solid #ced4da',
                width: '150px',
                borderRadius: '5px',
                height: '2rem',
              }}
            >
              {!isAppLoader && <option value="">Select Application</option>}
              {isAppLoader && <option value="">Loading...</option>}
              {newAppList.map((data, index) => (
                <option key={index} value={data.application_name}>
                  {data?.aliasName}
                </option>
              ))}
            </select>
          </div>
          <div className="me-3 d-flex align-items-center form-check">
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Language<sup className="text-danger">*</sup>
            </label>
            <select
              className="form-select mx-2"
              aria-label="Default select example"
              value={languageloc}
              onChange={(event) => {
                setLanguageloc(event.target.value);
              }}
            >
              {!isAppLoader && <option value="">Select Application</option>}
              {isAppLoader && <option value="">Loading...</option>}
              {language.map((data, index) => (
                <option key={index} value={data.value}>
                  {data.label}
                </option>
              ))}
              {/* {allowLan?.map ((option) => {
                        return <option value={option.value}>{option["label"]}</option>
                        })} */}
            </select>
          </div>
        </div>
        <div className="d-flex me-2">
          {!go ? (
            <button
              className="btn btn-primary"
              style={{ width: '5rem' }}
              disabled={!(appname && languageloc && countrys)}
              onClick={() => {
                getLocale();
                setGo(true);
              }}
            >
              Go
            </button>
          ) : (
            <button className="btn btn-primary" style={{ width: '5rem' }}>
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </button>
          )}
          {(role.toLowerCase() === 'super admin' ||
            role.toLowerCase() === 'admin') && (
            <div
              className="ms-1 d-flex align-items-center btn btn-primary"
              onClick={openCountryModal}
            >
              <span
                className="material-symbols-outlined text-white"
                role="button"
              >
                upload_file
              </span>
            </div>
          )}
        </div>
      </div>

      {appname &&
        languageloc &&
        countryloc &&
        Object.entries(value).length > 0 && (
          <div className="my-2">
            <div className="d-flex justify-content-end align-items-center p-2 user-select-none">
              <div role="button" onClick={openUploadFile}>
                upload file
              </div>
              <div
                className="material-symbols-outlined text-primary"
                role="button"
                onClick={openUploadFile}
              >
                upload_file
              </div>
            </div>
          </div>
        )}
      <div>
        {Object.entries(value).map(([key, data], index) => {
          return (
            !key.includes('_') && (
              <div className="row mb-2" key={index}>
                <div className="col-5">{key}</div>
                <div className="col-7 d-flex align-items-center">
                  <input
                    className="form-control"
                    placeholder={data}
                    onChange={(e) => {
                      updateValue(key, e.target.value);
                    }}
                  />
                </div>
              </div>
            )
          );
        })}
      </div>

      {value && !submit && (
        <button
          className="btn btn-primary mb-4 mt-5"
          style={{ width: '10rem' }}
          onClick={() => {
            handleSubmit();
            setSubmit(true);
          }}
        >
          Submit
        </button>
      )}
      {submit && (
        <button
          className="btn btn-primary mb-4 mt-5"
          style={{ width: '10rem' }}
        >
          <div
            className="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </button>
      )}

      <MessagePopup
        modalIsOpen={messageIsOpen}
        closeModal={closemessagepopup}
        title={message.title}
        body={message.body}
        success={message.success}
      />
      <FileUpload
        modalIsOpen={fileUpload}
        closeModal={colseUploadFile}
        metaData={{
          appname,
          languageloc,
          countryloc,
        }}
        setMessage={setMessage}
        openmessagepopup={openmessagepopup}
      />
      <CountryOnboard
        modalIsOpen={countryModal}
        closeModal={colseCountryModal}
        setMessage={setMessage}
        openmessagepopup={openmessagepopup}
      />
    </main>
  );
}

export default UpdateTran;
