import React, { useState } from 'react';
import './signinpage.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import * as constant from '../Constants/constant';
import { sanitizeUserInput } from '../../helper/sanitize';
// import Eye from "./eye.png";

function Signinpage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  axios.defaults.withCredentials = true;
  const { csrf } = useSelector((state) => state.app);

  localStorage.setItem('username', username);
  localStorage.setItem('password', password);

  function handleOnclick() {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
        { email: username },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        localStorage.setItem('role', response.data.data.role);

        if (
          response.data.data.role &&
          response.data.data.role === 'App Owner'
        ) {
          navigate('/applicationowner');
        } else if (
          response.data.data.role &&
          response.data.data.role === 'Admin'
        ) {
          navigate('/applicationadmin');
        } else if (
          response.data.data.role &&
          response.data.data.role === 'Super Admin'
        ) {
          navigate('/applicationadmin');
        } else {
          navigate('/notfound');
        }
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
        navigate('/notfound');
      });
  }

  function myFunction() {
    const x = document.getElementById('myInput');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }

  return (
    <div className="fullcontainer">
      <div className="middle-container1">
        <div className="top-heading">
          <h2 className="okta-text">okta</h2>
        </div>
        <hr />
        <div className="sign-in-line"> Sign In </div>
        <div className="text-boxes">
          <div className="input-boxes">
            <label className="label">
              <p className="paragraph"> User Name </p>
            </label>
            <div>
              <input
                type="text"
                className="select-option"
                id="firstName"
                name="firstName"
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
          </div>
          <div className="input-boxes">
            <label className="label">
              <p className="paragraph"> Password </p>
            </label>
            <div className="inputbox-and-eye">
              <input
                type="password"
                className="select-option"
                id="myInput"
                name="password"
                onChange={(event) => setPassword(event.target.value)}
              />{' '}
              &nbsp;
              <span className="material-symbols-outlined" onClick={myFunction}>
                visibility
              </span>
            </div>
          </div>
        </div>
        <div className="checkbox">
          <input
            type="checkbox"
            className="terms-checkbox"
            id="Remember"
            name="Remember"
            placeholder="Remember me"
          />
          <label className="label">Remember me</label>
        </div>
        <div className="button">
          <button
            onClick={
              handleOnclick
              // ()=>navigate("/application")
            }
            className="signin-with-line"
          >
            Sign In
            <div className="line-and-arrow">
              <span className="vertical-line"> | </span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="arrow-right"
                className="arrow"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Signinpage;
