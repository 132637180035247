import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import { NavLink, useNavigate } from 'react-router-dom';
import AmgenLogo from '../Assets/Group1.svg';
import MyComponent from '../Table/Table';
import './index.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import MailTestPopup from '../Popup/Mailtestpopup';
import * as constant from '../Constants/constant';
import {
  updateLoader,
  completeButtonDisable,
  setAdminApplication,
  setOnboardData,
  updateOnboardKind,
} from '../../store/slice';
import { setShow } from '../../store/appslice';
import '../style.css';
import UserPopover from '../userpopover';
import MessagePopup from '../Popup/Messagepopup';
import { sanitizeUserInput } from '../../helper/sanitize';
import OnboardView from '../Popup/onboardview';
import Loader from '../loader/loader';

function Applicationhome() {
  const [loading, setLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [data, setData] = useState('');
  const [adLoader, setADLoader] = useState({});
  const [mailData, setMailData] = useState({ title: '', appName: '', uri: '' });
  const { kind, request_id, adminApplications } = useSelector(
    (state) => state.onboard
  );
  const { csrf, show } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [onboardView, setOnboard] = useState(false);
  const [onboardPayload, setOnboardPayload] = useState({});
  const [applist1, setApplist1] = useState('');
  const [applist, setApplist] = useState([]);
  const [langList, setLangList] = useState([]);
  const [allowLan, setAllowLan] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  axios.defaults.withCredentials = true;
  const languageOptions = [
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Italian',
      value: 'it',
    },
    {
      label: 'French',
      value: 'fr',
    },
    {
      label: 'Spanish',
      value: 'es',
    },
    {
      label: 'German',
      value: 'de',
    },
  ];
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  const [message, setMessage] = useState({
    title: '',
    body: '',
    success: false,
  });

  function handleOnboard() {
    dispatch(completeButtonDisable(false));
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-app`,
        { application_name: 'globalUiDefault' },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        response.data.data.name = '';
        dispatch(setOnboardData(response.data.data));
        navigate(`/myrequest/onboard`);
        setLoading(false);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  function handleOnboardModal() {
    setOnboard((pre) => !pre);
  }

  function openmessagepopup() {
    setMessageIsOpen(true);
  }
  function closemessagepopup() {
    setMessageIsOpen(false);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // let role = localStorage.getItem("role");
  const { role } = useSelector((state) => state.dashboard);
  const user = localStorage.getItem('username');

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`, {
        headers: { ...constant.headers },
      })

      .then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
            { email: user },
            {
              headers: {
                ...constant.headers,
                'csrf-token': res.data.data.csrfToken,
              },
            }
          )
          .then((response) => {
            const adminApp = response.data.data.data.map(
              (data) => data.application_name
            );
            dispatch(setAdminApplication(adminApp));

            // setData(response.data.data.data);

            if (role == 'Super Admin') {
              axios
                .get(
                  `${process.env.REACT_APP_API_BASE_URL}/api/get-all-app-list`,
                  {
                    headers: {
                      ...constant.headers,
                      'csrf-token': res.data.data.csrfToken,
                    },
                  }
                )
                .then((response) => {
                  setData(response.data.data);

                  dispatch(updateLoader());
                })
                .catch((error) => {
                  console.log(sanitizeUserInput(JSON.stringify(error)));
                });
            } else {
              axios
                .get(
                  `${process.env.REACT_APP_API_BASE_URL}/api/get-all-app-list`,
                  {
                    headers: {
                      ...constant.headers,
                      'csrf-token': res.data.data.csrfToken,
                    },
                  }
                )
                .then((response) => {
                  setApplist(response.data.data);
                  const data1 = response.data.data;
                  setData(
                    data1.filter((data) => {
                      return adminApp.includes(data.application_name);
                    })
                  );
                  dispatch(updateLoader());
                })
                .catch((error) => {
                  console.log(sanitizeUserInput(JSON.stringify(error)));
                });
            }
          })
          .catch((error) => {
            console.log(sanitizeUserInput(JSON.stringify(error)));
          });
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
    return () => {
      dispatch(updateLoader());
    };
  }, []);

  // function handleEdit(e, r) {
  //   dispatch(completeButtonDisable(true));
  //   dispatch(updateOnboardKind("adminOnboard"));

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_BASE_URL}/api/get-app`,
  //       { application_name: r.application_name },
  //       {
  //         headers: { ...constant.headers,"csrf-token": csrf },
  //       }
  //     )
  //     .then((response) => {
  //       dispatch(setOnboardData(response.data.data));
  //       navigate(`/myrequest/onboard`);
  //     })
  //     .catch((error) => {
  //       console.log(sanitizeUserInput(JSON.stringify(error)));
  //     });
  // }
  function handleView(e, r) {
    // dispatch(completeButtonDisable(true));

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-app`,
        { application_name: r.application_name },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setOnboardPayload(response.data.data);
        setOnboard(true);
        setViewLoading(false);
        // dispatch(setOnboardData(response.data.data));
        // navigate(`/myrequest/onboard`);
      })
      .catch((error) => {
        setViewLoading(false);
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  function handleStatus(status, r) {
    setADLoader({ ...adLoader, [r]: true });
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
        {
          application_name: r,
          request_type: status == 'Active' ? 'activation' : 'deactivation',
          email: localStorage.getItem('username'),
        },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setMessage((prevState) => ({
          ...prevState,
          title: 'Request',
          body: 'Request submitted successfully.',
          success: true,
        }));
        setADLoader({ ...adLoader, [r]: false });
        openmessagepopup();
      })
      .catch((error) => {
        setADLoader({ ...adLoader, [r]: false });
        console.log(sanitizeUserInput(JSON.stringify(error)));
        setADLoader({ ...adLoader, [r]: false });
      });
  }

  const columns = [
    {
      name: 'Application Name',
      selector: (row) => row.options.aliasName,
      cell: (row) => row.options.aliasName,
    },
    {
      name: 'Application Owner',
      selector: (row) => row.options.appOwner,
      cell: (row) => row.options.appOwner,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
    },
    {
      name: 'View',
      cell: (r) => {
        return (
          <div>
            {/* <span
              role="button"
              className="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Edit"
              onClick={(e) => {
                handleEdit(e, r);
              }}
            >
              edit
            </span> */}

            <span
              role="button"
              className="material-symbols-outlined iconfontsize"
              data-toggle="tooltip"
              title="View"
              onClick={(e) => {
                setViewLoading(true);
                handleView(e, r);
              }}
            >
              visibility
            </span>
            {/* {r?.options?.globalUI === true && (
              <span
                role="button"
                style={{ color: "#045EB7" }}
                className="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Test template"
                onClick={(e) => {
                  setMailData({
                    ...mailData,
                    title: "",
                    appName: r.application_name,
                    country: r.options?.country || "",
                    uri: "success-mail-invoke",
                    language: r.options?.language || ""
                  });
                  setAllowLan(r.options?.allowedLanguages || languageOptions)
                  setLangList(r.options?.mailBlastCountries)

                  openModal(e, r);
                }}
              >
                play_circle
              </span>
            )} */}
            {/* {r?.options?.triggerHcpFailureEmail === true && (
              <span
                role="button"
                className="material-symbols-outlined text-danger iconfontsize" data-toggle="tooltip" title="Send failure template"
                onClick={(e) => {
                  setMailData({
                    ...mailData,
                    title: "Failure ",
                    country: r.options?.country || "",
                    appName: r.application_name,
                    uri: "rejection-mail-invoke",
                    language: r.options?.language || ""
                  });
                  setAllowLan(r.options?.allowedLanguages || languageOptions)
                  openModal(e, r);
                }}
              >
                play_circle
              </span>
            )} */}
          </div>
        );
      },
    },
    {
      name: 'Test blast',
      cell: (r) => {
        return (
          <div>
            {/* <span
              role="button"
              className="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Edit"
              onClick={(e) => {
                handleEdit(e, r);
              }}
            >
              edit
            </span> */}

            {/* <span role="button"
              className="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="View"
              onClick={(e) => {
                handleView(e, r);
              }}
            >visibility</span> */}
            {r?.options?.globalUI === true && (
              <span
                role="button"
                style={{ color: '#045EB7' }}
                className="material-symbols-outlined iconfontsize"
                data-toggle="tooltip"
                title="Test template"
                onClick={(e) => {
                  setMailData({
                    ...mailData,
                    title: '',
                    appName: r.application_name,
                    country: r.options?.country || '',
                    uri: 'success-mail-invoke',
                    language: r.options?.language || '',
                  });
                  setAllowLan(r.options?.allowedLanguages || languageOptions);
                  setLangList(r.options?.mailBlastCountries);

                  openModal(e, r);
                }}
              >
                play_circle
              </span>
            )}
            {/* {r?.options?.triggerHcpFailureEmail === true && (
              <span
                role="button"
                className="material-symbols-outlined text-danger iconfontsize" data-toggle="tooltip" title="Send failure template"
                onClick={(e) => {
                  setMailData({
                    ...mailData,
                    title: "Failure ",
                    country: r.options?.country || "",
                    appName: r.application_name,
                    uri: "rejection-mail-invoke",
                    language: r.options?.language || ""
                  });
                  setAllowLan(r.options?.allowedLanguages || languageOptions)
                  openModal(e, r);
                }}
              >
                play_circle
              </span>
            )} */}
          </div>
        );
      },
    },
    {
      name: 'Actions',
      cell: (r) => {
        return (
          <div>
            {adLoader[r.application_name] || false ? (
              <div>
                <button type="button" className="tablebtn">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              </div>
            ) : (
              <div>
                {r.status == 'Active' ? (
                  <button
                    type="button"
                    className="tablebtn"
                    onClick={() => {
                      handleStatus(
                        'deactive',
                        r.application_name,
                        'Deactivate'
                      );
                    }}
                  >
                    Deactivate
                  </button>
                ) : (
                  <button
                    type="button"
                    className="tablebtn"
                    onClick={() => {
                      handleStatus('Active', r.application_name, 'Activate');
                    }}
                  >
                    Activate
                  </button>
                )}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <main className={show ? 'space-toggle' : null} style={{ margin: 0 }}>
      <aside
        style={{ background: '#045EB7' }}
        className={`sidebar ${show ? 'show' : null}`}
      >
        <nav className="nav">
          <div>
            <div className="header-toggle" onClick={() => dispatch(setShow())}>
              <span className="fas fa-bars sidebarfirst" />
            </div>
            <Sidebar show={show} />
          </div>
        </nav>
      </aside>
      <div className="maintop">
        <header
          style={{
            background: 'white',
            zIndex: '-1',
            height: '7vh',
            padding: 0,
          }}
          className={`header ${show ? 'space-toggle' : null}`}
        >
          <span>
            <img
              style={{ paddingLeft: '1rem', height: '40px' }}
              src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg"
              alt=""
            />
          </span>
          <div>
            {/* <span className="material-symbols-outlined headercolor">search</span>&nbsp;&nbsp;
            <span className="material-symbols-outlined headercolor">notifications</span>&nbsp;&nbsp; */}
            {/* <span className="material-symbols-outlined headercolor" type="button" data-toggle="tooltip" title={user}>account_circle</span> */}

            <span
              aria-describedby={id}
              role="button"
              className="material-symbols-outlined headercolor fs-1 me-3"
              onClick={handleClick}
            >
              account_circle
            </span>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <UserPopover />
            </Popover>
          </div>
        </header>
        <button className="mainheading">
          <span>Applications</span>
        </button>
        <div className="bodyfirst">
          <div className="me-5" style={{ padding: ' 2rem 0rem 0rem 2rem' }}>
            <div className="d-flex justify-content-end">
              {loading ? (
                <button
                  className="btn btn-primary mb-2"
                  style={{ width: '11rem' }}
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <button
                  className="btn btn-primary mb-2 d-flex justify-content-end"
                  style={{ width: '11rem' }}
                  onClick={() => {
                    handleOnboard();
                  }}
                >
                  Onboard Application
                </button>
              )}
            </div>
            <div className="tablebo">
              <MyComponent
                header={false}
                columns={columns}
                data={data}
                fileName="Application List"
                tableHeight="70vh"
              />
            </div>
            <MailTestPopup
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              maildata={mailData}
              setMessage={setMessage}
              openmessagepopup={openmessagepopup}
              allowLan={allowLan}
              langList={langList}
            />
            {/* MessagePopup */}
            <MessagePopup
              modalIsOpen={messageIsOpen}
              closeModal={closemessagepopup}
              title={message.title}
              body={message.body}
              success={message.success}
            />
            <OnboardView
              modalIsOpen={onboardView}
              payload={onboardPayload}
              closeModal={handleOnboardModal}
            />
            {viewLoading && <Loader />}
            {/* <Viewpopup data ={data} modalIsOpen={modalIsOpen} closeModal={closeModal}></Viewpopup>
                        <Editpopup data ={data} modalIsOpen2={modalIsOpen2} closeModal2={closeModal2}></Editpopup> */}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Applicationhome;
