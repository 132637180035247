import React, { useState } from 'react';
import '../style.css';
import 'chart.js/auto';
import axios from 'axios';
import { PieChart } from '@mui/x-charts/PieChart';
import { ResponsiveChartContainer } from '@mui/x-charts';
import Box from '@mui/material/Box';
// import { Logindata } from "./login_data";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../helper/helper';
import * as constant from '../Constants/constant';
import { sanitizeUserInput } from '../../helper/sanitize';
import MyComponent from '../Table/Table';

function Login(props) {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [appname, setAppname] = useState('');
  const role = localStorage.getItem('role');
  const [loginlabel, setLoginLabel] = useState([]);
  const [value, setValue] = useState([]);
  const [pieValue, setPieValue] = useState([{}]);
  const { csrf } = useSelector((state) => state.app);
  axios.defaults.withCredentials = true;
  const [color, setColor] = useState([]);
  const [color2, setColor2] = useState([
    'rgb(137, 207, 240)',
    'rgb(115, 147, 179)',
    'rgb(0, 150, 255)',
    'rgb(100, 149, 237)',
    'rgb(111, 143, 175)',
    'rgb(93, 63, 211)',
  ]);

  const dynamicColors = (index) => {
    const r = index; // Math.floor(Math.random() * 255);
    const g = index; // Math.floor(Math.random() * 255);
    const b = index; // Math.floor(Math.random() * 255);
    return `rgb(${r},${g},${b})`;
  };

  const column = [
    {
      name: 'Application',
      cell: (row) => row.aliasName,

      reorder: true,
    },
    {
      name: 'First Name',
      cell: (row) => row.firstName,
      reorder: true,
    },
    {
      name: 'Last Name',
      cell: (row) => row.lastName,
      reorder: true,
    },
    {
      name: 'Email',
      cell: (row) => row.userEmail,
      grow: 2,
      reorder: true,
    },
    {
      name: 'Country',
      cell: (row) => row.country,
      compact: true,
      reorder: true,
    },
    {
      name: 'Total',
      cell: (row) => row.Total,
      reorder: true,
    },
  ];

  React.useEffect(() => {
    const payload = {};

    if (props.country) {
      payload.country = props.country;
    }
    if (props.startdate) {
      payload.startDate = props.startdate.toISOString();
    }
    if (props.enddate) {
      payload.endDate = props.enddate.toISOString();
    }
    if (props.dashapp) {
      payload.appName = props.dashapp;
    }
    // activeRegistered(payload,props.setActiveregisteredcount,props.setActiveRegisteredData,csrf)

    if (props.goflag) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
          {
            login: true,
            ...payload,
          },
          {
            headers: { ...constant.headers, 'csrf-token': csrf },
          }
        )
        .then((response) => {
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
              {
                loginTable: true,
                ...payload,
              },
              {
                headers: { ...constant.headers, 'csrf-token': csrf },
              }
            )
            .then((response) => {
              setData(response.data);
            })
            .catch((error) => {
              console.log(
                sanitizeUserInput(JSON.stringify(error)),
                'errorItem'
              );
            });
          const temp = [];
          for (let i = 0; i < response.data.length; i++) {
            temp.push(dynamicColors(i));
          }
          setColor(temp);

          const label = [];
          let count = 0;
          const user = [];
          response.data?.forEach((val) => {
            count = Number(count) + Number(val.Total);
            const app = val.aliasName ? val.aliasName : val.appName;
            label.push(app);
            user.push(val.Total);
          });
          setLoginLabel(label);
          setValue(user);
          setValues(label, user);
          props.setLoggedincount(count);
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)), 'errorItem');
        });
    }
  }, [props.goflag]);

  const setValues = (label, value) => {
    const pie = [];
    if (label) {
      label.map((app, index) =>
        pie.push({ id: app, label: app, value: value[index] })
      );
    }
    setPieValue(pie);
  };

  React.useEffect(() => {
    const temp = [];
    for (let i = 0; i < props.activeLoggedInData.length; i++) {
      temp.push(dynamicColors(i));
    }
    setColor(temp);
    const label = [];
    const user = [];
    if (props.activeLoggedInData.length > 0) {
      props.activeLoggedInData?.forEach((val) => {
        const app = val.aliasName ? val.aliasName : val.appName;
        label.push(app);
        user.push(val.Total);
      });
    }
    setLoginLabel(label);
    setValue(user);
    setValues(label, user);
    // setData(props.activeCountryData)
  }, [props.activeLoggedInData]);

  //   function updateAppname(app){

  //     axios.post(`https://947wb8hryj.execute-api.eu-central-1.amazonaws.com/sandbox/api/dashboard-data`,
  //     {
  //         "country":props.country,
  //         "login":true,
  //         "startDate":props.startdate.toISOString(),
  //         "endDate":props.enddate.toISOString(),
  //         "appName":app
  //     },
  //     {
  //         headers : {...constant.headers}
  //     }
  //     )
  //     .then((response) => {
  //         setData(response.data)
  //         setAppname(response.data.map((data)=>data.application_name))
  //         setValue(response.data.map((data)=>data.OccurenceValue))
  //         //dispatch(updateLoader(true));
  //     })
  //     .catch(error => {console.log(error)})
  // }

  return (
    <div className="container">
      <div className="d-flex justify-content-end">
        <div className="form-check form-switch my-1">
          <input
            className="form-check-input"
            type="checkbox"
            role="swich"
            id="flexSwitchCheckDefault"
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            Table View
          </label>
        </div>
      </div>
      <div className="row d-flex">
        {toggle && (
          <div style={{}}>
            <MyComponent
              header="true"
              fileName="Application onboarded"
              data={data}
              tableHeight="47vh"
              columns={column}
              paginationPerPage={10}
            />
          </div>
        )}
        {!toggle && (
          <div
            className="d-flex justify-content-center w-50"
            style={{ height: '50vh' }}
          >
            <Box sx={{ width: '100%' }}>
              <PieChart
                colors={color2}
                series={[
                  {
                    data: pieValue,
                  },
                ]}
                height={400}
                label={({ datum }) => datum.label}
                slotProps={{ legend: { hidden: true } }}
                labelPosition="inside"
              />
            </Box>
          </div>
        )}
        {!toggle && (
          <div
            className="d-flex justify-content-center login-legend w-50 overflow-auto"
            style={{ height: '50vh' }}
          >
            <div className="d-block w-100 h-auto">
              {pieValue.map((item, index) => (
                <div className="d-flex align-items-center mb-2">
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: color2[index % 6],
                    }}
                  />
                  <div className="ms-3 "> {item.label}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
