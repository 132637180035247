import React from 'react';

function TestPage() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 w-100">
      <h1 className="text-dark fw-bold user-select-none">
        Page not available ☹️!
      </h1>
    </div>
  );
}

export default TestPage;
