import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Collapse, { SubCollapse } from '../Collapse/collapse';
import * as constant from '../Constants/constant';
import { sanitizeUserInput } from '../../helper/sanitize';
import { setTranslation, updateTranslation } from '../../store/onboardfinal';
import { getLanguage } from '../../helper/helper';

export default function AddTranslation() {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.info);
  const { translations, finalTranslations } = useSelector(
    (state) => state.onboardFinal
  );
  const { data } = useSelector((state) => state.onboard);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    const { country } = data.options;
    const temp = data.options.allowedLanguages.map(async (data, index) => {
      const trans_url = `${process.env.REACT_APP_API_BASE_URL}/api/locales/${constant.updateTranslation.app}/${data.value}-${country}`;
      await axios
        .get(trans_url, {
          headers: { ...constant.headers },
        })
        .then((response) => {
          dispatch(
            setTranslation({
              key: `${data.value}-${country}`,
              value: response.data,
            })
          );
        })
        .catch((err) => console.log(sanitizeUserInput(JSON.stringify(err))));
    });
  }, []);

  function buildTranslate() {
    if (Object.keys(translations).length > 0) {
      return Object.keys(translations).map((keys, index) => {
        return (
          <div key={index} className="w-auto me-1 shadow">
            <SubCollapse
              title={`${getLanguage(keys.split('-')[0])} Translation`}
              info={info[keys.split('-')[0]]}
            >
              <div className="w-auto mx-3">
                {Object.entries(translations[keys]).map(([key, data], ind) => {
                  return (
                    !key.includes('_') && (
                      <div
                        className="d-flex align-items-center w-100 mb-2"
                        key={ind}
                      >
                        <div className="w-50">{key}</div>
                        <div className="w-50">
                          <input
                            className="form-control"
                            placeholder={data}
                            value={
                              Object.keys(finalTranslations).length !== 0 &&
                              keys in finalTranslations &&
                              Object.keys(finalTranslations[keys]).length !==
                                0 &&
                              key in finalTranslations[keys]
                                ? finalTranslations[keys][key]
                                : ''
                            }
                            onChange={(e) => {
                              dispatch(
                                updateTranslation({
                                  obj: keys,
                                  key,
                                  value: e.target.value,
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </SubCollapse>
          </div>
        );
        // return Object.entries(translations[keys]).map(([key, data], index) => {
        //   return (
        //     !key.includes("_") && (
        //       <div className="d-flex align-items-center w-100 mb-2" key={index}>
        //         <div className="w-50">{key}</div>
        //         <div className="w-50">
        //           <input
        //             className="form-control"
        //             placeholder={data}
        //             onChange={(e) => {
        //               //   updateValue(key, e.target.value)
        //             }}
        //           ></input>
        //         </div>
        //       </div>
        //     )
        //   );
        // });
      });
    }
    return <div>loading...</div>;
  }

  //   function updateValue(name, val){

  //     setValue({
  //       ...value,
  //       [name]:val

  //     })
  //   }
  return (
    <div className="w-auto" style={{ marginTop: '2rem', marginLeft: '2rem' }}>
      {buildTranslate()}
    </div>
  );
}
