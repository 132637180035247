import React from 'react';
import { useDispatch } from 'react-redux';
import { modifyRegisterValidation } from '../../store/slice';
import { text, email, phone, password } from './validations_list';

export default function ValidationComponentView({
  value,
  index,
  openVal,
  setValData,
}) {
  const dispatch = useDispatch();
  const validations = (val) => {
    switch (val.type) {
      case 'text':
        return (
          <div className="d-flex ">
            <div
              className="d-flex justify-content-center align-items-center user-select-none px-2 border rounded-pill me-1"
              style={
                'type' in val.validations
                  ? { backgroundColor: '#045EB7', color: '#fff' }
                  : {}
              }
            >
              <span>{text.type}</span>
              {/* {"type" in val.validations ? (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                  onClick={() =>
                    dispatch(
                      modifyRegisterValidation({
                        index: index,
                        key: "type",
                        value: text.type,
                      })
                    )
                  }
                >
                  cancel
                </span>
                
              ) : (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-success"
                  onClick={() =>
                    dispatch(
                      modifyRegisterValidation({
                        index: index,
                        key: "type",
                        value: text.type,
                      })
                    )
                  }
                >
                  check_circle
                </span>
              )} */}
            </div>
            <div
              className="d-flex justify-content-center align-items-center user-select-none px-2 border rounded-pill me-1"
              style={
                'max' in val.validations
                  ? { backgroundColor: '#045EB7', color: '#fff' }
                  : {}
              }
            >
              {'max' in val.validations ? (
                <div>
                  maximum<span className="ms-1">{val.validations.max}</span>
                </div>
              ) : (
                <span>maximum</span>
              )}
              {/* {"max" in val.validations && <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                  onClick={() =>{
                    setValData({
                      title:"maximum",
                      index: index,
                      key: "max",
                      check:val.validations.min
                    })
                    openVal()}}
                >
                   edit_square
                </span>} */}
              {/* {"max" in val.validations ? (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                  onClick={() =>
                    dispatch(
                      modifyRegisterValidation({
                        index: index,
                        key: "max",
                        value: text.max,
                      })
                    )
                  }
                >
                  cancel
                </span>
              ) : (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-success"
                  onClick={() =>
                    dispatch(
                      modifyRegisterValidation({
                        index: index,
                        key: "max",
                        value: text.max,
                      })
                    )
                  }
                >
                  check_circle
                </span>
              )} */}
            </div>
            <div
              className="d-flex justify-content-center align-items-center user-select-none px-2 border rounded-pill me-1"
              style={
                'min' in val.validations
                  ? { backgroundColor: '#045EB7', color: '#fff' }
                  : {}
              }
            >
              {'min' in val.validations ? (
                <div>
                  minimum<span className="ms-1">{val.validations.min}</span>
                </div>
              ) : (
                <span>minimum</span>
              )}
              {/* {"min" in val.validations && <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                  onClick={() =>{
                    setValData({
                      title:"minimum",
                      index: index,
                      key: "min",
                      check:val.validations.max
                    })
                    openVal()}}
                >
                  edit_square
                </span>}
              {"min" in val.validations ? (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                  onClick={() =>
                    dispatch(
                      modifyRegisterValidation({
                        index: index,
                        key: "min",
                        value: text.min,
                      })
                    )
                  }
                >
                  cancel
                </span>
              ) : (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-success"
                  onClick={() =>
                    dispatch(
                      modifyRegisterValidation({
                        index: index,
                        key: "min",
                        value: text.min,
                      })
                    )
                  }
                >
                  check_circle
                </span>
              )} */}
            </div>
          </div>
        );

      case 'email':
        return (
          <div className="d-flex ">
            <div
              className="text-nowrap user-select-none px-2 border rounded-pill me-1"
              style={
                'type' in val.validations
                  ? { backgroundColor: '#045EB7', color: '#fff' }
                  : {}
              }
            >
              {email.type}
            </div>
          </div>
        );
      case 'phone':
        return (
          <div className="d-flex ">
            <div
              className="d-flex justify-content-center align-items-center user-select-none px-2 border rounded-pill me-1"
              style={
                'type' in val.validations
                  ? { backgroundColor: '#045EB7', color: '#fff' }
                  : {}
              }
            >
              <span>{phone.type}</span>
              {/* {("type" in val.validations)?(
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                  onClick={() =>
                dispatch(
                  modifyRegisterValidation({
                    index: index,
                    key: "type",
                    value: phone.type,
                  })
                )
              }
                >
                  cancel
                </span>
              ) : (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-success"
                 onClick={() =>
                dispatch(
                  modifyRegisterValidation({
                    index: index,
                    key: "type",
                    value: phone.type,
                  })
                )
              }
                >
                  check_circle
                </span>
              )} */}
            </div>
            <div
              className="d-flex justify-content-center align-items-center user-select-none px-2 border rounded-pill me-1"
              style={
                'max' in val.validations
                  ? { backgroundColor: '#045EB7', color: '#fff' }
                  : {}
              }
            >
              <span>{`max-${phone.max}`}</span>
              {/* {("max" in val.validations)?(
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                onClick={() =>
                dispatch(
                  modifyRegisterValidation({
                    index: index,
                    key: "max",
                    value: phone.max,
                  })
                )
              }
                >
                  cancel
                </span>
              ) : (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-success"
               onClick={() =>
                dispatch(
                  modifyRegisterValidation({
                    index: index,
                    key: "max",
                    value: phone.max,
                  })
                )
              }
                >
                  check_circle
                </span>
              )} */}
            </div>
            <div
              className="d-flex justify-content-center align-items-center user-select-none px-2 border rounded-pill me-1"
              style={
                'min' in val.validations
                  ? { backgroundColor: '#045EB7', color: '#fff' }
                  : {}
              }
            >
              <span>{`min-${phone.min}`}</span>
              {/* {("min" in val.validations)?(
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-light"
                  onClick={() =>
                dispatch(
                  modifyRegisterValidation({
                    index: index,
                    key: "min",
                    value: phone.min,
                  })
                )
              }
                >
                  cancel
                </span>
              ) : (
                <span
                  role="button"
                  className="ms-1 material-symbols-outlined align-middle fs-6 text-success"
                onClick={() =>
                dispatch(
                  modifyRegisterValidation({
                    index: index,
                    key: "min",
                    value: phone.min,
                  })
                )
              }
                >
                  check_circle
                </span>
              )} */}
            </div>
          </div>
        );

      case 'password':
        return (
          <div className="d-flex ">
            <div
              className="text-nowrap user-select-none px-2 border rounded-pill me-1"
              style={{ backgroundColor: '#045EB7', color: '#fff' }}
            >
              {password.type}
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };
  return <div>{validations(value)} </div>;
}
