import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateRegexpatternvalue,
  updateRegexmessagevalue,
} from '../../store/slice';
import { trimData } from '../../helper/onboard';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    background: 'white',
    color: 'white',
  },
};

function Regexpopup({ modalIsOpen, closeModal, index, name }) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.onboard);

  const { info } = useSelector((state) => state.info);
  const [pattern, setPattern] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    if (data?.options?.fields[index]?.validations?.regex?.pattern) {
      setPattern(data.options.fields[index]?.validations?.regex?.pattern);
      setMessage(data.options.fields[index]?.validations?.regex?.msg);
    } else {
      setPattern('');
      setMessage('');
    }
  }, [index]);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateRegexpatternvalue({ index, value: pattern, name }));
    dispatch(updateRegexmessagevalue({ index, value: message, name }));
    close();
  }

  function close() {
    closeModal();
  }

  // const isRegExp = (string) => {
  //     try {
  //         return new Function(`
  //             "use strict";
  //             try {
  //                 new RegExp(${string});
  //                 return true;
  //             } catch (e) {
  //                 return false;
  //             }
  //         `)();
  //     } catch(e) {
  //         return false;
  //     }
  // };

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="text-dark">
          <div className="text-center">
            <b>Add Regex validations</b>
          </div>
          <form>
            <div className="form-group">
              <div className="d-flex align-items-center mb-2">
                <label htmlFor="usr" className="me-1 m-0">
                  Enter your validation
                </label>
                <span
                  className="material-symbols-outlined fs-6 text-info"
                  role="button"
                  data-toggle="tooltip"
                  title={info?.regexPattern}
                >
                  info
                </span>
              </div>

              <input
                type="text"
                className="form-control"
                value={pattern}
                onChange={(e) => {
                  setPattern(trimData(e.target.value));
                }}
              />
            </div>
            {/* {isRegExp(pattern) === false ? 
                        <div style={{color:"red"}}>* Please enter correct validations </div>
                        :""} */}
            <div className="form-group">
              <div className="d-flex align-items-center mb-2">
                <label htmlFor="pwd" className="me-1 m-0">
                  Enter error message to shown
                </label>
                <span
                  className="material-symbols-outlined fs-6 text-info"
                  role="button"
                  data-toggle="tooltip"
                  title={info?.regexMessage}
                >
                  info
                </span>
              </div>
              <textarea
                type="text"
                className="form-control"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-secondary btn-sm me-4"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary btn-sm"
                disabled={pattern === '' || message === ''}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default Regexpopup;
