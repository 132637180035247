import React from 'react';
import { NavLink } from 'react-router-dom';

function Signinfail() {
  return (
    <div className="fof">
      <div style={{ margin: '20rem' }}>
        <h1>User Not Found</h1>
        <h5 style={{ marginLeft: '5rem' }}>Back to sign in page</h5>
        <NavLink to="/login">
          <button
            style={{
              color: '#045EB7',
              border: '1px solid #045EB7',
              marginLeft: '7rem',
            }}
          >
            Sign in
          </button>
        </NavLink>
      </div>
    </div>
  );
}

export default Signinfail;
