const OnboardData = {
  customizeField: {},
  consentRemove: false,
  consent: {
    name: 'promotions',
    consentURL: 'https://www.amgen.com/dp',
    consentURLLinks: {},
    label:
      'I consent to be contacted by Amgen and its agents in the future regarding Amgen products, services, programs and/or events',
    links: [
      {
        key: 'consent',
        labelFor: 'consent',
      },
    ],
    placeholder:
      'I consent to be contacted by Amgen and its agents in the future regarding Amgen products, services, programs and/or events',
    type: 'checkbox',
    validations: {
      required: false,
    },
    values: '',
  },
  newField: {
    name: '',
    label: '',
    placeholder: '',
    type: 'text',
    validations: {
      required: false,
    },
    values: null,
  },
  Loader: '',
  role: '',
  initialLoader: true,
  completeButtonDisable: false,
  kind: 'onboard',
  request_id: '',
  translations: [],
  clientName: '',
  data: {},
  adminApplications: [],
};
export default OnboardData;
