export const baseURL =
  'https://0orkwkczzl.execute-api.eu-central-1.amazonaws.com/dev/api';
// "https://947wb8hryj.execute-api.eu-central-1.amazonaws.com/sandbox/api";
// export const baseURL = "http://localhost:3000/dev/api";
export const headers = {
  // Referer : baseURL,
  'app-name': 'dashboard',
};

const allowedApps = [
  'AmgenISS',
  'BeyondstatinsAU',
  'agGMIUS',
  'agGMIFrance',
  'agGMISpain',
  'AmgenCompass',
  'demoTwo',
  'demoOne',
];
const allowedLanguages = {
  Spanish: 'es',
  English: 'en',
  French: 'fr',
};
const allowedCountry = {
  Spain: 'ES',
  Australia: 'AU',
  US: 'US',
  France: 'FR',
  Canada: 'CA',
};

const urlFormat =
  /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const updateTranslation = { app: 'globalUiDefault', country: 'CA' };

const blockedDomain = ['.amgen.com', '.forgeblocks.com', '.forgerock.io'];
const blockedNavItem = ['selfservice.amgen.com', 'd15eg5cisfcohl.'];
const appList = {
  RedAmgenES: 'RedAmgen ES',
  AmgevitaHCPCA: 'Amgevita HCP CA',
  agGMIUS: 'Amgen Medinfo US',
  AmgenAssistAU: 'Amgen Assist',
  agGMICanada: 'Amgen Medinfo Canada',
  EvenityBR: 'Evenity BR',
  ProliaHCPCA: 'Prolia HCP CA',
  WezlanaCA: 'Wezlana CA',
  Help4U: 'Help4U AU',
  RepathaHCPCA: 'Repatha HCP CA',
  LumakrasJP: 'LMK-JP',
  tepezzaSA: 'Tepezza SA',
  EvenityCA: 'Evenity CA',
  agGMIFrance: 'Amgen Medinfo France',
  ProliaFR: 'Prolia FR',
  BeyondstatinAU: 'Beyondstatin AU',
  AmgenCompass: 'Amgen Compass',
  RepathaCOPAY: 'Repatha COPAY AU',
  AmgenISS: 'Amgen ISS',
  agGMIAustralia: 'Amgen Medinfo AU',
  agGMISpain: 'Amgen Medinfo Spain',
  OtezlaHCPCA: 'Otezla HCP CA',
  UpliznaBR: 'Uplizna BR',
  AmgenproFr: 'Amgenpro FR',
  RepathaPBSCalculatorAU: 'Repatha PBS Calculator AU',
  demoTwo: 'Demo application 2',
  demoOne: 'Demo application 1',
};

export {
  allowedApps,
  allowedLanguages,
  allowedCountry,
  urlFormat,
  updateTranslation,
  emailRegex,
  blockedDomain,
  blockedNavItem,
  appList,
};
