import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useOktaAuth, withOktaAuth } from '@okta/okta-react';
import './Sidebar.css';

import { useSelector, useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { updateRole } from '../../store/slice';
import {
  setAllowedApplication,
  updateInitialLoader,
  setActive,
} from '../../store/dashboardslice';
import {
  dashboardOnly,
  superAdmin,
  admin,
  appOwner,
  devSuperAdmin,
  devAdmin,
  devAppOwner,
} from './sidebarOptions';
import { blockedNavItem } from '../Constants/constant';

function Sidebar(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const username = localStorage.getItem('username');
  const dispatch = useDispatch();
  const { allowedApp, role, initialLoader, active } = useSelector(
    (state) => state.dashboard
  );
  const { csrf, show } = useSelector((state) => state.app);

  const location = useLocation();

  const res = username == null ? '' : username.split('@');
  const [name, setName] = useState('');

  const token = localStorage.getItem('okta-token-storage');
  useEffect(() => {
    if (token) {
      const parsedToken = JSON.parse(token);
      const decoded = jwt_decode(parsedToken.idToken.idToken);
      setName(decoded.name);
    }
  }, []);
  const dynamicNav = (userType) => {
    const type = userType ? userType.toLowerCase() : 'not found';
    switch (type) {
      case 'app owner':
        const z =
          process.env.REACT_APP_SIDE_BAR === 'dev' ? devAppOwner : appOwner;
        return (
          <div style={{ marginTop: '2rem' }} className="nav-list">
            {z.map((navItems, index) => {
              if (navItems.item === 'Validationform') {
                if (
                  !blockedNavItem.some((val) =>
                    window.location.hostname.includes(val)
                  )
                ) {
                  return (
                    <a
                      className="nav-link "
                      href={navItems.to}
                      target="blank"
                      style={{
                        background: '#435F7B',
                        borderBottom: '1px solid white',
                      }}
                    >
                      <span
                        style={{ color: 'white' }}
                        className={navItems.symbol}
                      >
                        {navItems.symbolName}
                      </span>
                      <span
                        style={{ color: 'white' }}
                        className="nav-link-name"
                      >
                        {navItems.item}
                      </span>
                    </a>
                  );
                }
                return <></>;
              }
              return (
                <NavLink
                  key={index}
                  to={navItems.to}
                  className="nav-link "
                  data-toggle="tooltip"
                  title={navItems.title}
                  style={({ isActive }) => ({
                    background: isActive ? '#0E2236' : '#435F7B',
                    borderBottom: isActive
                      ? '1px solid white'
                      : '1px solid white',
                  })}
                >
                  <span style={{ color: 'white' }} className={navItems.symbol}>
                    {navItems.symbolName}
                  </span>
                  <span style={{ color: 'white' }} className="nav-link-name">
                    {navItems.item}
                  </span>
                </NavLink>
              );
            })}
          </div>
        );
      case 'admin':
        const x = process.env.REACT_APP_SIDE_BAR === 'dev' ? devAdmin : admin;
        return (
          <div style={{ marginTop: '2rem' }} className="nav-list">
            {x.map((navItems, index) => {
              if (navItems.item === 'Validationform') {
                if (
                  !blockedNavItem.some((val) =>
                    window.location.hostname.includes(val)
                  )
                ) {
                  return (
                    <a
                      className="nav-link "
                      href={navItems.to}
                      target="blank"
                      style={{
                        background: '#435F7B',
                        borderBottom: '1px solid white',
                      }}
                    >
                      <span
                        style={{ color: 'white' }}
                        className={navItems.symbol}
                      >
                        {navItems.symbolName}
                      </span>
                      <span
                        style={{ color: 'white' }}
                        className="nav-link-name"
                      >
                        {navItems.item}
                      </span>
                    </a>
                  );
                }
                return <></>;
              }
              return (
                <NavLink
                  key={index}
                  to={navItems.to}
                  className="nav-link "
                  data-toggle="tooltip"
                  title={navItems.title}
                  style={({ isActive }) => ({
                    background: isActive ? '#0E2236' : '#435F7B',
                    borderBottom: isActive
                      ? '1px solid white'
                      : '1px solid white',
                  })}
                >
                  <span style={{ color: 'white' }} className={navItems.symbol}>
                    {navItems.symbolName}
                  </span>
                  <span style={{ color: 'white' }} className="nav-link-name">
                    {navItems.item}
                  </span>
                </NavLink>
              );
            })}
          </div>
        );
      case 'super admin':
        const y =
          process.env.REACT_APP_SIDE_BAR === 'dev' ? devSuperAdmin : superAdmin;
        return (
          <div style={{ marginTop: '2rem' }} className="nav-list">
            {y.map((navItems, index) => {
              if (navItems.item === 'Validationform') {
                if (
                  !blockedNavItem.some((val) =>
                    window.location.hostname.includes(val)
                  )
                ) {
                  return (
                    <a
                      className="nav-link "
                      href={navItems.to}
                      target="blank"
                      style={{
                        background: '#435F7B',
                        borderBottom: '1px solid white',
                      }}
                    >
                      <span
                        style={{ color: 'white' }}
                        className={navItems.symbol}
                      >
                        {navItems.symbolName}
                      </span>
                      <span
                        style={{ color: 'white' }}
                        className="nav-link-name"
                      >
                        {navItems.item}
                      </span>
                    </a>
                  );
                }
                return <></>;
              }
              return (
                <NavLink
                  key={index}
                  to={navItems.to}
                  className="nav-link "
                  data-toggle="tooltip"
                  title={navItems.title}
                  style={({ isActive }) => ({
                    background: isActive ? '#0E2236' : '#435F7B',
                    borderBottom: isActive
                      ? '1px solid white'
                      : '1px solid white',
                  })}
                >
                  <span style={{ color: 'white' }} className={navItems.symbol}>
                    {navItems.symbolName}
                  </span>
                  <span style={{ color: 'white' }} className="nav-link-name">
                    {navItems.item}
                  </span>
                </NavLink>
              );
            })}
          </div>
        );
      case 'dashboard only':
        return (
          <div style={{ marginTop: '2rem' }} className="nav-list">
            {dashboardOnly.map((navItems, index) => {
              return (
                <NavLink
                  key={index}
                  to={navItems.to}
                  className="nav-link "
                  data-toggle="tooltip"
                  title={navItems.title}
                  style={({ isActive }) => ({
                    background: isActive ? '#0E2236' : '#435F7B',
                    borderBottom: isActive
                      ? '1px solid white'
                      : '1px solid white',
                  })}
                >
                  <span style={{ color: 'white' }} className={navItems.symbol}>
                    {navItems.symbolName}
                  </span>
                  <span style={{ color: 'white' }} className="nav-link-name">
                    {navItems.item}
                  </span>
                </NavLink>
              );
            })}
          </div>
        );
      case 'not found':
        return (
          <div style={{ marginTop: '2rem' }} className="nav-list">
            <NavLink
              data-toggle="tooltip"
              title="No role found"
              to="/rolenotfound"
              className="nav-link"
            >
              <span
                style={{ color: 'white' }}
                className="material-symbols-outlined"
              >
                live_help
              </span>
              <span style={{ color: 'white' }} className="nav-link-name">
                Help
              </span>
            </NavLink>
            <button
              className="nav-link bg-transparent w-100 border-0"
              onClick={async () => {
                localStorage.removeItem('username');
                localStorage.removeItem('role');
                await oktaAuth.signOut({
                  postLogoutRedirectUri: `${window.location.origin}/logout`,
                });
              }}
            >
              <i
                style={{ color: 'white' }}
                className="fas fa-sign-out nav-link-icon"
              />
              <span style={{ color: 'white' }} className="nav-link-name">
                Logout
              </span>
            </button>
          </div>
        );
      default:
        return (
          <div style={{ marginTop: '2rem' }} className="nav-list">
            <NavLink
              data-toggle="tooltip"
              title="No role found"
              to="/rolenotfound"
              className="nav-link"
            >
              <span
                style={{ color: 'white' }}
                className="material-symbols-outlined"
              >
                live_help
              </span>
              <span style={{ color: 'white' }} className="nav-link-name">
                Help
              </span>
            </NavLink>
            <button
              className="nav-link bg-transparent w-100 border-0"
              onClick={async () => {
                localStorage.removeItem('username');
                localStorage.removeItem('role');
                await oktaAuth.signOut({
                  postLogoutRedirectUri: `${window.location.origin}/logout`,
                });
              }}
            >
              <i
                style={{ color: 'white' }}
                className="fas fa-sign-out nav-link-icon"
              />
              <span style={{ color: 'white' }} className="nav-link-name">
                Logout
              </span>
            </button>
          </div>
        );
    }
  };

  return (
    <div>
      <div
        className={show ? 'visible' : 'invisible'}
        style={{ color: 'white', paddingLeft: '70px', paddingTop: '40px' }}
      >
        <h6 className="pe-none">Welcome</h6>
        <h6 className="pe-none">{name !== '' ? name : res[0]}</h6>
      </div>
      {dynamicNav(role)}
      {/* {!role ? 
            <div style={{ marginTop: "2rem" }} className='nav-list'>
                
                <NavLink data-toggle="tooltip" title="No role found" to='/rolenotfound' className='nav-link'>
                    <span style={{ color: "white" }} class="material-symbols-outlined">live_help</span>
                    <span style={{ color: "white" }} className='nav-link-name'>Help</span>
                </NavLink>
                <button className='nav-link bg-transparent w-100 border-0' onClick={(async ()=>{ 
                    localStorage.removeItem("username");
                    localStorage.removeItem("role");
                    await oktaAuth.signOut({postLogoutRedirectUri: `${window.location.origin}/logout`});
                  
                    })}>
                    <i style={{ color: "white" }} className='fas fa-sign-out nav-link-icon'></i>
                    <span style={{ color: "white" }} className='nav-link-name'>Logout</span>
                </button>
            </div>
            : role == "App Owner" ?
            <div style={{ marginTop: "2rem" }} className='nav-list'>
            <NavLink data-toggle="tooltip" title="Dashboard" to='/dashboard' className='nav-link ' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                <span style={{ color: "white" }} class="material-symbols-outlined">dashboard</span>
                <span style={{ color: "white" }} className='nav-link-name'>Dashboard</span>
            </NavLink>
            <NavLink data-toggle="tooltip" title="Applications" to='/applicationowner' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                <span style={{ color: "white" }} class="material-symbols-outlined">apps</span>
                <span style={{ color: "white" }} className='nav-link-name' >Applications</span>
            </NavLink>
            <NavLink data-toggle="tooltip" title="My Requests" to='/myrequest' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                <span style={{ color: "white" }} class="material-symbols-outlined">request_quote</span>
                <span style={{ color: "white" }} className='nav-link-name' >My Requests</span>
            </NavLink>
            <NavLink data-toggle="tooltip" title="Access" to='/access' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                <span style={{ color: "white" }} class="material-symbols-outlined">settings_applications</span>
                <span style={{ color: "white" }} className='nav-link-name' >Application Access</span>
            </NavLink>
            <NavLink data-toggle="tooltip" title="Preload" to='/preloaduser' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
            <span style={{ color: "white" }} class="material-symbols-outlined">group_add</span>
            <span style={{ color: "white" }} className='nav-link-name' >Preload User</span>
            </NavLink>
            <NavLink data-toggle="tooltip" title="Mail Template" to='/mailtemplate' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                <span style={{ color: "white" }} class="material-symbols-outlined">mail</span>
                <span style={{ color: "white" }} className='nav-link-name' >Mail Template</span>
            </NavLink>
            <NavLink data-toggle="tooltip" title="Update Translation" to='/updatetranslation' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                    <span style={{ color: "white" }} class="material-symbols-outlined">translate</span>
                    <span style={{ color: "white" }} className='nav-link-name' >Update Translation</span>
                    </NavLink>
            <NavLink data-toggle="tooltip" title="Client Creation" to='/clientcreation' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                    <span style={{ color: "white" }} class="material-symbols-outlined">api</span>
                    <span style={{ color: "white" }} className='nav-link-name' >Client Creation</span>
            </NavLink>
            <NavLink data-toggle="tooltip" title="Whitelisting URLs" to='/whitelist' className='nav-link ' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                <span style={{ color: "white" }} class="material-symbols-outlined">add_link</span>
                <span style={{ color: "white" }} className='nav-link-name'>Whitelist</span>
            </NavLink>
            </div>
            :
            role ==  "Super Admin" || "Admin" ?
            <div style={{ marginTop: "2rem" }} className='nav-list'>
                    <NavLink to='/dashboard' className='nav-link ' data-toggle="tooltip" title="Dashboard" style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                        <span style={{ color: "white" }} class="material-symbols-outlined">dashboard</span>
                        <span style={{ color: "white" }} className='nav-link-name'>Dashboard</span>
                    </NavLink>
                    <NavLink data-toggle="tooltip" title="Applications" to='/applicationadmin' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                        <span style={{ color: "white" }} class="material-symbols-outlined">apps</span>
                        <span style={{ color: "white" }} className='nav-link-name' >Applications</span>
                    </NavLink>
                    <NavLink data-toggle="tooltip" title="User" to='/user' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                        <span style={{ color: "white" }} class="material-symbols-outlined">person</span>
                        <span style={{ color: "white" }} className='nav-link-name' >Users</span>
                    </NavLink>
                     <NavLink data-toggle="tooltip" title="Requests" to='/request' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                        <span style={{ color: "white" }} class="material-symbols-outlined">request_quote</span>
                        <span style={{ color: "white" }} className='nav-link-name' >Requests</span>
                    </NavLink>
                    <NavLink data-toggle="tooltip" title="Preload" to='/preloaduser' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                    <span style={{ color: "white" }} class="material-symbols-outlined">group_add</span>
                    <span style={{ color: "white" }} className='nav-link-name' >Preload User</span>
                    </NavLink>
                    <NavLink data-toggle="tooltip" title="Mail Template" to='/mailtemplate' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                    <span style={{ color: "white" }} class="material-symbols-outlined">mail</span>
                    <span style={{ color: "white" }} className='nav-link-name' >Mail Template</span>
                    </NavLink>
                    <NavLink data-toggle="tooltip" title="Update Translation" to='/updatetranslation' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                    <span style={{ color: "white" }} class="material-symbols-outlined">translate</span>
                    <span style={{ color: "white" }} className='nav-link-name' >Update Translation</span>
                    </NavLink>
                    <NavLink data-toggle="tooltip" title="Client Creation" to='/clientcreation' className='nav-link' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                        <span style={{ color: "white" }} class="material-symbols-outlined">api</span>
                        <span style={{ color: "white" }} className='nav-link-name' >Client Creation</span>
                    </NavLink>
                    <NavLink data-toggle="tooltip" title="Whitelisting URLs" to='/whitelist' className='nav-link ' style={({ isActive }) => ({ background: isActive ? "#0E2236" : "#435F7B", borderBottom: isActive ? "1px solid white" : "1px solid white" })}>
                        <span style={{ color: "white" }} class="material-symbols-outlined">add_link</span>
                        <span style={{ color: "white" }} className='nav-link-name'>Whitelist</span>
                    </NavLink>
                    
                    
            </div>
            :"Not Found"
            } */}
    </div>
  );
}

export default withOktaAuth(Sidebar);
