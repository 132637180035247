import React, { useState } from 'react';

import Modal from 'react-modal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import * as constant from '../Constants/constant';
import { sanitizeUserInput } from '../../helper/sanitize';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    background: 'white',
    color: 'white',
  },
};

function MailTestPopup({
  modalIsOpen,
  closeModal,
  maildata,
  setMessage,
  openmessagepopup,
  allowLan,
  langList,
}) {
  const { csrf } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const [languages, setAllowLan] = useState(allowLan);
  const [selectedOpt, setSelectedOpt] = useState([]);
  axios.defaults.withCredentials = true;
  const [body, setBody] = useState({
    firstName: '',
    lastName: '',
    language: '',
    email: '',
    title: '',
    country: '',
    language: '',
  });
  function reset() {
    setBody({
      firstName: '',
      lastName: '',
      language: '',
      email: '',
      title: '',
      country: '',
      language: '',
    });
  }
  function handleClose() {
    reset();
    closeModal();
  }

  let subtitle;

  React.useEffect(() => {
    if (!allowLan || allowLan === '') {
      setAllowLan([
        {
          label: 'English',
          value: 'en',
        },
        {
          label: 'Italian',
          value: 'it',
        },
        {
          label: 'French',
          value: 'fr',
        },
        {
          label: 'Spanish',
          value: 'es',
        },
        {
          label: 'German',
          value: 'de',
        },
      ]);
    }
    setBody({ ...body, ...maildata });
  }, []);

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = 'white';
  }

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <button
            onClick={() => {
              handleClose();
            }}
            style={{
              textDecoration: 'none',
              border: 'none',
              borderRadius: '16px',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            className="material-symbols-outlined position-absolute top-0 end-0"
          >
            close
          </button>
          <div className="text-primary fs-3 fw-bold mb-2">
            {maildata.title}Mail Testing
          </div>
          <div className="row">
            <div className="col-6">
              {langList ? (
                <div>
                  <div className="mb-2">
                    <p className="text-dark mb-1">Select Country</p>
                    <div className="dropdown">
                      <select
                        name="country"
                        value={body.country}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setBody({
                            ...body,
                            [e.target.name]: e.target.value,
                            language: '',
                          });
                          const x = langList.filter((x) => {
                            if (x.value === e.target.value) {
                              return x;
                            }
                          });
                          setSelectedOpt(x[0].supportedlang);
                        }}
                      >
                        <option value="">Select Country</option>

                        {langList?.map((option) => {
                          return (
                            <option value={option.value}>{option.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="mb-2">
                    <p className="text-dark mb-1">Select Language</p>
                    <div className="dropdown">
                      <select
                        name="language"
                        value={body.language}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) =>
                          setBody({ ...body, [e.target.name]: e.target.value })
                        }
                      >
                        <option value="">Select Language</option>

                        {selectedOpt?.map((option) => {
                          return (
                            <option value={option.Value}>{option.Label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mb-2">
                  <p className="text-dark mb-1">Select Language</p>
                  <div className="dropdown">
                    <select
                      name="language"
                      value={body.language}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) =>
                        setBody({ ...body, [e.target.name]: e.target.value })
                      }
                    >
                      <option value="">Select Language</option>

                      {allowLan?.map((option) => {
                        return (
                          <option value={option.value}>{option.label}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              <div className="mb-2">
                <p className="mb-1">
                  <span className="text-dark">Email</span>
                  <sup className="text-danger">*</sup>
                </p>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="basic-addon1"
                    name="email"
                    placeholder="Email"
                    value={body.email}
                    onChange={(e) =>
                      setBody({ ...body, [e.target.name]: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-2">
                <p className="text-dark mb-1">Select Mail Template</p>
                <div className="dropdown">
                  <select
                    name="activity"
                    value={body.activity}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) =>
                      setBody({ ...body, [e.target.name]: e.target.value })
                    }
                  >
                    <option value="">Select Activity</option>
                    <option value="EmailUpdateConfirmation">
                      Email update confirmation
                    </option>
                    <option value="PasswordUpdateConfirmation">
                      Password update confirmation
                    </option>
                    <option value="ProfileUpdateConfirmation">
                      Profile update confirmation
                    </option>
                    <option value="Registration">Registration</option>
                    <option value="ResetPassword">Reset password</option>
                    <option value="SendOTP">Send OTP</option>
                    <option value="success">Success mail</option>
                    <option value="fail">Failure mail</option>
                    {/* <option value="inactive">Inactive notification mail</option>
                    <option value="deactive">Deactivated notification mail</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            {loading ? (
              <button
                className="btn btn-primary"
                style={{ width: '80px' }}
                type="button"
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            ) : (
              <button
                className="btn btn-primary"
                style={{ width: '80px' }}
                type="button"
                disabled={body.email === '' && true}
                onClick={() => {
                  setLoading(true);
                  axios
                    .post(
                      `${process.env.REACT_APP_API_BASE_URL}/api/test-blast`,
                      {
                        ...body,
                        // appName:maildata.appName
                        appName:
                          body.activity === 'success' ||
                          body.activity === 'fail' ||
                          body.activity === 'inactive' ||
                          body.activity === 'deactive'
                            ? maildata.appName
                            : 'selfServiceDefault',
                      },
                      {
                        headers: { ...constant.headers, 'csrf-token': csrf },
                      }
                    )
                    .then((response) => {
                      setMessage((prevState) => ({
                        ...prevState,
                        title: 'Mail blast',
                        body: 'Mail blasted successfully',
                        success: true,
                      }));
                      setLoading(false);

                      handleClose();
                      openmessagepopup();
                    })
                    .catch((error) => {
                      setMessage((prevState) => ({
                        ...prevState,
                        title: 'Mail blast',
                        body: 'Something went wrong, please verify whether selected template exists or not for the selected application.',
                        success: false,
                      }));
                      setLoading(false);
                      handleClose();
                      openmessagepopup();
                      console.log(sanitizeUserInput(JSON.stringify(error)));
                    });
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MailTestPopup;
