import React, { useState } from 'react';

import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import * as constant from '../Constants/constant';
import Preview from '../Dynamic_mail/preview';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    background: 'white',
    color: 'white',
  },
};

function EmailTemplatePreviewPopup({ modalIsOpen, closeModal, data }) {
  let subtitle;

  function afterOpenModal() {
    subtitle.style.color = 'white';
  }

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ height: '90vh' }}>
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
      </Modal>
    </div>
  );
}

export default EmailTemplatePreviewPopup;
