import React, { useEffect, useState } from 'react';

import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import axios from 'axios';
import * as constant from '../Constants/constant';
import { sanitizeUserInput } from '../../helper/sanitize';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '80%',
    background: 'white',
    color: 'white',
  },
};

function FileUpload({
  modalIsOpen,
  closeModal,
  metaData,
  setMessage,
  openmessagepopup,
}) {
  const { csrf } = useSelector((state) => state.app);
  const [hasRows, setHasRows] = useState([]);
  const [data, setData] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState('');

  const handleFileUpload = (files) => {
    // const file = e.target.files[0];

    if (files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the first sheet is your target sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert the worksheet to an array of objects
        const excelData = XLSX.utils.sheet_to_json(worksheet, {
          header: ['key', 'value'],
        });

        // Check if there is at least one row
        setHasRows(excelData);
      };

      reader.readAsArrayBuffer(files);
    }
  };
  function handleSubmit() {
    setSubmit(true);
    if (hasRows.length > 0) {
      let value = {};
      hasRows.map((data) => (value = { ...value, [data.key]: data.value }));
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
          {
            application_name: metaData.appname,
            request_type: 'updateTranslation',
            email: localStorage.getItem('username'),
            data: {
              language: metaData.languageloc,
              country: metaData.countryloc,
              data: value,
            },
          },
          {
            headers: { ...constant.headers, 'csrf-token': csrf },
          }
        )
        .then((response) => {
          setMessage((prevState) => ({
            ...prevState,
            title: 'Language Translation Updated Successfully',
            body: 'Language translation updated succesfully. Template Waiting for Approval',
            success: true,
          }));
          setSubmit(false);
          closeModal();
          openmessagepopup();
        })
        .catch((error) => {
          setSubmit(false);
          closeModal();
          console.log(sanitizeUserInput(JSON.stringify(error)));
        });
    } else {
      setError(`${data.name} is didn't contain any values`);
      setSubmit(false);
    }
  }

  return (
    <div className="position-relative w-50">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div style={{ color: '#0063c3' }}>
              <b>Upload File</b>
            </div>
            <button
              onClick={() => {
                closeModal();
              }}
              style={{
                textDecoration: 'none',
                border: 'none',
                borderRadius: '16px',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
              className="material-symbols-outlined position-absolute top-0 end-0"
            >
              close
            </button>
          </div>
          <div className="w-100">
            <Dropzone
              multiple={false}
              onDrop={(files) => {
                handleFileUpload(files[0]);
                setData(files);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p
                    role="button"
                    className="my-auto d-flex align-items-center rounded px-5 py-3 secondary"
                    style={{
                      width: 'max-content',
                      borderWidth: '1px',
                      borderStyle: 'dashed',
                    }}
                  >
                    <span className="material-symbols-outlined fs-1">
                      folder
                    </span>
                    Drag your files here or
                    <span className="ms-1 primary">browse</span>
                  </p>
                </div>
              )}
            </Dropzone>
          </div>
          {data.map((data, index) => (
            <div
              className="d-flex align-items-center mt-2 user-select-none"
              key={index}
            >
              <div className="text-primary">{data.name}</div>
              <div
                className="material-symbols-outlined text-danger"
                role="button"
                onClick={() => {
                  setData((pre) => pre.filter((d, i) => i !== index));
                  setHasRows([]);
                }}
              >
                close
              </div>
            </div>
          ))}
          {error && <div className="text-danger">{error}</div>}

          <div>
            {!submit && (
              <button
                className="btn btn-primary mt-3"
                style={{ width: '10rem' }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </button>
            )}
            {submit && (
              <button
                className="btn btn-primary mt-3"
                style={{ width: '10rem' }}
              >
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FileUpload;
