import React, { useState } from 'react';

import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import * as constant from '../Constants/constant';
import { sanitizeUserInput } from '../../helper/sanitize';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    background: 'white',
    color: 'white',
  },
};

function Editpopup({ modalIsOpen2, closeModal2, r }) {
  const { csrf } = useSelector((state) => state.app);
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    application_name: r.application_name,
    email: r.email,
    request_type: r.request_type,
    request_id: r.request_id,
  });

  React.useEffect(() => {
    const d = {
      application_name: r.application_name,
      email: r.email,
      request_type: r.request_type,
      request_id: r.request_id,
    };
    setData(d);
  }, [r]);

  function handleClose() {
    closeModal2();
  }

  let subtitle;

  function handleOnchange(e) {
    const d = { ...data };
    d[e.target.name] = e.target.value;
    setData(d);
  }

  function afterOpenModal() {
    subtitle.style.color = 'white';
  }

  function handleSubmit() {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/edit-user-request`,
        {
          request_id: data.request_id,
          application_name: data.application_name,
        },
        {
          headers: { ...constant.headers, 'csrf-token': csrf },
        }
      )
      .then((response) => {
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
        setLoading(false);
        handleClose();
      });
  }

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen2}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal2}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <div>
          <button
            onClick={() => {
              handleClose();
            }}
            style={{
              textDecoration: 'none',
              border: 'none',
              borderRadius: '16px',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            className="material-symbols-outlined position-absolute top-0 end-0"
          >
            close
          </button>
          <div style={{ color: '#0063c3', fontFamily: 'Arial' }}>Edit</div>
          <div>
            <div style={{ paddingTop: '2rem', fontFamily: 'Arial' }}>
              <label style={{ color: '#6c757d' }}>Application Name </label>
              <input
                type="text"
                className="form-control"
                name="application_name"
                value={data.application_name}
                onChange={(e) => {
                  handleOnchange(e);
                }}
              />
              <br />
              <br />
              {/* <label style={{color:"black"}}>user</label>
                <input type="text" name="email" value={data.email} onChange={(e) => handleOnchange(e)}></input><br/><br/>
                <label style={{color:"black"}}>Request Type</label>
                <input type="text" name="request_type" value={data.request_type} onChange={(e) => handleOnchange(e)}></input> */}
            </div>
            <div>
              {loading ? (
                <button
                  className="btn btn-primary"
                  style={{ width: '15%' }}
                  type="button"
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <button
                  className="btn btn-primary ms-2"
                  style={{ width: '15%' }}
                  value="true"
                  name="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Editpopup;
